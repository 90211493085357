import request from "../service/index";
import { tickersType } from "./type";
class API {
	static cms = {
		// hit/1:热门
		getAppTickers: (data: tickersType) => {
			return request({
				url: "/cms/api/app/tickers",
				method: "GET",
				data,
			});
		},
		// 行情收藏操作
		getAppFavorite: (data: any) => {
			return request({
				url: "/cms/api/app/favorite",
				method: "POST",
				data,
			});
		},
		// fromId
		getAppFavoriteList: (data: any) => {
			return request({
				url: "/cms/api/app/favorite/list",
				method: "POST",
				data,
			});
		},
	};
	static wallets = {
		// 获取新闻列表
		postAppNewsList: (data: any) => {
			return request({
				url: "/wallet/api/app/news/list",
				method: "POST",
				data,
			});
		},
		// 获取新闻列表
		getAppNewsDetail: (data: any) => {
			return request({
				url: "/wallet/api/app/news/detail",
				method: "GET",
				data,
			});
		},
		// 获取应用DApps列表
		postAppDappsList: (data: any) => {
			return request({
				url: "/wallet/api/app/dapps/list",
				method: "POST",
				data,
			});
		},
		// 添加取消操作
		postAppDappsOperation: (data: any) => {
			return request({
				url: "/wallet/api/app/dapps/operation",
				method: "POST",
				data,
				noTip: true
			});
		},
		// Dapp收藏操作
		postAppFavorite: (data: any) => {
			return request({
				url: "/wallet/api/app/favorite",
				method: "POST",
				data,
			});
		},
		// 获取应用DApps分类列表
		postDappsCategoryList: (data: any) => {
			return request({
				url: "/wallet/api/app/dapps/category/list",
				method: "POST",
				data,
			});
		},
		// 获取收藏列表
		postAppFavoriteList: (data: any) => {
			return request({
				url: "/wallet/api/app/favorite/list",
				method: "POST",
				data,
			});
		},
		// dApp排序
		postSortDApps: (data: { fromId: string, idList: number[] }) => {
			return request({
				url: "/wallet/api/app/dapps/sort",
				method: "POST",
				data,
			});
		},
		// 获取跨链币种
		getAppBridgeChannel: (data: any) => {
			return request({
				url: "/wallet/api/bridge/channel",
				method: "GET",
				data,
			});
		},
		// 跨链兑换转入
		postBridgeConversionIn: (data: any) => {
			return request({
				url: "/wallet/api/bridge/conversion/in",
				method: "POST",
				data,
			});
		},
		// 跨链兑换转出
		postBridgeConversionOut: (data: any) => {
			return request({
				url: "/wallet/api/bridge/conversion/out",
				method: "POST",
				data,
			});
		},
		// 跨链兑换记录
		postBridgeItems: (data: any) => {
			return request({
				url: "/wallet/api/bridge/items",
				method: "POST",
				data,
			});
		},
		// 跨链取消订单
		postBridgeItemOperation: (data: any) => {
			return request({
				url: "/wallet/api/bridge/item/operation",
				method: "POST",
				data,
			});
		},
		// 发布代币合约
		postGlobalContractDeploy: (data: any) => {
			return request({
				url: "/wallet/api/contract/deploy",
				method: "POST",
				data,
			});
		},
		//
		postContractDeployItems: (data: any) => {
			return request({
				url: "/wallet/api/contract/deploy/items",
				method: "POST",
				data,
			});
		},
		getAppBanner: (data: any) => {
			return request({
				url: "/wallet/api/app/banner",
				method: "GET",
				data,
			});
		},
		// 获取banner详情
		getAppBannerContent: (data: any) => {
			return request({
				url: "/wallet/api/app/banner/content",
				method: "GET",
				data,
			});
		},
			// 文件上传
			apiAppUploadFile: (data: any) => {
				return request({
					url: "/wallet/api/app/upload/file",
					method: "POST",
					data,
				});
			},
				// 获取钱包配置
		getHomeConfig: (data: any) => {
			return request({
				url: "/wallet/api/app/homeConfig",
				method: "GET",
				data,
			});
		},
	};
}

export default API;
