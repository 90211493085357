import API from "@/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const fetchAppFavorite = createAsyncThunk(
	"favorites/fetchAppFavoriteList",
	async (params: any) => {
		try {
			const response = await API.wallets.postAppFavoriteList(params);
			return { data: response };
		} catch (error) {
			console.log(
				"================postAppFavoriteList===================="
			);
			console.log(error);
		}
	}
);

interface updateType {
	id: number;
	value: number;
}

const initialState: any = {
	data: [],
	ids: [],
};

const favoritesSlice = createSlice({
	name: "favorites",
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<any[]>) => {
			const newData = action.payload;
			state.data = newData;
		},
		addData: (state, action: PayloadAction<any>) => {
			const itemData = action.payload;
			state.data.push(itemData);
		},
		updateData: (state, action: PayloadAction<updateType>) => {
			const itemData = action.payload;
			const updatedData = state.data.map((item) => {
				if (item.id === itemData.id) {
					item.favorite = itemData.value;
				}
				return item;
			});
			const newData = updatedData.filter((item) => item.favorite === 1);
			state.data = newData;
			const allIds = newData.map((item) => item.id);
			state.ids = allIds;
		},
		removeData: (state, action: PayloadAction<number>) => {
			state.data = state.data.filter(
				(item) => item.id !== action.payload
			);
		},
	},
});

export const { setData, addData, updateData, removeData } =
	favoritesSlice.actions;
export default favoritesSlice.reducer;
