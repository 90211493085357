// en/英文 zh_CN/中文简体 zh_HK/中文繁体 pt/葡萄牙语 ja/日语 ko/韩语
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./en/translation.json";
import zhCNTranslation from "./zh-CN/translation.json";
import zhHKTranslation from "./zh_HK/translation.json";
import { getInfoFromUserAgent } from "@/utils/Index";
import { setStorage } from "@/utils/Storage";

const languages = ["en", "zh_CN", "zh_HK"]; // 已知
const defaultLang =
	getInfoFromUserAgent("Language") || navigator.language.replace("-", "_");
setStorage("Language", languages.includes(defaultLang) ? defaultLang : "en");

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: enTranslation },
		zh_CN: { translation: zhCNTranslation },
		zh_HK: { translation: zhHKTranslation },
	},
	lng: defaultLang,
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
