import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
	id: number;
	text: string;
}

interface TodoState {
	todos: Todo[];
	addressBooks: [];
}

const initialState: TodoState = {
	todos: [],
	addressBooks: [],
};

const todosSlice = createSlice({
	name: "todos",
	initialState,
	reducers: {
		addTodo: (state, action: PayloadAction<Todo>) => {
			state.todos.push(action.payload);
		},
		removeTodo: (state, action: PayloadAction<number>) => {
			state.todos = state.todos.filter(
				(todo) => todo.id !== action.payload
			);
		},
	},
});

export const { addTodo, removeTodo } = todosSlice.actions;
export default todosSlice.reducer;
