import React, { useState } from "react";
import { sortDataType } from "@/api/type";
import "../style/eliteTitle.scss";
import useCustomTranslation from "@/hooks/useTranslation";
interface ChildComponentProps {
	onChangeSort: (newValue: sortDataType) => void;
}

const EliteTitle: React.FC<ChildComponentProps> = ({ onChangeSort }) => {
	const { t } = useCustomTranslation();
	const [tabItems, setTabItems] = useState([
		{
			id: "0",
			key: "unit",
			name: t("name"),
			imageCls: "arrow",
		},
		{
			id: "1",
			key: "volume24h",
			name: t("volume24h"),
			imageCls: "arrow",
		},
		{
			id: "2",
			key: "lastPrice",
			name: t("lastPrice"),
			imageCls: "arrow",
		},
		{
			id: "3",
			key: "price24hPcnt",
			name: t("price24hPcnt"),
			imageCls: "arrow",
		},
	]);
	// 不需要定义类型-静态数据
	const handleUpdateItem = (targetItem: any) => {
		const updatedTabItems = tabItems.map((item) =>
			item.id === targetItem.id
				? { ...item, imageCls: targetItem.imageCls }
				: item
		);
		setTabItems(updatedTabItems);
	};

	const sortBy = (item: any) => {
		for (let index = 0; index < tabItems.length; index++) {
			const element = tabItems[index];
			if (element.key === item.key) {
				// let sortType = "desc";
				// if (element.imageCls === "arrow") {
				// 	element.imageCls = "arrowDown";
				// } else {
				// 	if (element.imageCls === "arrowUp") {
				// 		element.imageCls = "arrowDown";
				// 		sortType = "desc";
				// 	} else {
				// 		element.imageCls = "arrowUp";
				// 		sortType = "asc";
				// 	}
				// }
				let sortType = "normal";
				if (element.imageCls === "arrow") {
					element.imageCls = "arrowDown";
					sortType = "desc";
				} else if (element.imageCls === "arrowDown") {
					element.imageCls = "arrowUp";
					sortType = "asc";
				} else if (element.imageCls === "arrowUp") {
					element.imageCls = "arrow";
					sortType = "normal";
				}
				handleUpdateItem(element);
				onChangeSort({
					type: sortType === "normal" ? "unit" : element.key,
					sortOrder: sortType,
				} as sortDataType);
			} else {
				element.imageCls = "arrow";
			}
		}
	};
	return (
		<div className="elite-title">
			{tabItems.map((item) => (
				<div
					onClick={() => sortBy(item)}
					className="item"
					key={item.key}
				>
					<span>{item.name}</span>
					<div className={item.imageCls}></div>
				</div>
			))}
		</div>
	);
};

export default EliteTitle;
