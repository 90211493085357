import React, { useState } from "react";
import "../style/collectEdit.scss";
import { List, Image, Toast, SafeArea } from "antd-mobile";
import CustomNavBar from "@/components/navBar";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import API from "@/api/index";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import CustomIconLogo from "@/components/iconLogo";
import useCustomTranslation from "@/hooks/useTranslation";
import ParentApp from "@/components/parentApp";

const reorder = (list: [], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};
const CollectEdit: React.FC<{
	metadata: any[];
	onFavoriteMetadata: (newValue: any[]) => void;
	onLeftClick: () => void;
}> = ({ metadata, onFavoriteMetadata, onLeftClick }) => {
	const { t } = useCustomTranslation();
	const [list, setList] = useState<any>(metadata);
	const userId = useSelector((state: RootState) => state.hot.userId);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		const newList = reorder(
			list,
			result.source.index,
			result.destination.index
		);
		setList([...newList]);
	};
	const onClickRight = async () => {
		const idArray = list.map((item: any) => item.favoriteId);
		const result = await API.cms.getAppFavorite({
			fromId: userId,
			sortIds: idArray,
		});
		Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});
		if (result.code === 0) {
			Toast.clear();
			Toast.show({
				content: t("SavedSuccessfully"),
			});
			onFavoriteMetadata(list);
		} else {
			Toast.clear();
			Toast.show({
				content: t("CollectionFailed"),
			});
		}
	};
	return (
		<ParentApp>
			<div className="drag-wrap">
				<SafeArea position="top" />
				<CustomNavBar
					title={t("EditCollection")}
					onLeftClick={onLeftClick}
					rightContent={
						<span
							style={{
								color: "var(--primary)",
								fontSize: "16px",
							}}
							onClick={onClickRight}
						>
							{t("Save")}
						</span>
					}
				/>
				<section className="list-wrap">
					<List>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(droppableProvided) => (
									<div ref={droppableProvided.innerRef}>
										{list.map(
											(item: any, index: number) => (
												<Draggable
													key={item.unit}
													draggableId={item.unit}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={
																provided.innerRef
															}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={{
																...provided
																	.draggableProps
																	.style,
																boxShadow:
																	!snapshot.isDragging
																		? "none"
																		: "0 0 8px rgba(6, 6, 6, 0.18)",
															}}
														>
															<div
																className="eliteItem-wrap"
																key={index}
															>
																<CustomIconLogo
																	h={30}
																	w={30}
																	unit={
																		item.unit
																	}
																/>
																<div className="content">
																	<div className="coin">
																		<strong>
																			{
																				item.unit
																			}
																		</strong>
																	</div>
																	<Image
																		src={require("@/images/drag.png")}
																		width={
																			24
																		}
																		height={
																			24
																		}
																		fit="fill"
																	/>
																</div>
															</div>
														</div>
													)}
												</Draggable>
											)
										)}
										{droppableProvided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</List>
				</section>
			</div>
		</ParentApp>
	);
};

export default CollectEdit;
