import { SafeArea } from "antd-mobile";
import React, { ReactNode, useEffect, useState } from "react";
interface ParentProps {
	children: ReactNode;
	bg?: string;
}

const ParentApp: React.FC<ParentProps> = ({ children, bg }) => {
	let defaultTheme = "light";
	const DarkTheme = window.matchMedia("(prefers-color-scheme: dark)"); // 是深色
	if (DarkTheme.matches) {
		defaultTheme = "dark";
	}
	const [theme, setTheme] = useState(defaultTheme);
	// 手动变更
	const toggleTheme = (value: string) => {
		const localTheme = value === "light" ? "dark" : "light";
		setTheme(localTheme);
		localStorage.setItem("theme", localTheme);
	};

	useEffect(() => {
		function handleColorSchemeChange(e) {
			if (!e.matches) {
				toggleTheme("dark");
			} else {
				toggleTheme("light");
			}
		}
		DarkTheme.addEventListener("change", handleColorSchemeChange);
		handleColorSchemeChange(DarkTheme);
		localStorage.setItem("theme", defaultTheme);
	}, []);

	return (
		<div
			id="app"
			className={`${theme}`}
			style={{ backgroundColor: "var(--background)" }}
		>
			<div style={{ background: bg || "var(--background)" }}>
				<SafeArea position="top" />
			</div>
			<div className="app-main">{children}</div>
		</div>
	);
};

export default ParentApp;
