import { dataURL2Blob } from './blob'

export const getImageObj = (link: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const handle = (image: HTMLImageElement) => {
      if (image.width !== 0 || image.height !== 0) {
        resolve(image)
      } else {
        reject()
      }
    }

    const image = new Image()
    image.addEventListener('load', event =>
      handle(event.target as HTMLImageElement)
    )
    image.addEventListener('error', reject)
    image.src = link

    if (image.complete) {
      handle(image)
      return
    }

    const intervalId = setInterval(() => {
      if (image.complete) {
        handle(image)
        clearInterval(intervalId)
      }
    }, 40)
  })
}

interface ImageThumbnailConfig {
  maxHeight: number
  maxWidth: number
  quality: number
  scale: number
}

export const compress = (
  image: HTMLImageElement,
  mineType = 'image/jpeg',
  compressOptions: {
    maxLength: number,
    minLength: number
    quality: number,
  }
) => {
  const quality = Math.min(1, compressOptions.quality)
  const  COMPRESS_LENGTH_MAX = compressOptions.maxLength
  const  COMPRESS_LENGTH_MIN = compressOptions.minLength
  
  const width = image.width
  const height = image.height

  let compressWidth = width
  let compressHeight = height

  if (quality < 1) {
    const isheight = width < height
    const zoom = isheight
      ? height / COMPRESS_LENGTH_MAX
      : width / COMPRESS_LENGTH_MAX

    if (width > COMPRESS_LENGTH_MAX && height > COMPRESS_LENGTH_MAX) {
      if (isheight) {
        compressHeight = COMPRESS_LENGTH_MAX
        compressWidth = compressWidth / zoom
      } else {
        compressWidth = COMPRESS_LENGTH_MAX
        compressHeight = compressHeight / zoom
      }
    } else if (width > COMPRESS_LENGTH_MAX && height > COMPRESS_LENGTH_MIN) {
      compressWidth = COMPRESS_LENGTH_MAX
      compressHeight = compressHeight / zoom
      if (compressHeight < COMPRESS_LENGTH_MIN) {
        compressHeight = COMPRESS_LENGTH_MIN
        compressWidth = width / (height / COMPRESS_LENGTH_MIN)
      }
    } else if (height > COMPRESS_LENGTH_MAX && width > COMPRESS_LENGTH_MIN) {
      compressHeight = COMPRESS_LENGTH_MAX
      compressWidth = compressWidth / zoom
      if (compressWidth < COMPRESS_LENGTH_MIN) {
        compressWidth = COMPRESS_LENGTH_MIN
        compressHeight = height / (width / COMPRESS_LENGTH_MIN)
      }
    }
  }

  compressWidth = Math.floor(compressWidth)
  compressHeight = Math.floor(compressHeight)

  const canvas = document.createElement('canvas')

  canvas.width = compressWidth
  canvas.height = compressHeight

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ctx = canvas.getContext('2d')!
  ctx.fillStyle = '#FFF'
  ctx.fillRect(0, 0, compressWidth, compressHeight)
  ctx.drawImage(image, 0, 0, width, height, 0, 0, compressWidth, compressHeight)

  if (!mineType || mineType == 'image/png') {
    mineType = 'image/jpeg'
  }
  return {
    imageFile: dataURL2Blob(
      canvas.toDataURL(mineType, Math.min(0.92, quality))
    ),
    compressWidth,
    compressHeight
  }
}

export const getThumbnail = (
  img: HTMLImageElement,
  thumbnailConfig: ImageThumbnailConfig
) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  const pos = calcPosition(img.width, img.height, thumbnailConfig)
  canvas.width =
    pos.w > thumbnailConfig.maxWidth ? thumbnailConfig.maxWidth : pos.w
  canvas.height =
    pos.h > thumbnailConfig.maxHeight ? thumbnailConfig.maxHeight : pos.h
  context?.drawImage(img, pos.x, pos.y, pos.w, pos.h)
  try {
    let base64 = canvas.toDataURL('image/jpeg', thumbnailConfig.quality)
    const reg = new RegExp('^data:image/[^;]+;base64,')
    base64 = base64.replace(reg, '')
    return base64
  } catch (e: any) {
    throw new Error(e)
  }
}

function calcPosition(
  width: number,
  height: number,
  thumbnailConfig: ImageThumbnailConfig
) {
  const isheight = width < height
  const scale = isheight ? height / width : width / height
  let zoom: number
  let x = 0
  let y = 0
  let w: number
  let h: number

  const gtScale = function () {
    if (isheight) {
      zoom = width / 100
      w = 100
      h = height / zoom
      y = (h - thumbnailConfig.maxHeight) / 2
    } else {
      zoom = height / 100
      h = 100
      w = width / zoom
      x = (w - thumbnailConfig.maxWidth) / 2
    }
    return {
      w: w,
      h: h,
      x: -x,
      y: -y
    }
  }

  const ltScale = function () {
    if (isheight) {
      zoom = height / thumbnailConfig.maxHeight
      h = thumbnailConfig.maxHeight
      w = width / zoom
    } else {
      zoom = width / thumbnailConfig.maxWidth
      w = thumbnailConfig.maxWidth
      h = height / zoom
    }
    return {
      w: w,
      h: h,
      x: -x,
      y: -y
    }
  }
  return scale > thumbnailConfig.scale ? gtScale() : ltScale()
}
