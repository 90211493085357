import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Toast,
  Image,
  ActionSheet,
} from 'antd-mobile'
import { useEffect, useState } from 'react'
import './style/index.scss'
import API from '@/api'
import useRouter from '@/hooks/useRouter'
import {
  getInfoFromUserAgent,
  isEmpty,
  setDataToClipboard
} from '@/utils/Index'
import useCustomTranslation from '@/hooks/useTranslation'
import ParentApp from '@/components/parentApp'
import { useMetaMask } from '@onyx-p/metamask-react'
import useEthTransaction from '@/hooks/useEthTransaction'
import { RightOutline } from 'antd-mobile-icons'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import CustomNavBar from '@/components/navBar'

type Network = {
  name: string
  rpcUrl: string
  chainId: number
  id: number
  unit: string
  decimal: number
}

type Coin = {
  chainId: number // 链Id
  rpcUrl: string
  name: string // 链名称
  contractAddress?: string // 合约地址
  contractDecimal?: number // 合约位数
  contractUnit?: string // 合约单位
  createTime?: string
  decimal: number // 主币位数
  symbol: string
  id: number // 币种Id
  logo?: string
  payAnyone: number
  sort: number
  status: number
  unit: string // 主币单位
  updateTime?: string
  balanceFixed?: string
  balance?: string
  usdBalance?: string
  hidden?: boolean // 是否已隐藏
  official?: number
  scanUrl?: string // 区块浏览器
  price?: string // 价格参考
}

const ExChange: React.FC = () => {
  const { t } = useCustomTranslation()
  const { customNavigate, goBack } = useRouter()
  const { account, connect, status } = useMetaMask()
  const [loadingConf, setLoadingConf] = useState(false)
  const [networkList, setNetworkList] = useState<Network[]>([])
  const [network, setNetwork] = useState<Network | undefined>()
  const [networkSheetVisible, setNetworkSheetVisible] = useState(false)
  // address / name / symbol / amount / decimal;
  const [form] = Form.useForm()
  const { switchNetworkIfNeed, importToken } = useEthTransaction()
  const userId = useSelector((state: RootState) => state.hot.userId)

  useEffect(() => {
    getHomeConfig()
  }, [])

  useEffect(() => {
    console.log('account change', account)
    form.setFieldsValue({ address: account ?? '' })
    // formRef.current.setFieldsValue(newData);
  }, [account])

  const getHomeConfig = async () => {
    // 6196483
    setLoadingConf(true)
    try {
      const response = await API.wallets.getHomeConfig({ fromId: userId })
      if (response?.code === 0) {
        let coins: Coin[] = response.content?.data?.coins ?? []
        coins = coins.filter(
          (item) => isEmpty(item.contractAddress) && item.payAnyone == 1
        )
        setNetworkList(coins)
        setNetwork(coins?.[0])
      }
    } catch (error) {}
    setLoadingConf(false)
  }

  const globalContractDeployHandle = async (params) => {
    const loading = Toast.show({
      icon: 'loading',
      content: t('refreshingText'),
      duration: 0
    })
    try {
      const result = await API.wallets.postGlobalContractDeploy(params)
      loading.close()
      if (result.code === 0) {
        form.resetFields()
        form.setFieldsValue({ address: account ?? '' })
        const contentData = result.content.data
        tokenH5Add(contentData)
      }
    } catch (error) {
      loading.close()
      console.log('---postGlobalContractDeploy--:', error)
      Toast.show({
        content: t('SubmissionFailed'),
        position: 'top'
      })
    }
  }

  const tokenH5Add = async (contentData: any) => {
    const loading = Toast.show({
      icon: 'loading',
      content: t('refreshingText'),
      duration: 0
    })
    try {
      await switchNetworkIfNeed({
        name: network.name,
        rpcUrl: network.rpcUrl,
        chainId: network.chainId,
        unit: network.unit,
        decimal: network.decimal
      })
      await importToken({
        address: contentData.contractAddress,
        decimals: +contentData.decimal,
        symbol: contentData.symbol
      })
      Toast.show({
        content: t('AddedSuccessfully'),
        position: 'top'
      })
    } catch (error) {
      loading.close()
      const resultConfirm = await Modal.confirm({
        content: t('NetworkFailedManually')
      })
      if (resultConfirm) {
        setDataToClipboard(contentData.contractAddress)
        Toast.show({
          content: t('copiedSucc'),
          position: 'top'
        })
      }
    }
  }

  const onFinish = (values) => {
    if (isEmpty(network)) {
      return
    }
    let params = { coinId: network.id, ...values }
    globalContractDeployHandle(params)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const toogleAddressStatus = async () => {
    if (status !== 'connected') {
      connect()
    }
  }

  const validateDecimal = (rule, value, callback) => {
    // 使用正则表达式检查输入是否为数字，并且小于等于 18
    const isNumber = /^\d+$/.test(value)
    if (isNumber && parseInt(value, 10) <= 18) {
      callback()
    } else {
      callback(t('validNumberEqual'))
    }
  }
  const validateInputNumber = (rule, value, callback) => {
    const regex = /^[0-9]*$/
    if (regex.test(value) || value === '') {
      callback()
    } else {
      callback(t('validNumber'))
    }
  }
  return (
    <ParentApp>
      <section className="section-main">
      <CustomNavBar
          title={t('InitialCoinOffering')}
          onLeftClick={() => {
            goBack()
          }}
        />

        <div
          className="container"
          style={{
            flex: 1,
            overflowY: 'auto',
            backgroundColor: '#FFFFFF'
          }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="basic-form"
            style={{
              '--border-top': 'none',
              '--border-bottom': 'none'
            }}
            initialValues={{ address: account ?? '' }}
          >
            <Card
              style={{
                paddingBottom: '15px',
                marginTop: '2px',
                position: 'relative'
              }}
            >
              <div
                className="iss-record-box"
                onClick={() => {
                  if (account) {
                    customNavigate(`/coinRecord/${account}`)
                  } else {
                    connect()
                  }
                }}
                color="#2D374F"
              >
                <Image src={require(`@/images/record_black.png`)} width={24} />
                <span>{t('issueRecords')}</span>
              </div>
              <Form.Item
                label={t('WalletAddress')}
                name="address"
                rules={[
                  {
                    required: true,
                    message: t('PEWalletAddress')
                  }
                ]}
                style={{
                  position: 'relative',
                  padding: '0px'
                }}
              >
                <Input
                  // disabled={true}
                  autoComplete="off"
                  placeholder={t('PEWalletAddress')}
                  value={account ?? ''}
                ></Input>
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    toogleAddressStatus()
                  }}
                ></div>
              </Form.Item>

              <section className="network-box">
                <p className="label">{t('SelectNetwork')}</p>
                <div
                  className={`content ${
                    !networkList.length && !loadingConf ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    networkList.length && setNetworkSheetVisible(true)
                  }}
                >
                  <span>
                    {networkList.length
                      ? network?.name
                      : loadingConf
                      ? ''
                      : t('NetworkEmpty')}
                  </span>

                  <RightOutline />
                </div>
              </section>

              <Form.Item
                label={t('TokenName')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('PETokenName')
                  }
                ]}
              >
                <Input autoComplete="off" placeholder={t('PETokenName')} />
              </Form.Item>
              <Form.Item
                label={t('TokenSymbol')}
                name="symbol"
                rules={[
                  {
                    required: true,
                    message: t('PETokenSymbol')
                  }
                ]}
              >
                <Input autoComplete="off" placeholder={t('PETokenSymbol')} />
              </Form.Item>
              <Form.Item
                label={t('InitialSupply')}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: t('PEInitialSupply')
                  },
                  {
                    validator: validateInputNumber
                  }
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder={t('PEInitialSupply')}
                  inputMode="numeric"
                  pattern="[0-9]*[.]?[0-9]*"
                />
              </Form.Item>
              <Form.Item
                label={t('Decimals')}
                name="decimal"
                rules={[
                  {
                    required: true,
                    message: t('PEDecimals')
                  },
                  { validator: validateDecimal }
                ]}
              >
                <Input
                  type="digit"
                  autoComplete="off"
                  placeholder={t('PEDecimals')}
                  inputMode="numeric"
                  pattern="[0-9]*[.]?[0-9]*"
                />
              </Form.Item>
              <div
                style={{
                  width: '100%',
                  marginTop: 30,
                  overflow: 'hidden'
                }}
              >
                <Button
                  block
                  color="primary"
                  type="submit"
                  shape="rounded"
                  style={{ height: '50px' }}
                  disabled={isEmpty(network)}
                >
                  {t('AddTokens')}
                </Button>
              </div>
            </Card>
          </Form>
        </div>
      </section>

      <ActionSheet
        extra={t('SelectNetwork')}
        cancelText={t('Cancel')}
        visible={networkSheetVisible}
        actions={networkList.map((item) => ({ text: item.name, key: item.id }))}
        onAction={(action) => {
          setNetwork(networkList.find((item) => item.id == action.key))
          setNetworkSheetVisible(false)
        }}
        onClose={() => setNetworkSheetVisible(false)}
      />
    </ParentApp>
  )
}

export default ExChange
