import { configureStore } from "@reduxjs/toolkit";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducers/index";

const persistConfig = {
	key: "root",
	storage: storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: true,
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export function clearLocalstorage() {
	try {
		// 清空所有缓存的数据
		persistor.purge().then(() => {
			console.log("所有缓存的数据已清空。");
		});
		// 在清空数据后，您可能希望重新填充存储的初始状态
		persistor.persist();
		localStorage.clear();
		sessionStorage.clear();
	} catch (error) {
		console.log("=================clearLocalstorage===================");
		console.log(error);
		console.log("=================clearLocalstorage===================");
	}
}
export default store;
