import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
	data: [],
};

const recentlyUsedSlice = createSlice({
	name: "recentlyUsed",
	initialState,
	reducers: {
		addUsed: (state, action: PayloadAction) => {
			const newData = action.payload;
			const oldData = state.data;
			const index = oldData.findIndex(
				(item) => item.id === newData["id"]
			);
			if (index !== -1) {
				oldData.splice(index, 1); // 移除元素
			}
			oldData.unshift(newData); // 添加新元素
			state.data = oldData;
		},
		removeUsed: (state, action: PayloadAction<number>) => {
			state.data = state.data.filter(
				(todo) => todo.id !== action.payload
			);
		},
	},
});

export const { addUsed, removeUsed } = recentlyUsedSlice.actions;
export default recentlyUsedSlice.reducer;
