import { useMetaMask } from '@onyx-p/metamask-react'
import Web3 from 'web3'
import { ABI } from '@/types/constant'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import { isEmpty } from 'lodash'
import { fromWei, toWei } from '@/utils/chain'

type NetWork = {
  name: string
  rpcUrl: string
  chainId: number
  unit: string
  decimal: number
}

type ContractInfo = {
  contractDecimal: number
  contractAddress: string
}

const useEthTransaction = () => {
  const { account, chainId, ethereum, switchChain, addChain, connect } =
    useMetaMask()

  const prepareNetwork = async (network: NetWork) => {
    await switchNetworkIfNeed(network)
    const connectedAddr = await connectIfNeed()
    return connectedAddr
  }

  const connectIfNeed = async () => {
    if (!isEmpty(account)) return account!
    const addrList = await connect()
    return addrList?.[0]
  }

  const switchNetworkIfNeed = async (network: {
    name: string
    rpcUrl: string
    chainId: number
    unit: string
    decimal: number
  }) => {
    const chainIdHexStr = `0x${network.chainId.toString(16)}`
    if (chainId == chainIdHexStr) return

    try {
      return await switchChain(chainIdHexStr)
    } catch (switchErr: any) {
      if (switchErr.code === 4902) {
        return addChain({
          chainId: chainIdHexStr,
          chainName: network.name,
          rpcUrls: [network.rpcUrl],
          nativeCurrency: {
            name: network.unit,
            symbol: network.unit,
            decimals: network.decimal
          }
        })
      } else {
        throw switchErr
      }
    }
  }

  const getContractBalance = async (
    contractInfo: {
      contractDecimal: number
      contractAddress: string
    },
    address?: string
  ) => {
    const web3 = new Web3(ethereum)
    const contract = new web3.eth.Contract(ABI, contractInfo.contractAddress)
    let result: number = await contract.methods.balanceOf(address ?? account).call()
    return parseFloat(fromWei(result, contractInfo.contractDecimal))
  }

  const sendTransaction = async (
    amount: number,
    toAddress: string,
    contractInfo: ContractInfo
  ) => {
    const contractAddress = contractInfo.contractAddress
    const functionSelector = '0xa9059cbb'
    const amountEth = toWei(amount, contractInfo.contractDecimal)

    const web3 = new Web3(ethereum)
    const data =
      functionSelector +
      web3.eth.abi
        .encodeParameters(['address', 'uint256'], [toAddress, amountEth])
        .substring(2)
    const transactionParams = {
      from: account,
      to: contractAddress,
      data: data
    }
    const txHash: string | void = await ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParams]
    })

    return txHash
  }

  const importToken = async (tokenInfo: {
    address: string
    decimals: number
    symbol: string
  }) => {
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: tokenInfo
      }
    })
  }


  const getNonce = async (contractAddr: string) => {
    const web3 = new Web3(ethereum)
    const soldTokenContact = new web3.eth.Contract(
      ABI,
      contractAddr
    )
    const nonce: bigint = await soldTokenContact.methods.nonces(account).call()
    return web3.utils.toNumber(nonce) as number
  }

  const createPermit = async ({
    spender,
    value,
    deadline,
    contractAddress
  }: {
    spender: string
    value: string
    deadline: number
    contractAddress: string
  }) => {
    const nonce = await getNonce(contractAddress)
    const domainName = 'MyToken' // put your token name
    const domain = {
      name: domainName,
      version: '1',
      verifyingContract: contractAddress,
      chainId: chainId
    }

    const domainType = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ]

    const permit = { owner: account, spender, value, nonce, deadline }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]

    const data = {
      types: {
        EIP712Domain: domainType,
        Permit: Permit
      },
      domain: domain,
      primaryType: 'Permit',
      message: permit
    }
    //V4签名
    const signature = await ethereum.request({
      method: 'eth_signTypedData_v4',
      params: [account, data]
    })
    return splitSig(signature)
  }

  const splitSig = (sig: string) => {
    // 去掉签名前面的 "0x"
    const pureSig = sig.replace('0x', '')

    // Convert hexadecimal string to Uint8Array
    const sigArray = new Uint8Array(
      pureSig.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
    )

    // Extract r, s, and v values
    const r = sigArray.slice(0, 32)
    const s = sigArray.slice(32, 64)
    const v = sigArray.slice(64, 65)

    return {
      r: '0x' + bytesToHex(r),
      s: '0x' + bytesToHex(s),
      v: parseInt(bytesToHex(v), 16) + ''
    }
  }

  const bytesToHex = (bytes: Uint8Array) => {
    return Array.prototype.map
      .call(bytes, (byte) => ('00' + byte.toString(16)).slice(-2))
      .join('')
  }

  return {
    prepareNetwork,
    switchNetworkIfNeed,
    getContractBalance,
    sendTransaction,
    createPermit,
    importToken
  }
}

export default useEthTransaction
