import { combineReducers } from "@reduxjs/toolkit";
import todosSlice from "./reducer";
import hot from "./hot";
import mqtt from "./mqttSlice";
import used from "./recentlyUsed";
import favorites from "./favorites";

const reducer = combineReducers({
	todosSlice,
	hot,
	mqtt,
	used,
	favorites
});

export default reducer;
