// useRouter.js
import {
	getBaseURL,
	getInfoFromUserAgent,
	isAndroid,
	isIos,
	isValidURL,
} from "@/utils/Index";
import createBridge from "@/utils/WebViewJavascriptBridge";
import { Toast } from "antd-mobile";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const useRouter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const customNavigate = (url) => {
		// 有uid就代表是混合
		// Modal.alert({
		// 	title: "baseURL + url",
		// });
		const isApp = getInfoFromUserAgent("UserId");
		if (isApp) {
			const baseURL = getBaseURL() + "/#";
			if (isIos() || isAndroid()) {
				createBridge((bridge) => {
					bridge.callHandler("openWindow", {
						navigationBarHidden: true,
						url: baseURL + url,
					});
				});
			} else {
				navigate(url);
			}
		} else {
			navigate(url);
		}
	};
	const goBack = () => {
		const isApp = getInfoFromUserAgent("UserId");
		if (isApp) {
			if (isIos() || isAndroid()) {
				createBridge((bridge) => {
					bridge.callHandler("closeWindow");
				});
			} else {
				navigate(-1);
			}
		} else {
			navigate(-1);
		}
	};
	const toExternalLink = (url, hidden = false) => {
		if (!isValidURL(url)) {
			return Toast.show({
				content: "当前链接不合法",
				position: "top",
			});
		}
		const isApp = getInfoFromUserAgent("UserId");
		if (isApp) {
			if (isIos() || isAndroid()) {
				createBridge((bridge) => {
					bridge.callHandler("openWindow", {
						navigationBarHidden: hidden,
						url: url,
					});
				});
			} else {
				window.location.href = url;
			}
		} else {
			window.location.href = url;
		}
	};
	return {
		customNavigate,
		location,
		params,
		goBack,
		toExternalLink,
	};
};

export default useRouter;
