import type { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import axios, { AxiosResponse } from "axios";
import { Toast } from "antd-mobile";
import type {
	CreateRequestConfig,
	RequestConfig,
	RequestInterceptors,
} from "./types";
import { getStorage } from "@/utils/Storage";
import { isEmpty } from "@/utils/Index";

class Request {
	instance: AxiosInstance;
	interceptorsObj?: RequestInterceptors<AxiosResponse>;
	abortControllerMap: Map<string, AbortController>;

	constructor(config: CreateRequestConfig) {
		this.instance = axios.create(config);
		this.abortControllerMap = new Map();
		this.interceptorsObj = config.interceptors;
		this.instance.interceptors.request.use(
			async (res: InternalAxiosRequestConfig) => {
				const controller = new AbortController();
				const url = res.url || "";
				res.signal = controller.signal;
				this.abortControllerMap.set(url, controller);
				const token = await getStorage("token");
				if (!isEmpty(token)) {
					res.headers.Authorization = `Bearer ${token}`;
				}
				const Language = await getStorage("Language");
				res.headers.Language = Language;
				return res;
			},
			(err: any) => err
		);

		this.instance.interceptors.request.use(
			this.interceptorsObj?.requestInterceptors,
			this.interceptorsObj?.requestInterceptorsCatch
		);
		this.instance.interceptors.response.use(
			this.interceptorsObj?.responseInterceptors,
			this.interceptorsObj?.responseInterceptorsCatch
		);
		this.instance.interceptors.response.use(
			(res: AxiosResponse) => {
				const url = res.config.url || "";
				this.abortControllerMap.delete(url);
				if (res.data && res.data.code !== 0) {
					if (
						res.status === 401 ||
						(res!.data && res.data.code === 401)
					) {
						this.errorHandle(res.status, res.data.message);
					} else {
						if (
							// 待处理
							res.data.message &&
							res.data.message !== "ok"
						) {
							Toast.show({
								content: res.data.message,
							});
						}
					}
				}
				return res.data;
			},
			(err: any) => {
				const { response } = err;
				if (response) {
					if (
						// 待处理
						!response.config.noTip &&
						response.data.message &&
						response.data.message !== "ok"
					) {
						Toast.show({
							content: response.data.message,
						});
					}
					this.errorHandle(response.status, response.data.message);
				}
			}
		);
	}

	request<T>(config: RequestConfig<T>): Promise<T> {
		return new Promise((resolve, reject) => {
			if (config.interceptors?.requestInterceptors) {
				config = config.interceptors.requestInterceptors(config as any);
			}
			this.instance
				.request<any, T>(config)
				.then((res) => {
					if (config.interceptors?.responseInterceptors) {
						res = config.interceptors.responseInterceptors(res);
					}
					resolve(res);
				})
				.catch((err: any) => {
					reject(err);
				});
			// .finally(() => {})
		});
	}

	cancelAllRequest() {
		for (const [, controller] of this.abortControllerMap) {
			controller.abort();
		}
		this.abortControllerMap.clear();
	}

	cancelRequest(url: string | string[]) {
		const urlList = Array.isArray(url) ? url : [url];
		for (const _url of urlList) {
			this.abortControllerMap.get(_url)?.abort();
			this.abortControllerMap.delete(_url);
		}
	}

	errorHandle(status: number, other: string) {
		// 状态码判断
		switch (status) {
			case 302:
				console.log("接口重定向了！");
				break;
			case 400:
				console.log(
					"发出的请求有错误，服务器没有进行新建或修改数据的操作" +
						status
				);
				break;
			case 401: //重定向
				console.log("token:登录失效" + status);
				Toast.show({
					content: "Authentication failure",
				});
				break;
			case 403:
				console.log(
					"登录过期,用户得到授权，但是访问是被禁止的" + status
				);
				break;
			case 404:
				console.log("网络请求不存在" + status);
				break;
			case 406:
				console.log("请求的格式不可得" + status);
				break;
			case 408:
				console.log("请求超时！");
				break;
			case 410:
				console.log("请求的资源被永久删除，且不会再得到的" + status);
				break;
			case 422:
				console.log("当创建一个对象时，发生一个验证错误" + status);
				break;
			case 500:
				console.log("服务器发生错误，请检查服务器" + status);
				break;
			case 502:
				console.log("网关错误" + status);
				break;
			case 503:
				console.log("服务不可用，服务器暂时过载或维护" + status);
				break;
			case 504:
				console.log("网关超时" + status);
				break;
			default:
				console.log("其他错误错误" + status);
		}
	}
}

export default Request;
export { RequestConfig, RequestInterceptors };
