import { MqttClient, IClientOptions } from "mqtt";
import mqtt from "mqtt";

class MQTTStore {
	private static instance: MQTTStore;
	private client: MqttClient;
	private isConnect: boolean = false;
	private topics: string[] = [];
	private clientId: string = "";
	private reconnectInterval: number = 5000;
	private observers: ((topic: string, message: any) => void)[] = [];

	private constructor() {
		this.client = {} as MqttClient;
	}

	public static getInstance(): MQTTStore {
		if (!MQTTStore.instance) {
			MQTTStore.instance = new MQTTStore();
		}
		return MQTTStore.instance;
	}

	private removeFromArray<T>(array: T[], element: T): T[] {
		const index = array.indexOf(element);
		if (index !== -1) {
			array.splice(index, 1);
		}
		return array;
	}

	private receive(topic: string, message: any) {
		const textDecoder = new TextDecoder("utf-8");
		const decodedString = textDecoder.decode(message);
		let decodedValue = JSON.parse(decodedString);
		if (typeof decodedValue === "string") {
			decodedValue = JSON.parse(decodedValue);
		}
		this.notifyObservers(topic, decodedValue);
	}

	private notifyObservers(topic: string, message: any) {
		this.observers.forEach((observer) => observer(topic, message));
	}

	public addObserver(observer: (topic: string, message: any) => void) {
		this.observers.push(observer);
	}

	public removeObserver(observer: (topic: string, message: any) => void) {
		this.observers = this.removeFromArray(this.observers, observer);
	}

	public getIsConnect(): boolean {
		return this.isConnect;
	}

	private setisConnect(bool) {
		this.isConnect = bool;
	}

	getTopics(): string[] {
		return this.topics;
	}

	public getClient(): MqttClient {
		return this.client;
	}

	public getMQTTHost(): string {
		// return "i.tg2tg.com";
		return window.location.host;
	}

	public getORIGIN(): string {
		return window.location.origin;
	}

	public getMQTTUsername(): string {
		return "MQTT_USERNAME";
	}

	public getMQTTPassword(): string {
		return "MQTT_PASSWORD";
	}

	public connect(clientId: string): Promise<boolean> {
		this.clientId = clientId + "-" + Date.now();
		const MQTT_ORIGIN = this.getORIGIN();
		return new Promise((resolve, reject) => {
			const options: IClientOptions = {
				clientId: this.clientId,
				hostname: this.getMQTTHost(),
				protocol: MQTT_ORIGIN.startsWith("https") ? "wss" : "ws",
				protocolVersion: 4,
				port: MQTT_ORIGIN.startsWith("https") ? 443 : 80,
				path: "/mqtt",
				keepalive: 60,
				reconnectPeriod: 5,
				clean: false,
			};

			try {
				if (this.getIsConnect()) {
					resolve(true);
					return;
				}
				this.client = mqtt.connect(options);

				this.client.on("connect", () => {
					this.setisConnect(true);
					resolve(true);
					console.log("MQTT连接成功");
				});

				this.client.on("error", (error: { message: any }) => {
					console.error("MQTT Connection Error:", error.message);
					this.setisConnect(false);
					if (this.client) {
						this.client.end();
					}
					setTimeout(() => {
						this.client = mqtt.connect(options);
					}, this.reconnectInterval);
					this.reconnectInterval = Math.min(
						this.reconnectInterval * 2,
						180000
					);
				});

				this.client.on("message", (topic, message) => {
					this.receive(topic, message);
				});
			} catch (error) {
				console.error("MQTT连接错误:", error);
				this.setisConnect(false);
				reject(error);
			}
		});
	}

	public subscribe(topics: string[] | string) {
		let newTopics: string[] = [];
		if (typeof topics === "string") {
			topics = [topics];
		}
		topics.forEach((topic: string) => {
			if (!this.topics.includes(topic)) {
				newTopics.push(topic);
				this.topics.push(topic);
			}
		});

		if (newTopics.length > 0) {
			this.client.subscribe(newTopics);
		}
	}

	public unsubscribe(topic: string) {
		this.client.unsubscribe && this.client.unsubscribe(topic);
		this.topics = this.removeFromArray(this.topics, topic);
	}

	public sendPublic(topic: string, message: any) {
		this.client.publish(topic, JSON.stringify(message));
	}

	public disconnect() {
		this.setisConnect(false);
		this.client.end();
	}

	public clearAllTopics() {
		if (this.topics.length) {
			this.topics.forEach((topic) => {
				this.unsubscribe(topic);
			});
		}
	}
}

export default MQTTStore;
