import { Swiper, SwiperRef, Tabs } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import Dapp from "./components/dapp";
import NDapp from "../ndapp";
// import News from "./components/news";
import Market from "../market";
import useCustomTranslation from "@/hooks/useTranslation";
import { getIOSStatusBarHeight } from "@/utils/Index";
import ParentApp from "@/components/parentApp";
import createBridge from "@/utils/WebViewJavascriptBridge";
import eventBus from "@/utils/event";

const StyleSheet = {
	content: {
		flex: 1,
		overflow: "hidden",
		// backgroundColor: "white",
		// borderTopLeftRadius: "20px",
		// borderTopRightRadius: "20px",
		// boxShadow: "0px -4px 20px 0px rgba(132,187,227,0.25)",
	},
};

function Home() {
	const { t } = useCustomTranslation();
	const tabItems = [
		{
			key: "1",
			title: t("DApps"),
			comp: <NDapp key={"1"} />,
		},
		{
			key: "2",
			title: t("Market"),
			comp: <Market key={"2"} />,
		},
		// {
		// 	key: "3",
		// 	title: t("News"),
		// comp: <News key={'3'}/>,
		// },
	];
	const swiperRef = useRef<SwiperRef>(null);
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
		createBridge((bridge) => {
			bridge.registerHandler("viewAppeared", function () {
				eventBus.emit("viewAppeared");
			});
		});
	}, []);

	return (
		<ParentApp>
			<section className="section-main">
				<Tabs
					activeKey={tabItems[activeIndex].key}
					stretch={false}
					onChange={(key) => {
						const index = tabItems.findIndex(
							(item) => item.key === key
						);
						setActiveIndex(index);
						swiperRef.current?.swipeTo(index);
					}}
					activeLineMode="fixed"
					style={{
						"--active-title-color": "var(--primary)",
						"--active-line-color": "var(--primary)",
						"--title-font-size": "18px",
						marginLeft: "20px",
						color: "var(--secondary)",
						fontWeight: "500",
						borderBottom: "none",
					}}
				>
					{tabItems.map((item) => (
						<Tabs.Tab title={item.title} key={item.key} />
					))}
				</Tabs>
				<div style={StyleSheet.content}>
					<Swiper
						direction="horizontal"
						loop
						indicator={() => null}
						ref={swiperRef}
						defaultIndex={activeIndex}
						onIndexChange={(index) => {
							setActiveIndex(index);
						}}
						style={{ height: "100%" }}
					>
						{tabItems.map((item) => (
							<Swiper.Item key={item.key}>
								{item.comp}
							</Swiper.Item>
						))}
					</Swiper>
				</div>
			</section>
		</ParentApp>
	);
}

export default Home;
