import {
  Avatar,
  Button,
  Ellipsis,
  InfiniteScroll,
  List,
  PullToRefresh,
  Toast
} from 'antd-mobile'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import API from '@/api'
import { AppDispatch, RootState } from '@/redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { addUsed } from '@/redux/reducers/recentlyUsed'
import useRouter from '@/hooks/useRouter'
import '../style/index.scss'
import CustomEmpty from '@/components/empty'
import { getCoverUri } from '@/utils/Index'
import useCustomTranslation from '@/hooks/useTranslation'
import useDappsOperation from '@/hooks/useDappsOperation'
import { DAppItem } from '@/pages/home/type'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  useKeyboardSensor,
  useMouseSensor
} from 'react-beautiful-dnd'

import ReactDOM from 'react-dom'
import useTouchSensor from '@/hooks/useTouchSensor'

const sidebarPortal: HTMLElement = document.createElement('div')
sidebarPortal.classList.add('sidebar-portal')

if (!document.body) {
  throw new Error('body not ready for portal creation!')
}

document.body.appendChild(sidebarPortal)

const Universal: React.ForwardRefRenderFunction<
  any,
  { dappStateChanged?: (id: number, added: boolean) => void }
> = ({ dappStateChanged }, ref) => {
  const { t } = useCustomTranslation()
  const dispatch: AppDispatch = useDispatch()
  const localUseds = useSelector((state: RootState) => state.used.data)
  const userId = useSelector((state: RootState) => state.hot.userId)
  const { toExternalLink } = useRouter()
  const { dappsOperationyHandle } = useDappsOperation()
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [data, setData] = useState<DAppItem[]>([])
  const needUpdate = useRef(false)
     // type 1/拉普通列表 不传则给推荐和普通列表 分页必传
  const postAppDappsListHandle = async () => {
    try {
      const params = {
        type: 1,
        fromId: userId
      }
      const result = await API.wallets.postAppDappsList(params)
      if (result?.code === 0) {
        const contentData = result.content.data
        setData(contentData)
      }
    } catch (error) {
      console.log('---postAppDappsList--:', error)
    } finally {
    }
  }
  const handleRefresh = async () => {
    // 模拟刷新数据
    await postAppDappsListHandle()
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.source.index === result.destination.index)
      return
    let newData = [...data]
    ;[newData[result.source.index], newData[result.destination.index]] = [
      newData[result.destination.index],
      newData[result.source.index]
    ]
    setData(newData)
    API.wallets.postSortDApps({
      fromId: userId,
      idList: newData.map((item) => item.settingId)
    })
  }

  const handleAddUsed = (data: DAppItem) => {
    dispatch(addUsed(data as any))
  }

  const handleOperateDApp = async (dapp: DAppItem) => {
    const result = await dappsOperationyHandle({
      type: 2,
      id: dapp.id,
      remove: 1
    })
    if (result) {
      dappStateChanged?.(dapp.id, false)
      setData((prevList) => {
        const newList = [...prevList]
        const index = newList.findIndex((item) => item.id == dapp.id)
        if (index >= 0) {
          newList.splice(index, 1)
        }
        return newList
      })
    }
  }

  useEffect(() => {
    handleRefresh()
  }, [])

  useImperativeHandle(ref, () => ({
		refresh: () => {
      needUpdate.current = true
		},
    componentAppear: () => {
      if (needUpdate.current) {
        needUpdate.current = false
        handleRefresh()
      }
    }
	}));

  const openWindowSeft = (itemData: DAppItem) => {
    if (itemData.url) {
      let hidden = itemData.header === 0 ? true : false
      toExternalLink(itemData.url, hidden)
    }
  }

  const recentlyUsedHandle = () => {
    const handleTouchStart = (e) => e.stopPropagation()
    const handleTouchMove = (e) => e.stopPropagation()
    const handleTouchEnd = (e) => e.stopPropagation()
    return (
      <div className="subnav-area">
        <ul
          style={{
            display: 'flex',
            overflowX: 'scroll',
            whiteSpace: 'nowrap'
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {localUseds.map((item, index) => {
            return (
              <li
                onClick={() => {
                  openWindowSeft(item)
                }}
                key={index}
              >
                <div className="icon-wrap">
                  <Avatar
                    src={getCoverUri(item.cover)}
                    style={{
                      '--size': '48px',
                      '--border-radius': '8px'
                    }}
                  />
                </div>
                <div className="detail">
                  <Ellipsis direction="end" content={item.name}/>
                  {/* <div className="name">{item.name}</div> */}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <PullToRefresh
        onRefresh={handleRefresh}
        canReleaseText={t('canReleaseText')}
        completeText={t('completeText')}
        pullingText={t('pullingText')}
        refreshingText={t('refreshingText')}
      >
        {localUseds.length > 0 && (
          <>
            <div className="header-area">
              <div className="content">{t('RecentlyUsed')}</div>
            </div>
            {recentlyUsedHandle()}
          </>
        )}
        <div className="header-area">
          <div className="content">{t('MyDApps')}</div>
          <Button
            onClick={() => {
              setIsInEditMode(!isInEditMode)
            }}
            color="primary"
            fill="none"
            size="mini"
            style={{ fontSize: '10px', minWidth: '50px' }}
          >
            {isInEditMode ? t('FinishedEdit') : t('edit')}
          </Button>
        </div>
        {data.length === 0 ? (
          <CustomEmpty />
        ) : (
          <div className="list-area" style={{ position: 'relative' }}>
            <List>
              <DragDropContext
                enableDefaultSensors={false}
                sensors={[useMouseSensor, useKeyboardSensor, useTouchSensor]}
                onDragEnd={onDragEnd}
              >
                <Droppable droppableId="droppable">
                  {(droppableProvided) => (
                    <div ref={droppableProvided.innerRef}>
                      {data.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id + ''}
                          index={index}
                          isDragDisabled={!isInEditMode}
                        >
                          {(provided, snapshot) => {
                            const child = (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: !snapshot.isDragging
                                    ? 'none'
                                    : '0 0 8px rgba(6, 6, 6, 0.18)'
                                }}
                              >
                                <div
                                  onClick={
                                    isInEditMode
                                      ? null
                                      : () => {
                                          handleAddUsed(item)
                                          openWindowSeft(item)
                                        }
                                  }
                                  className="list-item"
                                  key={item.id}
                                >
                                  <div className="icon-wrap pt-3">
                                    <Avatar
                                      src={getCoverUri(item.cover)}
                                      style={{
                                        '--size': '40px',
                                        '--border-radius': '40px'
                                      }}
                                    />
                                  </div>
                                  <div className="content">
                                    <div className="name">{item.name}</div>
                                    <div className="desc">{item.info}</div>
                                  </div>

                                  {isInEditMode ? (
                                    <Button
                                      onClick={(event) => {
                                        event.stopPropagation()
                                        event.nativeEvent.stopImmediatePropagation()
                                        handleOperateDApp(item)
                                      }}
                                      size="mini"
                                      shape="rounded"
                                      style={{
                                        fontSize: '10px',
                                        '--text-color': '#4B6DE3',
                                        '--background-color': '#E8F0F7'
                                      }}
                                    >
                                      {t('Cancel')}
                                    </Button>
                                  ) : null}
                                </div>
                              </div>
                            )

                            if (snapshot.isDragging) {
                              return ReactDOM.createPortal(child, sidebarPortal)
                            } else {
                              return child
                            }
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </List>
          </div>
        )}
      </PullToRefresh>
    </div>
  )
}

export default Universal
