import React, { useEffect, useState } from "react";
import {
	Avatar,
	Button,
	Card,
	Dialog,
	Form,
	Input,
	Popup,
	Skeleton,
	Space,
	Toast,
} from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import API from "@/api";
import { isEmpty } from "lodash";
import { addThousandsSeparator, getCoverUri } from "@/utils/Index";
import "../style/index.scss";
import useRouter from "@/hooks/useRouter";
import useCustomTranslation from "@/hooks/useTranslation";
import useEthTransaction from "@/hooks/useEthTransaction";
import { toWei } from "@/utils/chain";

const StyleSheet = {
	li: {
		// display: "flex",
		padding: "8px 0",
		lineHeight: "1.2em",
	},
	span1: {
		fontSize: "15px",
		fontWeight: 500,
		color: "#2D374F",
	},
	span2: {
		flex: 1,
		overflow: "hidden",
		fontSize: "14px",
		fontWeight: 500,
		color: "#7D869D",
	},
};
const BridgeOut: React.FC<{
	data: any[];
	targetData: any;
	fromAddress?: string;
	balance: number;
	connectWallet: () => void;
	statusBool: boolean;
}> = ({
	data,
	targetData,
	connectWallet,
	statusBool,
	fromAddress,
	balance,
}) => {
	const { t } = useCustomTranslation();
	// const dispatch: AppDispatch = useDispatch();
	const { customNavigate } = useRouter();
	const [currencyData, setCurrencyData] = useState<any>({});
	const [addressData, setAddressData] = useState<any>({});
	const [visible, setVisible] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [toAddress, setToAddress] = useState("");
	const [amount, setAmount] = useState("");
	const { createPermit } = useEthTransaction();

	const togglesVisible = () => {
		setVisible(!visible);
	};

	const bridgeConversionOutHandle = async (params) => {
		Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});
		try {
			const result = await API.wallets.postBridgeConversionOut(params);
			if (result.code === 0) {
				Toast.show({
					content: t("SubmitWithdrawSuccessful"),
					position: "top",
				});
				recordStateTrack({
					arrivalMoney: moneyPreCalc() + " " + currencyData.unit,
					receivedAddress: toAddress,
					money: amount + " " + addressData.unit,
					fee: currencyData.taxUsd + " " + addressData.unit,
				});
				setAmount("");
			} else {
				Toast.show({
					content: result.message,
					position: "top",
				});
			}
		} catch (error) {
			Toast.show({
				content: t("TransferFailedLater"),
				position: "top",
			});
			console.log("---postBridgeConversionOut--:", error);
		}
	};

	useEffect(() => {
		if (data.length) setCurrencyData(data[0]);
	}, [data]);
	useEffect(() => {
		if (!isEmpty(targetData)) setAddressData(targetData);
	}, [targetData]);

	const selectWalletAddress = (itemData) => {
		if (itemData.code !== currencyData.code) {
			setCurrencyData(itemData);
		}
		togglesVisible();
	};

	const submitFrom = async () => {
		if (!statusBool) {
			return connectWallet();
		} else if (
			isEmpty(toAddress.toString()) ||
			toAddress.toString().length < 28
		) {
			return Toast.show({
				content: t("PETransferAddress"),
				position: "top",
			});
		} else if (isEmpty(amount.toString())) {
			return Toast.show({
				content: t("PETransferAmount"),
				position: "top",
			});
		} else if (+amount <= 0) {
			return Toast.show({
				content: t("MinAmount"),
				position: "top",
			});
		} else if (+amount > balance) {
			setAmount(balance + "");
			return Toast.show({
				content: t("MaxAmount") + ":" + balance,
				position: "top",
			});
		} else if (parseFloat(currencyData.taxUsd) >= +amount) {
			return Toast.show({
				content: t("MaxTxAmount") + ":" + currencyData.taxUsd,
				position: "top",
			});
		} else if (isEmpty(currencyData) || isEmpty(addressData)) {
			return Toast.show({
				content: t("SignTypedDataFailed"),
				position: "top",
			});
		}
		// const params = {
		// 	name: addressData.name,
		// 	rpcUrl: addressData.rpcUrl,
		// 	address: addressData.address,
		// 	amount: amount,
		// 	ownerAddress: addressData.ownerAddress,
		// 	chainId: addressData.chainId,
		// };
		setDisabled(true);
		Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});

		let signatureString: string | undefined;
		try {
			const deadline = parseInt(
				new Date().getTime() / 1000 + 20 * 60 + ""
			);
			const signature = await createPermit({
				value: toWei(amount, targetData.contractDecimal), //授权金额
				spender: addressData.ownerAddress, //被授权人
				contractAddress: addressData.address, //授权的合约地址
				deadline,
			});
			signatureString = JSON.stringify({ ...signature, deadline });
		} catch (error) {
			console.log("createPermit", error);
			Toast.show({
				content: t("SignTypedDataFailed"),
				position: "top",
			});
		}

		if (signatureString) {
			await bridgeConversionOutHandle({
				sourceAddress: fromAddress,
				money: amount,
				fee: currencyData.taxUsd,
				code: currencyData.code,
				receivedAddress: toAddress,
				transactionData: signatureString,
			});
		}

		setDisabled(false);
	};

	const moneyPreCalc = () => {
		if (+amount > parseFloat(currencyData.taxUsd)) {
			return (
				(+amount - parseFloat(currencyData.taxUsd)) *
				currencyData.outRate
			).toFixed(6);
		}
		return "--";
	};

	const recordStateTrack = (data) => {
		Dialog.confirm({
			confirmText: t("TrackWithdrawalStatus"),
			cancelText: t("Cancel"),
			bodyStyle: { width: "80vw", margin: "0 auto" },
			content: (
				<ul style={{ marginTop: "10px" }}>
					<li style={StyleSheet.li}>
						<div style={StyleSheet.span1}>
							{t("EstimatedArrival")}:
						</div>
						<div style={StyleSheet.span2}>{data.arrivalMoney}</div>
					</li>
					<li style={StyleSheet.li}>
						<div style={StyleSheet.span1}>
							{t("WithdrawalAddress")}:
						</div>
						<div style={StyleSheet.span2}>
							{data.receivedAddress}
						</div>
					</li>
					<li style={StyleSheet.li}>
						<div style={StyleSheet.span1}>
							{t("AmountCoinsWithdrawn")}:{" "}
						</div>
						<div style={StyleSheet.span2}>{data.money}</div>
					</li>
					<li style={StyleSheet.li}>
						<div style={StyleSheet.span1}>{t("Handlingfee")}:</div>
						<div style={StyleSheet.span2}>{data.fee}</div>
					</li>
				</ul>
			),
			onConfirm: () => {
				customNavigate(`/record/${fromAddress}?type=2`);
			},
		});
	};

	return (
		<div className="content">
			<Card className="care-wrap">
				<Card
					style={{
						backgroundColor: "#F9FBFD",
						marginBottom: "10px",
						borderRadius: "10px",
						border: "1px solid var(--border)",
					}}
				>
					<div className="stored-area">
						<div className="title">{t("Token")}</div>
						<div className="currency">
							{addressData.unit ? (
								<div
									style={{
										border: "1px solid rgba(0, 0, 0, .15)",
										borderRadius: "100%",
									}}
								>
									<Avatar
										src={getCoverUri(addressData.logo)}
										style={{ "--size": "28px" }}
									/>
								</div>
							) : (
								<Skeleton
									animated
									style={{
										width: "28px",
										height: "28px",
										"--border-radius": "100%",
									}}
								/>
							)}
							<strong>{addressData.unit || "-"}</strong>
							{/* <RightOutline fontSize={24} /> */}
						</div>
					</div>
				</Card>
				<Card
					style={{
						backgroundColor: "#F9FBFD",
						borderRadius: "10px",
						border: "1px solid var(--border)",
					}}
					onClick={togglesVisible}
				>
					<div className="stored-area">
						<div className="title">{t("Network")}</div>
						<div className="currency">
							{currencyData.unit ? (
								<Avatar
									src={getCoverUri(currencyData.logo)}
									style={{ "--size": "28px" }}
								/>
							) : (
								<Skeleton
									animated
									style={{
										width: "28px",
										height: "28px",
										"--border-radius": "100%",
									}}
								/>
							)}
							<strong>{currencyData.name}</strong>
							<RightOutline fontSize={1} color="#A8B4D2" />
						</div>
					</div>
				</Card>
			</Card>
			<Card style={{ marginBottom: "6px" }}>
				<Space
					justify="between"
					style={{
						fontSize: "14px",
						width: "100%",
						padding: "0 3px",
					}}
				>
					<span style={{ color: "#7D858A" }}>
						{t("EstimatedRate")}：
					</span>
					{currencyData.rate ? (
						<span
							style={{ color: "#2585BB" }}
						>{`1 ${addressData.unit} ≈ ${currencyData.outRate} ${currencyData.unit}`}</span>
					) : (
						<Skeleton
							animated
							style={{
								width: "150px",
								height: "18px",
							}}
						/>
					)}
				</Space>
			</Card>
			<div style={{ padding: "0 12px" }}>
				<Card
					style={{
						marginBottom: "10px",
						borderRadius: "10px",
						border: "1px solid #E8F0F7",
					}}
				>
					{/* <Form layout="vertical"> */}
					<Form.Item
						label={t("WithdrawalAddrress")}
						name="username"
						style={{
							color: "#7D869D",
							fontSize: "14px",
							fontWeight: 500,
						}}
					>
						<Input
							value={toAddress}
							onChange={(val) => {
								setToAddress(val);
							}}
							autoComplete="off"
							placeholder={t("CurrentlyAddres", {
								description: currencyData.description,
								symbol: currencyData.symbol,
							})}
							style={{
								"--font-size": "16px",
								"--color": "#A8B4D2",
							}}
						/>
					</Form.Item>
					{/* </Form> */}
				</Card>
			</div>
			<div style={{ padding: "0 12px" }}>
				<Card
					style={{
						marginBottom: "10px",
						borderRadius: "10px",
						border: "1px solid #E8F0F7",
					}}
				>
					<div
						style={{
							color: "#7D869D",
							fontSize: "14px",
							fontWeight: 500,
						}}
					>
						{t("WithdrawalAmount")}
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Input
							value={amount}
							onChange={(val) => {
								setAmount(val);
							}}
							autoComplete="off"
							placeholder={t("PEWithAmount")}
							style={{
								height: "28px",
								flex: "1",
								"--font-size": "16px",
								"--color": "#A8B4D2",
							}}
							inputMode="numeric"
							pattern="[0-9]*[.]?[0-9]*"
						/>
						{/* <strong>21.000</strong> */}
						<div
							onClick={() => {
								setAmount(() => {
									return balance < 0.01 ? "0" : balance + "";
								});
							}}
							style={{
								height: "28px",
								lineHeight: "28px",
								padding: "0 15px",
								color: "#4B6DE3",
								backgroundColor: "#E8F0F7",
								borderRadius: "10px",
								minWidth: "62px",
								textAlign: "center",
							}}
						>
							All
						</div>
					</div>
				</Card>
			</div>
			<dl className="detail">
				<dd>
					<span>{t("WalletBalannce")}：</span>
					<div>
						<span>
							{statusBool
								? addThousandsSeparator(balance)
								: "0.00"}
						</span>
						<span>{addressData.unit ?? "KD"} </span>
					</div>
				</dd>
				<dd>
					<span>{t("Handlingfee")}：</span>
					<div>
						<span>{currencyData.taxUsd ?? "--"}</span>
						<span>{addressData.unit ?? "KD"}</span>
					</div>
				</dd>
				<dd>
					<span>{t("WxpectedArrival")}：</span>
					<div>
						<span>{moneyPreCalc()} </span>
						<span>{currencyData.unit ?? "USDT"}</span>
					</div>
				</dd>
			</dl>
			<p
				style={{
					paddingLeft: "12px",
					paddingRight: "12px",
					fontSize: "16px",
					fontWeight: 500,
					color: "#C99327",
					marginBottom: "15px",
					lineHeight: "34px",
				}}
			>
				{t("CurrentlySupported")}
			</p>
			<div style={{ width: "99%", overflow: "hidden" }}>
				<Button
					onClick={submitFrom}
					disabled={disabled}
					block
					shape="rounded"
					size="large"
					style={{
						"--background-color": "#4B6DE3",
						"--text-color": "white",
					}}
				>
					{t("Submit")}
				</Button>
			</div>

			<Card>
				<dl className="tips">
					<dt>{t("Tips")}</dt>
					{[0, 1, 2, 3].map((item, index) => (
						<dd key={item}>
							{index + 1}.{t("warnLoss" + index)}
						</dd>
					))}
				</dl>
			</Card>

			<Popup
				visible={visible}
				onMaskClick={() => {
					setVisible(false);
				}}
				bodyClassName="popup-list"
			>
				<div
					style={{
						height: "40vh",
						overflowY: "scroll",
						padding: "20px 15px",
					}}
				>
					<ul className="coins">
						{data.map((item, index) => (
							<li
								onClick={() => {
									selectWalletAddress(item);
								}}
								key={index}
							>
								{/* className="img" */}
								<Avatar
									src={getCoverUri(item.logo)}
									style={{ "--size": "28px" }}
								/>
								<strong>{item.unit}</strong>
							</li>
						))}
					</ul>
				</div>
			</Popup>
		</div>
	);
};

export default BridgeOut;
