import Trade from "../pages/trade";
import ExChange from "../pages/exchange";
import New from "../pages/new";
import Record from "../pages/record";
import IssCoin from "../pages/issCoin";
import CoinRecord from "../pages/coinRecord";
import CustomizeDApp from "../pages/customizeDapp";
import Market from "@/pages/market";
import Application from "@/pages/ndapp";
import Home from "@/pages/home";

const routes = [
	{
		path: "/",
		component: Home,
		keepAlive: true,
	},
	{
		path: "/dapp",
		component: Application,
		keepAlive: true,
	},
	{
		path: "/market",
		component: Market,
		keepAlive: true,
	},
	{
		path: "/trade/:symbol",
		component: Trade,
		keepAlive: false,
	},
	{
		path: "/new/:id",
		component: New,
		keepAlive: false,
	},
	{
		path: "/exchange",
		component: ExChange,
		keepAlive: true,
	},
	{
		path: "/record/:id",
		component: Record,
		keepAlive: false,
	},
	{
		path: "/issCoin",
		component: IssCoin,
		keepAlive: false,
	},
	{
		path: "/coinRecord/:id",
		component: CoinRecord,
		keepAlive: false,
	},
	{
		path: "/customizeDApp/:dapp",
		component: CustomizeDApp,
		keepAlive: false,
	},
	// {
	// 	path: "/user/:id",
	// 	component: UserProfile,
	// },
	// props.match.params.id,
];

export default routes;
