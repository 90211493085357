import { isPromise } from "./Index"

export const getBlobUrl = (blob: Blob) => {
  const URL = window.URL || window.webkitURL
  return URL ? URL.createObjectURL(blob) : ''
}

export const getBlobExtension = (blob: Blob) => {
  const name: string = (blob as any).name
  if (name.length) {
    return name.substring(name.lastIndexOf('.') + 1)
  }
  return blob.type.indexOf('/') > 0
    ? blob.type.substring(blob.type.lastIndexOf('/') + 1)
    : ''
}

export const blob2ArrayBuffer = (blob: Blob) => {
  if (isPromise(blob.arrayBuffer)) {
    return blob.arrayBuffer()
  }
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', event => {
      if (event.target) {
        resolve(event.target.result as ArrayBuffer)
      } else {
        reject('null')
      }
    })
    reader.addEventListener('error', error => reject(error))
    reader.readAsArrayBuffer(blob)
  })
}

export const revokeBlobUrl = (url: string) => {
  const URL = window.URL || window.webkitURL
  URL.revokeObjectURL(url)
}

export const dataURL2Blob = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const type = url.match(/data:([^;]+)/)![1]
  const base64 = url.replace(/^[^,]+,/, '')
  const bstr = window.atob(base64)
  let n = bstr.length
  const u8arr = new Uint8Array(bstr.length)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type })
}
