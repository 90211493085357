import { RootState } from "@/redux/store";
import { Toast } from "antd-mobile";
import { useSelector } from "react-redux";
import useCustomTranslation from "./useTranslation";
import API from "@/api";

const useDappsOperation = () => {
	const { t } = useCustomTranslation();
	const userId = useSelector((state: RootState) => state.hot.userId);
	const dappsOperationyHandle = async (outParams: any) => {
		const loading = Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});
		try {
			// description = "fromId/必填 type 1/dapp操作[name/url/info/logo][id/remove 删除] 2/添加取消操作[id/添加 id/remove/取消添加]")
			const params = {
				fromId: userId,
			};
			const result = await API.wallets.postAppDappsOperation(
				Object.assign(params, outParams)
			);
			if (result.code === 0) {
				return true
			}
		} catch (error) {
			console.log("---postAppDappsOperation--:", error);
			Toast.show({
				content: t("SubmissionFailed"),
				position: "top",
			});
		} finally {
			loading.close();
		}
	};
	return { dappsOperationyHandle };
};

export default useDappsOperation;
