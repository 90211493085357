import Request from "./request";
import { AxiosResponse } from "axios";

import type { RequestConfig } from "./request/types";
export interface FTRequest<T> {
	code: number;
	message: string;
	content: T;
}

interface FTRequestConfig<T, R> extends RequestConfig<FTRequest<R>> {
	data?: T;
	noTip?: boolean;
}

const request = new Request({
	baseURL: "/",
	timeout: 1000 * 10,
	interceptors: {
		requestInterceptors: (config) => config,
		responseInterceptors: (result: AxiosResponse) => {
			return result;
		},
	},
});

const ftRequest = <D = any, T = any>(config: FTRequestConfig<D, T>) => {
	const { method = "GET" } = config;
	if (method === "get" || method === "GET") {
		config.params = config.data;
	}
	return request.request<FTRequest<T>>(config);
};

export const cancelRequest = (url: string | string[]) => {
	return request.cancelRequest(url);
};

export const cancelAllRequest = () => {
	return request.cancelAllRequest();
};

export default ftRequest;
