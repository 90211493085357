import CustomNavBar from "@/components/navBar";
import ParentApp from "@/components/parentApp";
import {
	Button,
	Form,
	ImageUploadItem,
	ImageUploader,
	Input,
	Image,
	TextArea,
	Toast,
} from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style/index.scss";
import API from "@/api";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import useRouter from "@/hooks/useRouter";
import { isEmpty, isNumber } from "lodash";
import { DAppItem } from "../home/type";
import { getCoverUri, isValidURL } from "@/utils/Index";
import { getBlobUrl } from "@/utils/blob";
import { compress, getImageObj } from "@/utils/image";

const CustomizeDApp: React.FC = () => {
	const { t } = useTranslation();
	const { goBack, params } = useRouter();
	const [isModifyType, setIsModifyType] = useState(false);
	const originalDApp = useRef<DAppItem>();
	const userId = useSelector((state: RootState) => state.hot.userId);
	const [fileList, setFileList] =
		useState<(ImageUploadItem & { file?: File })[]>();
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");
	const [info, setInfo] = useState("");

	useEffect(() => {
		let dapp: DAppItem | null = null;
		if (!isEmpty(params.dapp)) {
			try {
				let obj = JSON.parse(params.dapp);
				if (isNumber(obj.id)) {
					dapp = obj;
					dapp.url = decodeURIComponent(dapp.url);
					dapp.cover = dapp.cover?.length
						? decodeURIComponent(dapp.cover)
						: null;
				}
			} catch (error) {}
		}
		if (dapp) {
			const { name, id, url, info, cover } = dapp;
			setFileList(cover?.length ? [{ url: getCoverUri(cover) }] : []);
			setName(name);
			setUrl(url);
			setInfo(info);
			setIsModifyType(true);
			originalDApp.current = dapp;
		} else {
			setIsModifyType(false);
		}
	}, [params]);

	const preprocessUploadedFile = async (file: File) => {
		return {
			url: getBlobUrl(file),
			file: file,
		};
	};

	const handleSubmit = async () => {
		const dAppName = name?.trim();
		let dAppUrl: string;
		const dAppInfo = info;
		let cover: string | null = null;

		if (!/^https?:\/\//i.test(url)) {
			dAppUrl = "http://" + url;
		} else {
			dAppUrl = url;
		}

		if (isModifyType && fileList?.length) {
			cover = originalDApp.current?.cover;
		}

		if (!dAppName.length) {
			return Toast.show({
				content: t("DAppNameEmpty"),
			});
		}
		if (byteLength(dAppName) > 12) {
			return Toast.show({
				content: t("DAppNameWordLimit"),
			});
		}
		if (!isValidURL(dAppUrl)) {
			return Toast.show({
				content: t("DAppUrlInvalid"),
			});
		}

		const loadingRef = Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});

		if (fileList?.[0]?.file) {
			try {
				const { file, url: blobUrl } = fileList?.[0];
				const image = await getImageObj(blobUrl);
				const { imageFile } = compress(image, file.type, {
					maxLength: 600,
					minLength: 60,
					quality: 0.5,
				});
				const formData = new FormData();
				formData.append("file", imageFile);
				const result: any = await API.wallets.apiAppUploadFile(
					formData
				);
				if (result?.code === 0) {
					cover = result.content.data;
				} else {
					loadingRef.close();
					return;
				}
			} catch (error) {
				loadingRef.close();
				return;
			}
		}

		try {
			const response: any = await API.wallets.postAppDappsOperation({
				fromId: userId,
				type: 1,
				name: dAppName,
				url: dAppUrl,
				info: dAppInfo,
				cover: cover,
				id: originalDApp.current?.id,
			});
			loadingRef.close();
			if (response?.code === 0) {
				Toast.show(
					isModifyType
						? t("DAppModifySuccessfully")
						: t("DAppAddSuccessfully")
				);
				goBack();
			} else if (response?.message?.length) {
				Toast.show({
					content: response?.message,
				});
			}
		} catch (error) {
			loadingRef.close();
		}
	};

	const byteLength = (str: string) => {
		let l = str.length;
		let blen = 0;
		for (let i = 0; i < l; i++) {
			if ((str.charCodeAt(i) & 0xff00) != 0) {
				blen++;
			}
			blen++;
		}
		return blen;
	};
	return (
		<ParentApp bg="">
			<CustomNavBar
				title={t("CustomizeDApp")}
				rightContent={
					<Button
						color="primary"
						fill="none"
						style={{
							color: "var(--theme)",
							fontSize: "16px",
							paddingLeft: "2px",
							paddingRight: "2px",
						}}
						onClick={handleSubmit}
					>
						{t("Save")}
					</Button>
				}
				onLeftClick={() => {
					goBack();
				}}
			/>
			<div className="container">
				<div className="uploader-box">
					<ImageUploader
						maxCount={1}
						preview={false}
						value={fileList}
						accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp"
						onChange={setFileList}
						upload={preprocessUploadedFile}
					>
						<div className="upload-btn">
							<Image
								src={require("@/images/upload.png")}
								width={32}
								height={32}
							/>
						</div>
					</ImageUploader>
				</div>

				<div
					style={{
						height: "10px",
						backgroundColor: "var(--borderSecondary)",
					}}
				/>

				<ul className="input-list">
					<li>
						<span>{t("DAppName")}</span>
						<Input
							value={name}
							autoComplete="off"
							placeholder={t("DAppNamePlaceholder")}
							onChange={setName}
						/>
					</li>
					<li>
						<span>{t("DAppUrl")}</span>
						<Input
							value={url}
							autoComplete="off"
							placeholder={t("DAppUrlPlaceholder")}
							onChange={setUrl}
						/>
					</li>

					<li>
						<span>{t("DAppDesc")}</span>
						<TextArea
							value={info}
							autoComplete="off"
							rows={3}
							placeholder={t("DAppDescPlaceholder")}
							onChange={setInfo}
						/>
					</li>
				</ul>
			</div>
		</ParentApp>
	);
};

export default CustomizeDApp;
