import useCustomTranslation from "@/hooks/useTranslation";
import { Empty } from "antd-mobile";
import React from "react";

const CustomEmpty: React.FC = () => {
	const { t } = useCustomTranslation();
	return (
		<div className="empty-body">
			<Empty
				style={{ padding: "64px 0" }}
				imageStyle={{ width: "112px" }}
				image={require(`@/images/empty.png`)}
				description={t("Nodata")}
			/>
		</div>
	);
};

export default CustomEmpty;
