import {
  Button,
  Collapse,
  Image,
  InfiniteScroll,
  PullToRefresh,
  Toast
} from 'antd-mobile'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { DownOutline, UpOutline } from 'antd-mobile-icons'
import CustomEmpty from '@/components/empty'
import InfiniteScrollContent from '@/components/InfiniteScrollContent'
import '../style/index.scss'
import API from '@/api'
import {
  getStatusText,
  handleBrowserUrl,
  multiplyWithPrecision,
  partiallyHideHash,
  setDataToClipboard
} from '@/utils/Index'
import useCustomTranslation from '@/hooks/useTranslation'
import useRouter from '@/hooks/useRouter'

const Universal: React.ForwardRefRenderFunction<
  any,
  {
    fromAddress: string
    status: any
    activeIndex: number
  }
> = ({ fromAddress, status, activeIndex }, ref) => {
  const { t } = useCustomTranslation()
  const [data, setData] = useState<any[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const pageSize = 30
  const { toExternalLink } = useRouter()

  const divRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => ({
    postBridgeItemsHandle
  }))

  const bridgeItemOperationHandle = async (params) => {
    const toastObject = Toast.show({
      icon: 'loading',
      content: t('refreshingText'),
      duration: 0
    })
    try {
      const result = await API.wallets.postBridgeItemOperation(params)
      if (result.code === 0) {
        // const contentData = result.content.data;
        Toast.show({
          content: t('CancelSuccess'),
          position: 'top'
        })
        updateItem(params.id)
      } else {
        Toast.show({
          content: result.message,
          position: 'top'
        })
      }
      // toastObject.close();
    } catch (error) {
      toastObject.close()
      console.log('---postBridgeItemOperation--:', error)
    }
  }
  const updateItem = (itemId) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, status: -1 } : item
      )
    )
  }

  const postBridgeItemsHandle = async (isRefresh = false, outParam = {}) => {
    const toastObject = Toast.show({
      icon: 'loading',
      content: t('refreshingText'),
      duration: 0
    })
    try {
      let pageNum = isRefresh ? 0 : page
      const params = {
        page: pageNum,
        pageSize,
        type: 2,
        sourceAddress: fromAddress
      }
      Object.assign(params, outParam)
      // 分页必传 type 1/进 2/出 sourceAddress/来源人
      const result = await API.wallets.postBridgeItems(params)
      if (result.code === 0) {
        const contentData = result.content.data
        const haveMore = result.content.haveMore
        setHasMore(haveMore)
        if (haveMore) {
          if (isRefresh) {
            setPage(1)
          } else {
            setPage((prevPage) => prevPage + 1)
          }
        }
        if (pageNum === 0) {
          setData(contentData)
        } else {
          setData((prevData) => [...prevData, ...contentData])
        }
      }
    } catch (error) {
      console.log('---postBridgeItems--:', error)
    } finally {
      toastObject.close()
    }
  }
  const handleRefresh = async () => {
    // 模拟刷新数据
    setHasMore(true)
    await postBridgeItemsHandle(true)
  }

  const handleFetchMore = async () => {
    // 模拟加载更多数据
    await postBridgeItemsHandle()
  }
  useEffect(() => {
    postBridgeItemsHandle()
  }, [])

  useEffect(() => {
    if (status === null && activeIndex !== 1) return
    const params = {}
    if (status !== -1000) params['status'] = status
    postBridgeItemsHandle(true, params)
  }, [status])

  const browserUrlClick = (unit: string, transNo: string) => {
    let url = handleBrowserUrl(unit, transNo)
    if (url.startsWith('http')) {
      toExternalLink(url, false)
    }
  }

  const handlerTransNo = (unit: string, transNo: string) => {
    let url = handleBrowserUrl(unit, transNo)
    if (url.length > 42) {
      const prefix = url.slice(0, 30)
      const suffix = url.slice(-15)
      const hiddenPart = '......'
      url = `${prefix}${hiddenPart}${suffix}`
    }
    return url
  }

  const compPanelItem = (item) => {
    return (
      //symbol
      <Collapse.Panel key={item.id} title={item.receivedUnit}>
        <ul>
          <li>
            <span className="l-area">{t('TxTime')}</span>
            <span className="r-area">{item.createTime || '-'}</span>
          </li>
          <li>
            <span className="l-area">{t('state')}</span>
            <span className="r-area">{t(getStatusText(item.status))}</span>
          </li>
          <li>
            <span className="l-area">{t('ApplicationAmount')}</span>
            <span className="r-area">
              {item.money || '-'} {item.unit}
            </span>
          </li>
          <li>
            <span className="l-area">{t('handlingfee')}</span>
            <span className="r-area">{item.fee || '-'} {item.unit}</span>
          </li>
          {item.hasOwnProperty('received') ? (
            <li>
              <span className="l-area">{t('SettlementAmount')}</span>
              <span className="r-area">
                {item.received || '-'} {item.receivedUnit}
              </span>
            </li>
          ) : (
            <li>
              <span className="l-area">{t('EstimatedArrival')}</span>
              <span className="r-area">
                {multiplyWithPrecision(item.money, item.fee, 2) || '-'}
                {item.receivedUnit}
              </span>
            </li>
          )}
          <li>
            <span className="l-area">{t('TxCallbackTime')}</span>
            <span className="r-area">
              {item.status == 4 ? item.updateTime : '-'}
            </span>
          </li>
          <li>
            <span className="l-area">{t('ReceivedAddress')}</span>
            <span className="r-area order-no">
              {partiallyHideHash(item.receivedAddress)}
            </span>
            <Image
              src={require('@/images/copy_2.png')}
              width={24}
              height={24}
              fit="fill"
              style={{ marginLeft: '5px' }}
              onClick={() => {
                Toast.show({
                  content: t('copiedSucc')
                })
                setDataToClipboard(item.receivedAddress)
              }}
            />
          </li>
					<li>
            <span className="l-area">{t('TransactionHash')}</span>
            <span className="r-area order-no">
              {partiallyHideHash(item.transNo) ?? '-'}
            </span>
            { item.transNo ? <Image
              src={require('@/images/copy_2.png')}
              width={24}
              height={24}
              fit="fill"
              style={{ marginLeft: '5px' }}
              onClick={() => {
                Toast.show({
                  content: t('copiedSucc')
                })
                setDataToClipboard(item.transNo)
              }}
            /> : null } 
          </li>
          <li>
            <span className="l-area">{t('TransactionBrowser')}</span>
          </li>
          <li
            onClick={() => browserUrlClick(item.receivedUnit, item.transNo)}
            style={{ height: '100%' }}
          >
            <div className="outer-uri">
              {handlerTransNo(item.receivedUnit, item.transNo)}
            </div>
          </li>
          {[0].includes(item.status) && (
            <li>
              <span className="l-area"></span>
              <Button
                onClick={() =>
                  bridgeItemOperationHandle({
                    id: item.id,
                    status: -1,
                    sourceAddress: item.sourceAddress
                  })
                }
                fill="outline"
                size="small"
                color="primary"
              >
                {t('CancelWithdrawal')}
              </Button>
            </li>
          )}
        </ul>
      </Collapse.Panel>
    )
  }

  return (
    <div style={{ height: '100%', overflowY: 'auto' }} ref={divRef}>
      <PullToRefresh
        onRefresh={handleRefresh}
        canReleaseText={t('canReleaseText')}
        completeText={t('completeText')}
        pullingText={t('pullingText')}
        refreshingText={t('refreshingText')}
      >
        {data.length === 0 ? (
          <CustomEmpty />
        ) : (
          <div className="record-area">
            <Collapse
              defaultActiveKey={[data[0].id.toString()]}
              arrow={(active) =>
                active ? (
                  <Image
                    src={require('@/images/downOutline.png')}
                    width={14}
                    style={{ transform: 'rotate(180deg)' }}
                  />
                ) : (
                  <Image src={require('@/images/downOutline.png')} width={14} />
                )
              }
            >
              {data.map((item, index) => {
                return compPanelItem(item)
              })}
            </Collapse>
          </div>
        )}
        {data.length > 0 && (
          <InfiniteScroll loadMore={handleFetchMore} hasMore={hasMore}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        )}
      </PullToRefresh>
    </div>
  )
}

export default Universal
{
  /* <div
	style={{
		display: "flex",
		justifyContent: "space-between",
		padding: "10px 15px",
	}}
>
	<div
		onClick={() => {
			setVisible(true);
		}}
		style={{
			display: "flex",
			alignItems: "center",
		}}
	>
		<FilterOutline fontSize={24} />
		<span style={{ color: "#2D374F", fontSize: "16px" }}>
			{t("filter")}
		</span>
	</div>
	<Button onClick={handleRefresh} color="primary" size="small" fill="outline">
		{t("refresh")}
	</Button>
</div>; */
}
