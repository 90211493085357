import CustomNavBar from "@/components/navBar";
import useRouter from "@/hooks/useRouter";
import React from "react";
import { formatTimeover } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import { Avatar, Image, NavBar } from "antd-mobile";
import ParentApp from "@/components/parentApp";

const Market: React.FC<{ data: any }> = ({ data }) => {
	const { t } = useCustomTranslation();
	const { goBack } = useRouter();

	return (
		<ParentApp bg="white">
			<section
				className="section-main"
				style={{ backgroundColor: "white" }}
			>
				<NavBar
					backArrow={
						<div style={{ display: "flex" }}>
							<Avatar
								src={require(`@/images/back.png`)}
								style={{ "--size": "24px" }}
							/>
							<span
								style={{
									fontSize: "16px",
									fontWeight: "500",
									color: "#7D869D",
									paddingLeft: "8px",
								}}
							>
								{t("News")}
							</span>
						</div>
					}
					// right={
					// 	<Avatar
					// 		src={require(`@/images/share.png`)}
					// 		style={{
					// 			"--size": "24px",
					// 			display: "inline-block",
					// 		}}
					// 	/>
					// }
					onBack={goBack}
					style={{ color: "#2D374F" }}
				></NavBar>
				<article className="isolate-area">
					<h3 className="title">{data.title || "-"}</h3>
					<p className="time">
						{data.createTime
							? formatTimeover(data.createTime)
							: "-"}
					</p>
					<div
						className="content"
						dangerouslySetInnerHTML={{
							__html: data.content,
						}}
					></div>
				</article>
			</section>
		</ParentApp>
	);
};

export default Market;
