import {
	Collapse,
	Image,
	InfiniteScroll,
	PullToRefresh,
	Toast,
} from "antd-mobile";
import { DownOutline, UpOutline } from "antd-mobile-icons";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import CustomEmpty from "@/components/empty";
import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import "../style/index.scss";
import API from "@/api";
import {
	getStatusText,
	handleBrowserUrl,
	partiallyHideHash,
	setDataToClipboard,
} from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import useRouter from "@/hooks/useRouter";

const Universal: React.ForwardRefRenderFunction<
	any,
	{
		fromAddress: string;
		status: any;
		activeIndex: number;
	}
> = ({ fromAddress, status, activeIndex }, ref) => {
	const { t } = useCustomTranslation();
	const [data, setData] = useState<any[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const pageSize = 30;
	const { toExternalLink } = useRouter();

	const divRef = useRef<HTMLDivElement>(null);
	useImperativeHandle(ref, () => ({
		postBridgeItemsHandle,
	}));

	const postBridgeItemsHandle = async (isRefresh = false, outParam = {}) => {
		const toastObject = Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});
		try {
			let pageNum = isRefresh ? 0 : page;
			// 分页必传 type 1/进 2/出 sourceAddress/来源人
			const params = {
				page: pageNum,
				pageSize,
				type: 1,
				sourceAddress: fromAddress,
			};
			Object.assign(params, outParam);
			const result = await API.wallets.postBridgeItems(params);
			if (result.code === 0) {
				const contentData = result.content.data;
				const haveMore = result.content.haveMore;
				setHasMore(haveMore);
				if (haveMore) {
					if (isRefresh) {
						setPage(1);
					} else {
						setPage((prevPage) => prevPage + 1);
					}
				}
				if (pageNum === 0) {
					setData(contentData);
				} else {
					setData((prevData) => [...prevData, ...contentData]);
				}
			}
		} catch (error) {
			console.log("---postBridgeItems--:", error);
		} finally {
			toastObject.close();
		}
	};
	const handleRefresh = async () => {
		// 模拟刷新数据
		setHasMore(true);
		await postBridgeItemsHandle(true);
	};

	const handleFetchMore = async () => {
		// 模拟加载更多数据
		await postBridgeItemsHandle();
	};

	const browserUrlClick = (unit: string, transNo: string) => {
		let url = handleBrowserUrl(unit, transNo);
		if (url.startsWith("http")) {
			toExternalLink(url, false);
		}
	};

	useEffect(() => {
		postBridgeItemsHandle();
	}, []);

	useEffect(() => {
		if (status === null && activeIndex !== 0) return;
		const params = {};
		if (status !== -1000) params["status"] = status;
		postBridgeItemsHandle(true, params);
	}, [status]);

	const handlerTransNo = (unit: string, transNo: string) => {
		let url = handleBrowserUrl(unit, transNo);
		if (url.length > 42) {
			const prefix = url.slice(0, 24);
			const suffix = url.slice(-15);
			const hiddenPart = "......";
			url = `${prefix}${hiddenPart}${suffix}`;
		}
		return url;
	};

	const compPanelItem = (item) => {
		return (
			<Collapse.Panel key={item.id} title={item.unit}>
				<ul>
					<li>
						<span className="l-area">{t("PayTime")}</span>
						<span className="r-area">{item.createTime || "-"}</span>
					</li>
					<li>
						<span className="l-area">{t("state")}</span>
						<span className="r-area">
							{t(getStatusText(item.status))}
						</span>
					</li>
					<li>
						<span className="l-area">{t("ApplicationAmount")}</span>
						<span className="r-area">
							{item.money || "-"} {item.unit}
						</span>
					</li>
					<li>
						<span className="l-area">{t("SettlementAmount")}</span>
						<span className="r-area">
							{item.received || "-"} {item.receivedUnit}
						</span>
					</li>
					<li>
						<span className="l-area">{t("CoinTime")}</span>
						<span className="r-area">
							{item.status == 4 ? item.updateTime : "-"}
						</span>
					</li>
					<li
						onClick={() => {
							Toast.show({
								content: t("copiedSucc"),
							});
							setDataToClipboard(item.transNo);
						}}
					>
						<span className="l-area">
							{t("TransactionVoucher")}
						</span>
						<span className="r-area order-no">
							{partiallyHideHash(item.transNo)}
						</span>
						<Image
							src={require("@/images/copy_2.png")}
							width={24}
							height={24}
							fit="fill"
							style={{ marginLeft: "5px" }}
						/>
					</li>
					<li>
						<span className="l-area">
							{t("TransactionBrowser")}
						</span>
					</li>
					<li
						onClick={() => browserUrlClick(item.unit, item.transNo)}
						style={{ height: "100%" }}
					>
						<div className="outer-uri">
							{handlerTransNo(item.unit, item.transNo)}
						</div>
					</li>
					{/* <li>
						<span className="l-area">进度</span>
						<span className="r-area">{item.updateTime || "-"}</span>
					</li> */}
				</ul>
			</Collapse.Panel>
		);
	};
	return (
		<div style={{ height: "100%", overflowY: "auto" }} ref={divRef}>
			<PullToRefresh
				onRefresh={handleRefresh}
				canReleaseText={t("canReleaseText")}
				completeText={t("completeText")}
				pullingText={t("pullingText")}
				refreshingText={t("refreshingText")}
			>
				{data.length === 0 ? (
					<CustomEmpty />
				) : (
					<div className="record-area">
						<Collapse
							defaultActiveKey={[data[0].id.toString()]}
							arrow={(active) =>
								active ? (
									<Image
										src={require("@/images/downOutline.png")}
										width={14}
										style={{ transform: "rotate(180deg)" }}
									/>
								) : (
									<Image
										src={require("@/images/downOutline.png")}
										width={14}
									/>
								)
							}
						>
							{data.map((item, index) => {
								return compPanelItem(item);
							})}
						</Collapse>
					</div>
				)}
				{data.length > 0 && (
					<InfiniteScroll
						loadMore={handleFetchMore}
						hasMore={hasMore}
					>
						<InfiniteScrollContent hasMore={hasMore} />
					</InfiniteScroll>
				)}
			</PullToRefresh>
		</div>
	);
};

export default Universal;
{
	/* <div
	style={{
		display: "flex",
		justifyContent: "space-between",
		padding: "10px 15px",
	}}
>
	<div
		onClick={() => {
			setVisible(true);
		}}
		style={{
			display: "flex",
			alignItems: "center",
		}}
	>
		<FilterOutline fontSize={24} />
		<span style={{ color: "#2D374F", fontSize: "16px" }}>
			{t("filter")}
		</span>
	</div>
	<Button onClick={handleRefresh} color="primary" size="small" fill="outline">
		{t("refresh")}
	</Button>
</div>; */
}
