import {
	Avatar,
	Button,
	InfiniteScroll,
	PullToRefresh,
	Toast,
	Image,
} from "antd-mobile";
import React, { useEffect, useId, useImperativeHandle, useState } from "react";
import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import API from "@/api";
import { AppDispatch, RootState } from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { addUsed } from "@/redux/reducers/recentlyUsed";
import useRouter from "@/hooks/useRouter";
import "../style/index.scss";
import CustomEmpty from "@/components/empty";
import { getCoverUri, getInfoFromUserAgent, isIos } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import useDappsOperation from "@/hooks/useDappsOperation";
import { DAppItem } from "@/pages/home/type";
const Universal: React.ForwardRefRenderFunction<
	any,
	{ dappStateChanged?: (id: number, added: boolean) => void }
> = ({ dappStateChanged }, ref) => {
	const { t } = useCustomTranslation();
	const dispatch: AppDispatch = useDispatch();
	const localUseds = useSelector((state: RootState) => state.used.data);
	const userId = useSelector((state: RootState) => state.hot.userId);
	const { toExternalLink } = useRouter();
	const { dappsOperationyHandle } = useDappsOperation();

	const [data, setData] = useState<DAppItem[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const pageSize = 30;
	// type 1/拉普通列表 不传则给推荐和普通列表 分页必传
	const postAppDappsListHandle = async ({ isRefresh = false }) => {
		try {
			let pageNum = isRefresh ? 0 : page;
			const params = {
				page: pageNum,
				pageSize,
				type: 2,
				fromId: userId,
				version: `${isIos ? 2 : 1}_${
					getInfoFromUserAgent("AppVersion") ?? "1.0.0"
				}`,
			};
			const result = await API.wallets.postAppDappsList(params);
			if (result.code === 0) {
				const contentData = result.content.data;
				const haveMore = result.content.haveMore;
				setHasMore(haveMore);
				if (haveMore) {
					if (isRefresh) {
						setPage(1);
					} else {
						setPage((prevPage) => prevPage + 1);
					}
				}
				if (pageNum === 0) {
					setData(contentData);
				} else {
					setData((prevData) => [...prevData, ...contentData]);
				}
			}
		} catch (error) {
			console.log("---postAppDappsList--:", error);
			setHasMore(false);
		} finally {
		}
	};
	const handleRefresh = async () => {
		console.log("handleRefresh-------------");
		// 模拟刷新数据
		await postAppDappsListHandle({
			isRefresh: true,
		});
	};

	const handleFetchMore = async () => {
		console.log("handleFetchMore-------------");
		// 模拟加载更多数据
		await postAppDappsListHandle({});
	};

	const handleAddUsed = (data) => {
		dispatch(addUsed(data));
	};

	const handleOperateDApp = async (dapp: DAppItem) => {
		const setAdded = !dapp.settingId;
		const result = await dappsOperationyHandle(
			setAdded
				? { type: 2, id: dapp.id }
				: {
						type: 2,
						id: dapp.id,
						remove: 1,
				  }
		);
		if (result) {
			dappStateChanged?.(dapp.id, setAdded);
			setData((prevList) =>
				prevList.map((item) =>
					item.id === dapp.id
						? { ...item, settingId: setAdded ? 1 : undefined }
						: item
				)
			);
		}
	};

	useEffect(() => {
		handleRefresh();
	}, []);

	useImperativeHandle(ref, () => ({
		dappStateChanged: (id: number, added: boolean) => {
			setData((prevList) =>
				prevList.map((item) =>
					item.id === id
						? { ...item, settingId: added ? 1 : undefined }
						: item
				)
			);
		},
	}));

	const openWindowSeft = (itemData) => {
		if (itemData.url) {
			let hidden = itemData.header === 0 ? true : false;
			toExternalLink(itemData.url, hidden);
		}
	};
	return (
		<div style={{ height: "100%", overflowY: "auto" }}>
			<PullToRefresh
				onRefresh={handleRefresh}
				canReleaseText={t("canReleaseText")}
				completeText={t("completeText")}
				pullingText={t("pullingText")}
				refreshingText={t("refreshingText")}
			>
				{/* <div className="header-hot">
          <Image
            src={require(`@/images/hot.png`)}
            width={12}
            height={16}
            fit="fill"
          />
          <span>{t('DAppsRec')}</span>
          <Image
            src={require(`@/images/hot.png`)}
            width={12}
            height={16}
            fit="fill"
          />
        </div> */}
				{data.length === 0 ? (
					<CustomEmpty />
				) : (
					<div className="list-area">
						{data.map((item, index) => {
							return (
								<div
									onClick={() => {
										handleAddUsed(item);
										openWindowSeft(item);
									}}
									className="list-item"
									key={item.id + "_" + index}
								>
									<div className="icon-wrap pt-3">
										<Avatar
											src={getCoverUri(item.cover)}
											style={{
												"--size": "40px",
												"--border-radius": "40px",
											}}
										/>
									</div>
									<div className="content">
										<div className="name">{item.name}</div>
										<div className="desc">{item.info}</div>
									</div>
									<Button
										onClick={(event) => {
											event.stopPropagation();
											event.nativeEvent.stopImmediatePropagation();
											handleOperateDApp(item);
										}}
										size="mini"
										shape="rounded"
										style={{
											fontSize: "10px",
											"--text-color": item.settingId
												? "#8B8BA1"
												: "#4B6DE3",
											"--background-color": item.settingId
												? "#E8F0F7"
												: "#E8F0F7",
										}}
									>
										{item.settingId ? t("Added") : t("Add")}
									</Button>
								</div>
							);
						})}
					</div>
				)}
				{data.length > 0 && (
					<InfiniteScroll
						loadMore={handleFetchMore}
						hasMore={hasMore}
					>
						<InfiniteScrollContent hasMore={hasMore} />
					</InfiniteScroll>
				)}
			</PullToRefresh>
		</div>
	);
};

export default Universal;
