import {
	Avatar,
	Button,
	InfiniteScroll,
	PullToRefresh,
	Toast,
	Image,
} from "antd-mobile";
import React, { useEffect, useImperativeHandle, useState } from "react";
import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import API from "@/api";
import { AppDispatch, RootState } from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { addUsed } from "@/redux/reducers/recentlyUsed";
import useRouter from "@/hooks/useRouter";
import "../style/index.scss";
import CustomEmpty from "@/components/empty";
import { getCoverUri } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import useDappsOperation from "@/hooks/useDappsOperation";
import { DAppItem } from "@/pages/home/type";
import { clone, pick } from "lodash";
import { useActivate } from "react-activation";
import eventBus from "@/utils/event";
import { ToastHandler } from "antd-mobile/es/components/toast";

const Universal: React.ForwardRefRenderFunction<
	any,
	{ dappStateChanged?: (id: number, added: boolean) => void }
> = ({ dappStateChanged }, ref) => {
	const { t } = useCustomTranslation();
	const dispatch: AppDispatch = useDispatch();
	const userId = useSelector((state: RootState) => state.hot.userId);
	const { toExternalLink, customNavigate } = useRouter();
	const { dappsOperationyHandle } = useDappsOperation();
	const [data, setData] = useState<DAppItem[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const pageSize = 30;

	useActivate(() => {
		postAppDappsListHandle({});
	});

	useEffect(() => {
		const callback = () => {
			postAppDappsListHandle({});
		};
		eventBus.addListener("viewAppeared", callback);
		return () => {
			eventBus.removeListener("viewAppeared", callback);
		};
	}, []);

	// type 1/拉普通列表 不传则给推荐和普通列表 分页必传
	const postAppDappsListHandle = async ({ isRefresh = false }) => {
		try {
			let pageNum = isRefresh ? 0 : page;
			const params = {
				page: pageNum,
				pageSize,
				type: 3,
				fromId: userId,
			};
			const result = await API.wallets.postAppDappsList(params);
			if (result.code === 0) {
				const contentData = result.content.data;
				const haveMore = result.content.haveMore;
				setHasMore(haveMore);
				if (haveMore) {
					if (isRefresh) {
						setPage(1);
					} else {
						setPage((prevPage) => prevPage + 1);
					}
				}
				if (pageNum === 0) {
					setData(contentData);
				} else {
					setData((prevData) => [...prevData, ...contentData]);
				}
			}
		} catch (error) {
			console.log("---postAppDappsList--:", error);
			setHasMore(false);
		} finally {
		}
	};
	const handleRefresh = async () => {
		// 模拟刷新数据
		await postAppDappsListHandle({
			isRefresh: true,
		});
	};

	const handleFetchMore = async () => {
		// 模拟加载更多数据
		await postAppDappsListHandle({});
	};

	const handleAddUsed = (data) => {
		dispatch(addUsed(data));
	};

	useEffect(() => {
		handleRefresh();
	}, []);

	useImperativeHandle(ref, () => ({
		dappStateChanged: (id: number, added: boolean) => {
			setData((prevList) =>
				prevList.map((item) =>
					item.id === id
						? { ...item, settingId: added ? 1 : undefined }
						: item
				)
			);
		},
	}));

	const openWindowSeft = (itemData) => {
		if (itemData.url) {
			let hidden = itemData.header === 0 ? true : false;
			toExternalLink(itemData.url, hidden);
		}
	};

	const handleOperateDApp = async (dapp: DAppItem) => {
		const setAdded = !dapp.settingId;
		const result = await dappsOperationyHandle(
			setAdded
				? { type: 2, id: dapp.id }
				: {
						type: 2,
						id: dapp.id,
						remove: 1,
				  }
		);
		if (result) {
			dappStateChanged?.(dapp.id, setAdded);
			setData((prevList) =>
				prevList.map((item) =>
					item.id === dapp.id
						? { ...item, settingId: setAdded ? 1 : undefined }
						: item
				)
			);
		}
	};

	const handleDeleteDApp = async (dapp: DAppItem) => {
		const result = await dappsOperationyHandle({
			type: 1,
			id: dapp.id,
			remove: 1,
		});
		if (result) {
			dappStateChanged?.(dapp.id, false);
			setData((prevList) => {
				const newList = [...prevList];
				const index = newList.findIndex((item) => item.id == dapp.id);
				if (index >= 0) {
					newList.splice(index, 1);
				}
				return newList;
			});
		}
	};

	const handleEditDApp = (dapp: DAppItem) => {
		const tmpDApp = pick(dapp, ["id", "name", "url", "info", "cover"]);
		tmpDApp.url = encodeURIComponent(tmpDApp.url);
		tmpDApp.cover = tmpDApp.cover?.length
			? encodeURIComponent(tmpDApp.cover)
			: null;
		customNavigate(
			`/customizeDapp/${encodeURIComponent(JSON.stringify(tmpDApp))}`
		);
	};

	const onCustomizeDAppClicked = () => {
		customNavigate(
			"/customizeDapp/" + encodeURIComponent(JSON.stringify({}))
		);
	};

	return (
		<div className="custom-dapp-container">
			<div style={{ flex: 1, overflowY: "auto" }}>
				<PullToRefresh
					onRefresh={handleRefresh}
					canReleaseText={t("canReleaseText")}
					completeText={t("completeText")}
					pullingText={t("pullingText")}
					refreshingText={t("refreshingText")}
				>
					{data.length === 0 ? (
						<CustomEmpty />
					) : (
						<div className="list-area">
							{data.map((item, index) => {
								return (
									<div
										onClick={() => {
											handleAddUsed(item);
											openWindowSeft(item);
										}}
										className="list-item"
										key={item.id + "_" + index}
									>
										<div className="icon-wrap pt-3">
											<Avatar
												src={getCoverUri(item.cover)}
												style={{
													"--size": "40px",
													"--border-radius": "40px",
												}}
											/>
										</div>
										<div className="content">
											<div className="name">
												{item.name}
											</div>
											<div className="desc">
												{item.info}
											</div>
										</div>
										<Image
											src={require("@/images/edit.png")}
											width={24}
											height={24}
											style={{
												padding: "4px",
												marginRight: "2px",
											}}
											fit="fill"
											onClick={(event) => {
												event.stopPropagation();
												event.nativeEvent.stopImmediatePropagation();
												handleEditDApp(item);
											}}
										/>
										<Image
											src={require("@/images/del.png")}
											width={26}
											height={28}
											style={{
												padding: "4px",
												marginRight: "2px",
											}}
											fit="fill"
											onClick={(event) => {
												event.stopPropagation();
												event.nativeEvent.stopImmediatePropagation();
												handleDeleteDApp(item);
											}}
										/>
										<Button
											onClick={(event) => {
												event.stopPropagation();
												event.nativeEvent.stopImmediatePropagation();
												handleOperateDApp(item);
											}}
											size="mini"
											shape="rounded"
											style={{
												fontSize: "10px",
												"--text-color": item.settingId
													? "#8B8BA1"
													: "#4B6DE3",
												"--background-color":
													item.settingId
														? "#E8F0F7"
														: "#E8F0F7",
											}}
										>
											{item.settingId
												? t("Added")
												: t("Add")}
										</Button>
									</div>
								);
							})}
						</div>
					)}
					{data.length > 0 && (
						<InfiniteScroll
							loadMore={handleFetchMore}
							hasMore={hasMore}
						>
							<InfiniteScrollContent hasMore={hasMore} />
						</InfiniteScroll>
					)}
				</PullToRefresh>
			</div>

			<div style={{ flex: "none", padding: "10px 20px 5px 20px" }}>
				<Button
					block
					color="primary"
					size="large"
					shape="rounded"
					style={{
						"--background-color": "#4B6DE3",
						fontSize: "16px",
					}}
					onClick={() => {
						onCustomizeDAppClicked();
					}}
				>
					{"+ " + t("Customized")}
				</Button>
			</div>
			<div className="header-added">
				<span>{t("Automatically")}</span>
			</div>
		</div>
	);
};

export default Universal;
