import { round } from "lodash";
import { SortOrder } from "../api/type";

type UnitMappings = {
	[key: string]: string;
};

export function getLogo(unit: string) {
	const unitMappings: UnitMappings = {
		ADA: "ADA",
		ASTRA: "ASTRA",
		AUSDC: "AUSDC",
		AUSDT: "AUSDT",
		AWBTC: "AWBTC",
		BCH: "BCH",
		BTC: "BTC",
		BNB: "BNB",
		DOGE: "DOGE",
		DOT: "DOT",
		ETH: "ETH",
		MATIC: "MATIC",
		MZLC: "MZLC",
		SOL: "SOL",
		QZLC: "QZLC",
		TRX: "TRX",
		XRP: "XRP",
		ZLC: "ZLC",
	};
	const logoPath = unitMappings[unit] || "";
	if (logoPath) {
		return logoPath;
	}
	return null;
}

interface sortTpey {
	[key: string]: string | number;
}

export function sortByData<T extends sortTpey>(
	array: T[],
	key: string,
	sortOrder: SortOrder = "asc",
	tragetVal?: any[]
): T[] {
	if (sortOrder === "normal" && tragetVal?.length) {
		return array.sort((a, b) => {
			const valueA = a[key];
			const valueB = b[key];
			return tragetVal.indexOf(valueA) - tragetVal.indexOf(valueB);
		});
	}
	return array.sort((a, b) => {
		const valueA = a[key];
		const valueB = b[key];
		let result: number;
		if (key === "unit") {
			result = String(valueA).localeCompare(String(valueB), undefined, {
				sensitivity: "base",
			});
		} else {
			result = +valueA - +valueB;
		}
		return sortOrder === "desc" ? -result : result;
	});
}

export function formatVolume(volume: number): string {
	const units = ["", "K", "M", "B", "T", "P", "E", "Z", "Y"];
	let i = 0;
	while (volume >= 1000 && i < units.length - 1) {
		volume /= 1000;
		i++;
	}
	return volume.toFixed(2) + units[i];
}

function isNullOrUndefined(value: unknown): value is null | undefined {
	return value === null || value === undefined;
}

export function addThousandsSeparator(
	num: any,
	maxDecimalPlaces: number = 2
): string {
	if (isNullOrUndefined(num)) {
		num = "0";
	}
	if (typeof num === "string") {
		num = parseFloat(num);
	}

	num = num.toFixed(maxDecimalPlaces);
	let arrData = num.split(".");
	if (arrData.length > 1) {
		while (num.split(".")[1].length < maxDecimalPlaces) {
			num += "0";
		}
	} else {
		let decimalPart = "";
		while (decimalPart.length < maxDecimalPlaces) {
			decimalPart += "0";
		}
		num = "." + decimalPart;
	}

	return Number(num).toLocaleString(undefined, {
		minimumFractionDigits: maxDecimalPlaces,
		maximumFractionDigits: maxDecimalPlaces,
	});
}

export function roundToDecimalPlaces(
	inputNumber: number,
	decimalPlaces: number
): number {
	if (decimalPlaces < 0) {
		throw new Error("decimalPlaces 参数必须大于等于 0");
	}
	const multiplier = Math.pow(10, decimalPlaces);
	const roundedNumber = Math.round(inputNumber * multiplier) / multiplier;

	return roundedNumber;
}

export function isEmpty(val: any) {
	if (
		val == null ||
		typeof val === "undefined" ||
		val === undefined ||
		val === "undefined" ||
		("" + val).toUpperCase() === "NULL" ||
		"" + val === ""
	) {
		return true;
	}
	if (typeof val === "boolean") {
		return !val;
	}
	if (val instanceof Error) return val.message === "";
	switch (Object.prototype.toString.call(val)) {
		// String or Array
		case "[object String]":
		case "[object Array]":
			return !val.length;
		case "[object File]":
		case "[object Map]":
		case "[object Set]":
			return !val.size;
		case "[object Object]":
			return !Object.keys(val).length;
	}

	return false;
}

export const isFunction = (val: unknown): val is Function =>
	typeof val === "function";

export const isObject = (val: unknown): val is Record<any, any> =>
	val !== null && typeof val === "object";

export const isPromise = <T = any,>(val: unknown): val is Promise<T> =>
	isObject(val) && isFunction(val.then) && isFunction(val.catch);

// 格式化时间的格式
export function dateFormat(date: Date, format: string): string {
	const o: Record<string, number> = {
		"M+": date.getMonth() + 1,
		"d+": date.getDate(),
		"h+": date.getHours(),
		"m+": date.getMinutes(),
		"s+": date.getSeconds(),
		"q+": Math.floor((date.getMonth() + 3) / 3),
		S: date.getMilliseconds(),
	};

	if (/([Yy]+)/.test(format)) {
		format = format.replace(
			RegExp.$1,
			(date.getFullYear() + "").substr(4 - RegExp.$1.length)
		);
	}

	for (const k in o) {
		if (new RegExp("(" + k + ")").test(format)) {
			format = format.replace(
				RegExp.$1,
				RegExp.$1.length === 1
					? o[k].toString()
					: ("00" + o[k]).substr(("" + o[k]).length)
			);
		}
	}

	return format;
}

export function toFixed(number: any) {
	return toNumberFixed(number, 2);
}

export function toNumberFixed(number: any, decimal: number) {
	if (!number) return "0.00";
	if (typeof number == "string") {
		number = parseFloat(number);
	}
	let decimalNumber = 10 ** decimal;
	let value = (number * decimalNumber).toFixed(decimal);
	value = (parseInt(value) / decimalNumber).toFixed(decimal);
	return value;
}

/**
 * 获取剪贴板内容
 * @returns {Promise<string>} 剪贴板内容
 * // 使用示例
 * copyToClipboard().then((text) => {
 * 	console.log('剪贴板内容:', text);
 * });
 */
export async function copyToClipboard(): Promise<string> {
	try {
		// 检查是否支持 Clipboard API
		if (navigator.clipboard && navigator.clipboard.readText) {
			// 使用 Clipboard API 获取剪贴板内容
			return await navigator.clipboard.readText();
		} else {
			// 兼容旧版浏览器
			// 创建一个临时的 textarea 元素
			const textarea = document.createElement("textarea");
			textarea.style.position = "fixed"; // 避免影响布局
			document.body.appendChild(textarea);
			textarea.select();
			textarea.setSelectionRange(0, textarea.value.length);

			// 执行命令以获取剪贴板内容
			const success = document.execCommand("paste");
			let clipboardText = "";

			if (success) {
				clipboardText = textarea.value;
			}

			// 移除临时的 textarea 元素
			document.body.removeChild(textarea);

			return clipboardText;
		}
	} catch (error) {
		console.error("Failed to read clipboard contents:", error);
		return "";
	}
}

export function getUuid() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
		.replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		})
		.replace(/-/g, "");
}

export function getTickClassName(value: string) {
	if (isEmpty(value)) return;
	let className = "";
	switch (value) {
		case "PlusTick":
		case "ZeroPlusTick":
			className = "long";
			break;
		case "MinusTick":
		case "ZeroMinusTick":
			className = "short";
			break;
	}
	return className;
}

function getTimeValue(key) {
	const Language = localStorage.getItem("Language");
	const langData = {
		en: {
			monthAgo: "month ago",
			weekAgo: "week ago",
			dayAgo: "day ago",
			hourAgo: "hour ago",
			minutesAgo: "minutes ago",
			just: "just",
		},
		zh_CN: {
			monthAgo: "月前",
			weekAgo: "周前",
			dayAgo: "天前",
			hourAgo: "小时前",
			minutesAgo: "分钟前",
			just: "刚刚",
		},
		zh_HK: {
			monthAgo: "月前",
			weekAgo: "週前",
			dayAgo: "天前",
			hourAgo: "小時前",
			minutesAgo: "分鐘前",
			just: "剛剛",
		},
	};
	if (langData[Language]) {
		return langData[Language][key];
	} else {
		return langData["en"][key];
	}
}

export function formatTimeover(dateTime) {
	let minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
	let hour = minute * 60;
	let day = hour * 24;
	let week = day * 7;
	// let halfamonth = day * 15;
	let month = day * 30;
	let now = new Date().getTime(); //获取当前时间毫秒
	const dateTimeStamp: number = new Date(
		dateTime.replace(/-/g, "/")
	).getTime();
	let diffValue = now - dateTimeStamp; //时间差

	if (diffValue < 0) {
		return;
	}

	let minC = diffValue / minute; //计算时间差的分，时，天，周，月
	let hourC = diffValue / hour;
	let dayC = diffValue / day;
	let weekC = diffValue / week;
	let monthC = diffValue / month;
	let result = "-";
	if (monthC >= 1 && monthC <= 3) {
		result =
			" " +
			parseInt(monthC.toString(), 10) +
			" " +
			getTimeValue("monthAgo");
	} else if (weekC >= 1 && weekC <= 3) {
		result =
			" " +
			parseInt(weekC.toString(), 10) +
			" " +
			getTimeValue("weekAgo");
	} else if (dayC >= 1 && dayC <= 6) {
		result =
			" " + parseInt(dayC.toString(), 10) + " " + getTimeValue("dayAgo");
	} else if (hourC >= 1 && hourC <= 23) {
		result =
			" " +
			parseInt(hourC.toString(), 10) +
			" " +
			getTimeValue("hourAgo");
	} else if (minC >= 1 && minC <= 59) {
		result =
			" " +
			parseInt(minC.toString(), 10) +
			" " +
			getTimeValue("minutesAgo");
	} else if (diffValue >= 0 && diffValue <= minute) {
		result = getTimeValue("just");
	} else {
		let datetime = new Date();
		datetime.setTime(dateTimeStamp);
		let Nyear = datetime.getFullYear();
		let Nmonth =
			datetime.getMonth() + 1 < 10
				? "0" + (datetime.getMonth() + 1)
				: datetime.getMonth() + 1;
		let Ndate =
			datetime.getDate() < 10
				? "0" + datetime.getDate()
				: datetime.getDate();
		let Nhour =
			datetime.getHours() < 10
				? "0" + datetime.getHours()
				: datetime.getHours();
		let Nminute =
			datetime.getMinutes() < 10
				? "0" + datetime.getMinutes()
				: datetime.getMinutes();
		let Nsecond =
			datetime.getSeconds() < 10
				? "0" + datetime.getSeconds()
				: datetime.getSeconds();
		result =
			Nyear +
			"-" +
			Nmonth +
			"-" +
			Ndate +
			" " +
			Nhour +
			":" +
			Nminute +
			":" +
			Nsecond;
	}
	return result;
}

/**
 * 将文本设置到剪贴板
 * @param text 要复制到剪贴板的文本
 * // 使用示例
 *  setDataToClipboard('Hello, world!')
 * .then(() => console.log('Text copied to clipboard!'))
 * .catch((err) => console.error('Failed to copy text:', err));
 */
export async function setDataToClipboard(text: string): Promise<void> {
	if (!text) {
		return;
	}

	try {
		// 检查是否支持 Clipboard API
		if (navigator.clipboard && navigator.clipboard.writeText) {
			// 使用 Clipboard API 复制文本
			await navigator.clipboard.writeText(text);
		} else {
			// 兼容旧版浏览器
			// 创建一个临时的 textarea 元素
			const fakeElem = document.createElement("textarea");
			fakeElem.style.position = "absolute"; // 避免影响布局
			fakeElem.style.border = "0";
			fakeElem.style.padding = "0";
			fakeElem.style.margin = "0";
			fakeElem.style.width = "1px";
			fakeElem.style.height = "1px";
			fakeElem.style.opacity = "0"; // 隐藏元素
			fakeElem.value = text;

			// 添加到 DOM 中
			document.body.appendChild(fakeElem);
			fakeElem.select();
			fakeElem.setSelectionRange(0, fakeElem.value.length);

			// 执行复制命令
			const success = document.execCommand("copy");

			if (!success) {
				console.error("Failed to copy text to clipboard");
			}

			// 移除临时的 textarea 元素
			document.body.removeChild(fakeElem);
		}
	} catch (error) {
		console.error("Failed to copy text to clipboard:", error);
	}
}

// 将十六进制字符串转换为 Uint8Array
export function hexToUint8Array(hexString) {
	const bytes = [];
	for (let i = 0; i < hexString.length; i += 2) {
		bytes.push(parseInt(hexString.substr(i, 2), 16));
	}
	return new Uint8Array(bytes);
}

export function partiallyHideHash(hash) {
	const prefixLength = 5; // 指定要显示的哈希前缀长度
	const suffixLength = 4; // 指定要显示的哈希后缀长度

	if (!hash || hash.length <= prefixLength + suffixLength) {
		return hash; // 如果哈希长度小于等于指定的前缀和后缀长度之和，则不进行隐藏
	}

	const prefix = hash.slice(0, prefixLength);
	const suffix = hash.slice(-suffixLength);

	const hiddenPart = "..."; // 隐藏部分的标记，你可以根据需要自定义

	return `${prefix}${hiddenPart}${suffix}`;
}

// "Record":"记录",
//   "RechargeRecord": "充值记录",
//   "WDRecord": "提币记录",
//   "all":"全部",
//   "WithdrawalRecharge":"已发起提现/充值申请",
//   "CalledAddress":"已回调,等待转出到目标地址",
//   "transferCommit":"转出提交",
//   "transferOutCompleted":"转出已回调(流程完结)",
//   "reviewTokens":"已扣除代币,等待审核",
//   "refundedRefunded":"已退款(提现审核不过,需要退款)"
export function getStatusText(statusCode: number) {
	switch (statusCode) {
		case 1:
			return "WithdrawalRecharge";
		case 2:
			return "CalledAddress";
		case 3:
			return "transferCommit";
		case 4:
			return "transferOutCompleted";
		case 0:
			return "reviewTokens";
		case -1:
			return "refundedRefunded";
		default:
			return "-";
	}
}

export function getInfoFromUserAgent(keyword) {
	var match = navigator.userAgent.match(new RegExp(keyword + "\\/(\\S+)"));
	return match ? match[1] : null;
}

// 是否是IOS
export function isIos() {
	if (/ios|iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())) {
		return true;
	}
	return (
		navigator.maxTouchPoints &&
		navigator.maxTouchPoints > 2 &&
		/MacIntel/.test(navigator.platform)
	);
}

// 是否是安卓
export function isAndroid() {
	return /android/.test(window.navigator.userAgent.toLowerCase());
}

// 判断路劲
export function isValidURL(url) {
	const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
	return urlPattern.test(url);
}

export function getBaseURL() {
	const currentProtocol = window.location.protocol;
	const currentHostname = window.location.hostname;
	const currentPort = window.location.port;
	const baseURL = `${currentProtocol}//${currentHostname}${
		currentPort ? `:${currentPort}` : ""
	}`;
	return baseURL;
}

export function getCoverUri(url, prefix = "wallet") {
	if (isValidURL(url)) {
		return url;
	} else if (url) {
		const baseURL = getBaseURL();
		return baseURL + "/" + prefix + url;
	} else {
		return require("@/images/7071.png");
	}
}

export function truncateString(str, maxLength) {
	let maxValue = 6;
	if (str.length <= maxLength) {
		return str;
	} else {
		const start = str.slice(0, maxValue);
		const end = str.slice(-maxValue);
		return `${start}***${end}`;
	}
}

export const handleBrowserUrl = (unit: string, transNo: string) => {
	if (isEmpty(transNo) || isEmpty(unit)) return "-";
	if (unit.startsWith("TRON")) {
		return "https://tronscan.org/#/transaction/" + transNo;
	}
	if (unit.startsWith("ETH")) {
		return "https://etherscan.io/tx/" + transNo;
	}
	return "-";
};

export function multiplyWithPrecision(num1, num2, precision) {
	const multiplier = Math.pow(10, precision);
	const result = (num1 * multiplier - num2 * multiplier) / multiplier;
	return round(result, 6);
}

export function getIOSStatusBarHeight() {
	const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
	if (isIOS) {
		const windowInnerHeight = window.innerHeight;
		const documentClientHeight = document.documentElement.clientHeight;
		return windowInnerHeight - documentClientHeight;
	}
	return 0;
}
