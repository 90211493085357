import { Button, Popup, Swiper, SwiperRef, Tabs } from "antd-mobile";
import { forwardRef, useMemo, useRef, useState } from "react";
import Recharge from "./comp/recharge";
import Ofcoins from "./comp/ofcoins";
import useRouter from "@/hooks/useRouter";
import CustomNavBar from "@/components/navBar";
import useCustomTranslation from "@/hooks/useTranslation";
import { FilterOutline } from "antd-mobile-icons";
import ParentApp from "@/components/parentApp";
import "../../style/tabs.scss";
import { useMetaMask } from "@onyx-p/metamask-react";
const StyleSheet = {
	content: {
		flex: 1,
		overflow: "hidden",
	},
};
const ForwardedChildRechargeComponent = forwardRef(Recharge);
const ForwardedChildOfcoinsComponent = forwardRef(Ofcoins);

const Record: React.FC = () => {
	const { t } = useCustomTranslation();
	const { location, params, goBack } = useRouter();
	const queryParams = new URLSearchParams(location.search);
	const paramType = queryParams.get("type");
	const id = params.id || "";
	const childRef = useRef<any>(null);
	const childRefOfcoins = useRef<any>(null);
	const callChildMethod = () => {
		if (childRef.current) {
			childRef.current.postBridgeItemsHandle(true, {});
		}
	};
	const callChildOfcoinsMethod = () => {
		if (childRefOfcoins.current) {
			childRefOfcoins.current.postBridgeItemsHandle(true, {});
		}
	};
	const { account } = useMetaMask();

	const tabItems = [
		{
			key: "1",
			title: t("RechargeRecord"),
		},
		{
			key: "2",
			title: t("WDRecord"),
		},
	];
	const searchData: any[] = [
		{
			value: -1000,
			name: t("all"),
		},
		{
			value: 1,
			name: t("WithdrawalRecharge"),
		},
		{
			value: 2,
			name: t("CalledAddress"),
		},
		{
			value: 3,
			name: t("transferCommit"),
		},
		{
			value: 4,
			name: t("transferOutCompleted"),
		},
		{
			value: 0,
			name: t("reviewTokens"),
		},
		{
			value: -1,
			name: t("refundedRefunded"),
		},
	];
	const fromAddress = useMemo(() => {
		return account || id;
	}, [account]);
	const swiperRef = useRef<SwiperRef>(null);
	const [activeIndex, setActiveIndex] = useState(paramType === "2" ? 1 : 0);

	const [visible, setVisible] = useState(false);
	const [statusTecharge, setStatusTecharge] = useState(null);
	const [statusOfcoins, setStatusOfcoins] = useState(null);
	const setStateHandle = (value, isRefresh = false) => {
		if (activeIndex) {
			setStatusOfcoins(value);
			setTimeout(() => {
				isRefresh && callChildOfcoinsMethod();
			}, 30);
		} else {
			setStatusTecharge(value);
			setTimeout(() => {
				isRefresh && callChildMethod();
			}, 30);
		}
		setTimeout(() => {
			setVisible(false);
		}, 200);
	};
	return (
		<ParentApp>
			<section className="section-main">
				<CustomNavBar
					title={t("Record")}
					onLeftClick={() => {
						goBack();
					}}
					rightContent={
						<FilterOutline
							onClick={() => {
								setVisible(true);
							}}
							fontSize={24}
							color="#2D374F"
						/>
					}
				/>
				<div
					className="exc-main"
					style={{
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						flex: 1,
						overflow: "hidden",
					}}
				>
					<div className="customHeader custom-exchange">
						<Tabs
							className="tabs"
							activeKey={tabItems[activeIndex].key}
							stretch={true}
							onChange={(key) => {
								const index = tabItems.findIndex(
									(item) => item.key === key
								);
								setActiveIndex(index);
								swiperRef.current?.swipeTo(index);
							}}
							activeLineMode="fixed"
							style={{
								"--active-title-color": "var(--primary)",
								"--active-line-color": "var(--primary)",
								"--title-font-size": "16px",
								"--active-line-height": "0",
								"--fixed-active-line-width": "20px",
								color: "var(--secondary)",
								fontWeight: "500",
								borderBottom: "none",
							}}
						>
							{tabItems.map((item) => (
								<Tabs.Tab title={item.title} key={item.key} />
							))}
						</Tabs>
					</div>
					{/* <div>{fromAddress}---</div> */}
					<div style={StyleSheet.content}>
						<Swiper
							direction="horizontal"
							loop
							indicator={() => null}
							ref={swiperRef}
							defaultIndex={activeIndex}
							onIndexChange={(index) => {
								setActiveIndex(index);
							}}
							style={{ height: "100%" }}
						>
							<Swiper.Item>
								<ForwardedChildRechargeComponent
									fromAddress={fromAddress}
									status={statusTecharge}
									activeIndex={activeIndex}
									ref={childRef}
								/>
							</Swiper.Item>
							<Swiper.Item>
								<ForwardedChildOfcoinsComponent
									fromAddress={fromAddress}
									status={statusOfcoins}
									activeIndex={activeIndex}
									ref={childRefOfcoins}
								/>
							</Swiper.Item>
						</Swiper>
					</div>
				</div>
			</section>

			<Popup
				visible={visible}
				onMaskClick={() => {
					setVisible(false);
				}}
				bodyClassName="popup-list bg-transparent"
			>
				<div
					style={{
						height: "58vh",
						display: "flex",
						flexDirection: "column",
						margin: "28px 20px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							flex: 1,
							overflow: "hidden",
							paddingBottom: "20px",
							backgroundColor: "white",
							borderRadius: "20px",
							marginBottom: "10px",
						}}
					>
						<div className="head-wrap">
							<span className="title">{t("filter")}</span>
							<span
								onClick={() => {
									setStateHandle(searchData[0].value, true);
								}}
								className="refresh"
							>
								{t("refresh")}
							</span>
						</div>
						<ul
							style={{ flex: 1, overflowY: "scroll" }}
							className="coins"
						>
							{searchData.map((item, index) => (
								<li
									onClick={() => setStateHandle(item.value)}
									key={index}
								>
									<strong>{item.name}</strong>
								</li>
							))}
						</ul>
					</div>
					<Button
						onClick={() => {
							setVisible(false);
						}}
						size="large"
						shape="rounded"
						style={{ color: "#F5626B" }}
					>
						{t("Cancel")}
					</Button>
				</div>
			</Popup>
		</ParentApp>
	);
};

export default Record;
