import React, { useEffect, useRef, useState } from "react";
import { Tabs, Swiper, Popup, Image, Toast } from "antd-mobile";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import EliteTitle from "./cmp/eliteTiele";
import EliteList from "./cmp/eliteList";
import CollectEdit from "./cmp/collectEdit";
import SwiperBlock from "./cmp/swiperBlock";
import { tabItemTpee, sortDataType, nameEnum, typeEnum } from "@/api/type";
import { AppDispatch, RootState } from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import MQTTStore from "@/hooks/useMqtt";
import { cloneDeep, assign, throttle, isEmpty } from "lodash";
import {
	fetchAppFavoriteList,
	fetchAppHotTickers,
	fetchAppMainTickers,
	setMarketData,
	setSortByData,
	setEditFavoriteSort,
	fetchAppFavorite,
	onAppUpdateTickers,
	receiveMqttMessage,
	setHotData,
} from "@/redux/reducers/hot";
import "./style/index.scss";
import "@/style/tabs.scss";
import useCustomTranslation from "@/hooks/useTranslation";
import eventBus from "@/utils/event";
import ParentApp from "@/components/parentApp";

const MEliteListComponent = React.memo(EliteList);
// 设计带油
const Market: React.FC = () => {
	const { t } = useCustomTranslation();
	const [tabItems, setTabItems] = useState<tabItemTpee[]>([
		{
			key: "collect",
			title: t("collect"),
			hasMore: true,
			page: 0,
			data: [],
		},
		{
			key: "hot",
			title: t("PopularList"),
			hasMore: true,
			page: 0,
			data: [],
		},
		{
			key: "main",
			title: t("MainCoins"),
			hasMore: true,
			page: 0,
			data: [],
		},
	]);
	const handleUpdateMain = (index, newMainValues) => {
		const updatedTabItems = [...tabItems];
		updatedTabItems[index] = {
			...updatedTabItems[index],
			...newMainValues,
		};
		setTabItems(updatedTabItems);
	};
	const swiperRef = useRef<SwiperRef>(null);
	const [activeIndex, setActiveIndex] = useState(1);
	const [visibleCloseRight, setVisibleCloseRight] = useState(false);
	const [favoriteMetadata, setFavoriteMetadata] = useState<any[]>([]);
	const [rawDataCollect, setRawDataCollect] = useState<string[]>([]);
	const [rawDataHot, setRawDataHot] = useState<string[]>([]);
	const [rawDataMain, setRawDataMain] = useState<string[]>([]);

	/**-------------------------MQTT分割--------------------------------**/
	const mqttStoreInstance = MQTTStore.getInstance();
	// const router = useRouter();
	// const queryParams = new URLSearchParams(router.location.search);
	// queryParams.get("userId") || "-";
	const userId = useSelector((state: RootState) => state.hot.userId);
	const pageSize = 30;
	const dispatch: AppDispatch = useDispatch();

	const hotData = useSelector((state: RootState) => state.hot.hotData);
	const collectList = useSelector(
		(state: RootState) => state.hot.collectList
	);
	const hotList = useSelector((state: RootState) => state.hot.hotList);
	const mainList = useSelector((state: RootState) => state.hot.mainList);
	const tickersTopics = useSelector(
		(state: RootState) => state.hot.tickersTopics
	);

	const getFetchAppFavoriteList = async (pageIndex: number) => {
		try {
			const params = {
				pageSize,
				page: pageIndex,
			};
			if (!tabItems[0].hasMore && pageIndex !== 0) return;
			const resultAction = await dispatch(fetchAppFavoriteList(params));
			const resultData = resultAction.payload as { data: any };
			const result = resultData.data;
			if (result.code === 0) {
				const contentData = result.content;
				const newData = contentData.data;
				if (pageIndex === 0) {
					dispatch(
						setMarketData({
							name: nameEnum.Collect,
							type: typeEnum.Clear,
							data: [],
						})
					);
					setRawDataCollect([]);
				}
				if (newData.length > 0) {
					dispatch(
						setMarketData({
							name: nameEnum.Collect,
							type: typeEnum.Add,
							data: newData,
						})
					);
					const units = newData.map((item: any) => item.unit);
					setRawDataCollect([...rawDataCollect, ...units]);
				}

				handleUpdateMain(0, {
					hasMore: newData.length >= pageSize,
					page: params.page + 1,
				});
			}
			// console.log("Async action completed with result:", result);
		} catch (error) {
			console.error("Async action failed:", error);
		}
	};
	const getFetchAppHotTickers = async (pageIndex: number) => {
		try {
			const params = {
				pageSize,
				page: pageIndex,
			};
			if (!tabItems[1].hasMore && pageIndex !== 0) return;
			const resultAction = await dispatch(fetchAppHotTickers(params));
			const resultData = resultAction.payload as { data: any };
			const result = resultData.data;
			if (result.code === 0) {
				const contentData = result.content;
				const newData = contentData.data;
				if (pageIndex === 0) {
					dispatch(
						setMarketData({
							name: nameEnum.Hot,
							type: typeEnum.Clear,
							data: [],
						})
					);
					setRawDataHot([]);
				}
				if (newData.length > 0) {
					if (!hotData.length) {
						dispatch(setHotData(cloneDeep(newData).splice(0, 3)));
					}
					dispatch(
						setMarketData({
							name: nameEnum.Hot,
							type: typeEnum.Add,
							data: newData,
						})
					);
					const units = newData.map((item: any) => item.unit);
					setRawDataHot([...rawDataHot, ...units]);
				}
				handleUpdateMain(1, {
					hasMore: newData.length >= pageSize,
					page: params.page + 1,
				});
			}
			// console.log("Async action completed with result:", resultData);
		} catch (error) {
			console.error("Async action failed:", error);
		}
	};
	const getFetchAppMainTickers = async (pageIndex: number) => {
		try {
			const params = {
				pageSize,
				page: pageIndex,
			};
			if (!tabItems[2].hasMore && pageIndex !== 0) return;
			const resultAction = await dispatch(fetchAppMainTickers(params));
			const resultData = resultAction.payload as { data: any };
			const result = resultData.data;
			if (result.code === 0) {
				const contentData = result.content;
				const newData = contentData.data;
				if (pageIndex === 0) {
					dispatch(
						setMarketData({
							name: nameEnum.Main,
							type: typeEnum.Clear,
							data: [],
						})
					);
					setRawDataMain([]);
				}
				if (newData.length > 0) {
					dispatch(
						setMarketData({
							name: nameEnum.Main,
							type: typeEnum.Add,
							data: newData,
						})
					);
					const units = newData.map((item: any) => item.unit);
					setRawDataMain([...rawDataMain, ...units]);
				}

				console.log(
					newData.length >= pageSize,
					"==newData.length >= pageSize"
				);
				handleUpdateMain(2, {
					hasMore: newData.length >= pageSize,
					page: params.page + 1,
				});
			}
			// console.log("Async action completed with result:", resultData);
		} catch (error) {
			console.error("Async action failed:", error);
		}
	};

	const interfaces = [
		getFetchAppFavoriteList,
		getFetchAppHotTickers,
		getFetchAppMainTickers,
	];
	const datas = [collectList, hotList, mainList];
	for (let index = 0; index < tabItems.length; index++) {
		const element = tabItems[index];
		element.onInterface = interfaces[index];
		element.data = datas[index];
	}

	const onChangeSort = (newValue: sortDataType) => {
		// 带油
		let tragetArray = [];
		if (activeIndex === 0) {
			tragetArray = rawDataCollect;
		} else if (activeIndex === 1) {
			tragetArray = rawDataHot;
		} else {
			tragetArray = rawDataMain;
		}
		dispatch(
			setSortByData({
				index: tabItems[activeIndex].key,
				type: newValue.type,
				sortOrder: newValue.sortOrder,
				tragetArray,
			})
		);
	};

	const onFavoriteMetadata = (data: any[]) => {
		dispatch(setEditFavoriteSort(data));
		setVisibleCloseRight(false);
	};
	const onEditCollect = () => {
		setFavoriteMetadata(cloneDeep(collectList));
		setVisibleCloseRight(true);
	};

	const onAppFavorite = async (item: any) => {
		const params: any = { refId: item.unit }; // fromId: userId
		if (item.favorite === 1) {
			params["remove"] = 1;
		}
		const resultAction = await dispatch(fetchAppFavorite(params));
		const resultData = resultAction.payload as { data: any };
		const result = resultData.data;
		if (result.code === 0) {
			dispatch(
				onAppUpdateTickers(
					assign({}, item, { favorite: item.favorite ? 0 : 1 })
				)
			);
			Toast.show({
				content: item.favorite
					? t("CancelFavorites")
					: t("CollectionSuccessful"),
			});
		} else {
			Toast.show({
				content: t("CollectionFailed"),
			});
		}
	};

	useEffect(() => {
		// let tickersTopics = [
		// 	"tickers.DOGE",
		// 	"tickers.BTCUSDT",
		// 	"tickers.ETHUSDT",
		// ];
		const updateTickersTargetData = throttle((message) => {
			dispatch(receiveMqttMessage(message));
		}, 300);
		console.log("-----订阅-----", tickersTopics);
		mqttStoreInstance
			.connect(userId)
			.then((isConnected) => {
				if (isConnected) {
					mqttStoreInstance.subscribe(tickersTopics);
					const handleReceivedData = (
						topic: string,
						message: any
					) => {
						// console.log(
						// 	`Received data on topic ${topic}:`,
						// 	message
						// );
						updateTickersTargetData(message.data);
					};
					mqttStoreInstance.addObserver(handleReceivedData);
				} else {
					console.error("MQTT连接错误");
				}
			})
			.catch((error) => {
				console.error("错误的MQTT信息:", error);
			});
		// return () => {
		// 	mqttStoreInstance && mqttStoreInstance.disconnect();
		// };
	}, [dispatch]);

	useEffect(() => {
		const callback = () => {
			const newItem = localStorage.getItem("newItem");
			if (!isEmpty(newItem)) {
				dispatch(onAppUpdateTickers(cloneDeep(JSON.parse(newItem))));
			}
		};
		window.addEventListener("storage", function (event) {
			if (event.key === "newItem") {
				callback();
			}
		});
		eventBus.addListener("viewAppeared", callback);
		return () => {
			eventBus.removeListener("viewAppeared", callback);
		};
	}, []);

	return (
		<ParentApp>
			<div className="elite-area">
				{/* <CustomNavBar title="行情" /> */}
				{/* <EliteWrap hotData={hotData} key="list-hot" /> */}
				<SwiperBlock />
				<section className="elite-list">
					<div className="tabs_wrap">
						<Tabs
							activeKey={tabItems[activeIndex].key}
							stretch={true}
							onChange={(key) => {
								const index = tabItems.findIndex(
									(item) => item.key === key
								);
								setActiveIndex(index);
								swiperRef.current?.swipeTo(index);
							}}
							activeLineMode="fixed"
							className="custom_tabs"
						>
							{tabItems.map((item) => (
								<Tabs.Tab title={item.title} key={item.key} />
							))}
						</Tabs>
						{activeIndex === 0 && (
							<Image
								src={require("@/images/edit.png")}
								width={20}
								height={20}
								fit="fill"
								className="moreIcon"
								onClick={onEditCollect}
							/>
						)}
					</div>
					<EliteTitle onChangeSort={onChangeSort} key="title" />
					<Swiper
						className="swiper-wrap"
						direction="horizontal"
						loop
						indicator={() => null}
						ref={swiperRef}
						defaultIndex={activeIndex}
						onIndexChange={(index) => {
							setActiveIndex(index);
						}}
					>
						{tabItems.map((item, index) => (
							<Swiper.Item key={item.key}>
								{index === activeIndex && (
									<MEliteListComponent
										key={index}
										jsonData={item}
										onAppFavorite={onAppFavorite}
									/>
								)}
							</Swiper.Item>
						))}
					</Swiper>
				</section>
				<Popup
					position="right"
					visible={visibleCloseRight}
					showCloseButton={false}
					onClose={() => {
						setVisibleCloseRight(false);
					}}
					style={{ width: "100%" }}
				>
					{visibleCloseRight && (
						<CollectEdit
							metadata={favoriteMetadata}
							onFavoriteMetadata={onFavoriteMetadata}
							onLeftClick={() => setVisibleCloseRight(false)}
						/>
					)}
				</Popup>
			</div>
		</ParentApp>
	);
};

export default Market;
