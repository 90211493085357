import {
	Button,
	Swiper,
	SwiperRef,
	Tabs,
	Image,
	Toast,
	Avatar,
} from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import BridgeIn from "./comp/bridgeIn";
import BridgeOut from "./comp/bridgeOut";
import "../../style/tabs.scss";
import "./style/index.scss";
import API from "@/api";
import useRouter from "@/hooks/useRouter";
import CustomNavBar from "@/components/navBar";
import { isEmpty } from "lodash";
import { truncateString } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import ParentApp from "@/components/parentApp";
import { useMetaMask } from "@onyx-p/metamask-react";
import useEthTransaction from "@/hooks/useEthTransaction";

const ExChange: React.FC = () => {
	const { t } = useCustomTranslation();
	const { customNavigate, goBack } = useRouter();
	const swiperRef = useRef<SwiperRef>(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [data, setData] = useState<any[]>([]);
	const [targetData, setTargetData] = useState<any>({});
	const { account, status, chainId } = useMetaMask();
	const { prepareNetwork, getContractBalance } = useEthTransaction();
	const [balance, setBalance] = useState(0);

	const tabItems = [
		{
			key: "in",
			title: t("BuyCurrency", {
				unit: targetData?.unit ?? "KD",
			}),
		},
		{
			key: "out",
			title: t("SellCurrency", {
				unit: targetData?.unit ?? "KD",
			}),
		},
	];

	const appBridgeChannelHandle = async () => {
		try {
			const params = {};
			const result = await API.wallets.getAppBridgeChannel(params);
			if (result.code === 0) {
				const contentData = result.content.data;
				if (result.content.hasOwnProperty("targetData")) {
					const targetData = result.content.targetData;
					setTargetData(targetData);
				}
				setData(contentData);
			}
		} catch (error) {
			Toast.show({
				content: t("TransferFailedLater"),
				position: "top",
			});
			console.log("---getAppBridgeChannel--:", error);
		}
	};

	useEffect(() => {
		appBridgeChannelHandle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getBalance();
		// 使用 setInterval 开启定时器
		const timerId = setInterval(() => {
			getBalance();
		}, 5000);
		// 在组件卸载或其他条件下清除定时器，以避免内存泄漏
		return () => clearInterval(timerId);
	}, [status, account, targetData, chainId]);

	useEffect(() => {
		setBalance(0);
	}, [account, status]);

	useEffect(() => {
		if (
			!isEmpty(targetData) &&
			status === "connected" &&
			chainId !== `0x${targetData.chainId.toString(16)}`
		) {
			prepareNetwork({
				rpcUrl: targetData.rpcUrl,
				name: targetData.name,
				chainId: targetData.chainId,
				unit: targetData.unit,
				decimal: targetData.decimal,
			});
		}
	}, [status, targetData]);

	const connect = () => {
		if (isEmpty(targetData)) {
			Toast.show({
				content: t("TransferFailedLater"),
				position: "top",
			});
			return;
		}
		prepareNetwork({
			rpcUrl: targetData.rpcUrl,
			name: targetData.name,
			chainId: targetData.chainId,
			unit: targetData.unit,
			decimal: targetData.decimal,
		});
	};

	const getBalance = async () => {
		if (
			isEmpty(targetData) ||
			status !== "connected" ||
			chainId !== `0x${targetData.chainId.toString(16)}`
		)
			return;
		const { address, contractDecimal } = targetData;
		getContractBalance({ contractAddress: address, contractDecimal }).then(
			(result) => {
				setBalance(result);
			}
		);
	};

	return (
		<ParentApp>
			<section className="section-main">
				<CustomNavBar
					title={t("CrossChainBridge")}
					onLeftClick={() => {
						goBack();
					}}
				/>
				<div
					className="container-exc"
					style={{ flex: 1, overflowY: "auto" }}
				>
					<div className="ex-top">
						<div className="logo">
							<Image
								className="img"
								src={require("../../images/icon/def_logo.png")}
								fit="fill"
								style={{ borderRadius: "100%" }}
							/>
							<div className="bridge">
								<h1>
									{t("SwapCurrency", {
										unit: targetData?.unit ?? "KD",
									})}
								</h1>
								{account && (
									<p>{truncateString(account, 10)}</p>
								)}
							</div>
						</div>
						{/* connectionStatus
						<Button
								style={{
									height: "40px",
									minWidth: "120px",
									marginLeft: "5px",
									color: "#4B6DE3",
								}}
								size="mini"
								shape="rounded"
								onClick={() => {
									Dialog.confirm({
										content: t("cleaAddressr"),
										confirmText: t("Confirm"),
										cancelText: t("Cancel"),
										onConfirm: () => {
											setConnectionStatus(false);
											dispatch(resetsConnnect());
										},
									});
								}}
							>
								{truncateString(fromAddress, 10)}
							</Button>
						*/}
						{status === "connected" ? (
							<Avatar
								onClick={() => {
									customNavigate(`/record/${account}`);
								}}
								src={require(`@/images/record.png`)}
								style={{
									"--size": "23px",
									marginTop: "13px",
									display: "inline-block",
								}}
							/>
						) : (
							<Button
								style={{
									height: "40px",
									minWidth: "120px",
									margin: "5px 0",
									color: "#4B6DE3",
									fontWeight: 600,
									fontSize: "16px",
								}}
								size="mini"
								shape="rounded"
								onClick={connect}
							>
								{t("Connect")}
							</Button>
						)}
					</div>
					<div
						className="exc-main"
						style={{
							top: "-40px",
							marginBottom: "-40px",
						}}
					>
						<div className="customHeader custom-exchange">
							<Tabs
								className="tabs"
								activeKey={tabItems[activeIndex].key}
								stretch={true}
								onChange={(key) => {
									const index = tabItems.findIndex(
										(item) => item.key === key
									);
									setActiveIndex(index);
									swiperRef.current?.swipeTo(index);
								}}
								activeLineMode="fixed"
								style={{
									"--active-title-color": "var(--primary)",
									"--active-line-color": "var(--primary)",
									"--title-font-size": "18px",
									"--active-line-height": "0",
									"--fixed-active-line-width": "20px",
									borderBottom: "none",
								}}
							>
								{tabItems.map((item) => (
									<Tabs.Tab
										title={item.title}
										key={item.key}
									/>
								))}
							</Tabs>
						</div>
						<Swiper
							direction="horizontal"
							loop
							indicator={() => null}
							ref={swiperRef}
							defaultIndex={activeIndex}
							onIndexChange={(index) => {
								setActiveIndex(index);
							}}
						>
							<Swiper.Item>
								<BridgeIn
									data={data.filter((item) => {
										return item.status === 1;
									})}
									targetData={targetData}
									connectWallet={connect}
									isWalletConnect={status === "connected"}
									fromAddress={account}
								/>
							</Swiper.Item>
							<Swiper.Item>
								<BridgeOut
									data={data.filter((item) => {
										return item.cashStatus === 1;
									})}
									targetData={targetData}
									connectWallet={connect}
									statusBool={status === "connected"}
									fromAddress={account}
									balance={balance}
								/>
							</Swiper.Item>
						</Swiper>
					</div>
				</div>
			</section>
		</ParentApp>
	);
};

export default ExChange;
