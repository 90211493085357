import { useEffect, useRef, useState } from "react";
import "./index.scss";
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	ResolutionString,
	IChartingLibraryWidget,
	EntityInfo,
} from "../../charting_library";
import * as React from "react";
import { ArrowsAltOutline, ShrinkOutline } from "antd-mobile-icons";
import { getInfoFromUserAgent } from "@/utils/Index";

interface RemainingParameters {
	symbol: ChartingLibraryWidgetOptions["symbol"];
	interval: ChartingLibraryWidgetOptions["interval"];
}

interface componentsProps extends RemainingParameters {
	mqtt: any;
	isFull?: boolean;
	width: number;
	height: number;
	tooglePortalVisible: Function;
}

export interface ChartContainerProps extends RemainingParameters {
	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions["library_path"];
	chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
	clientId: ChartingLibraryWidgetOptions["client_id"];
	userId: ChartingLibraryWidgetOptions["user_id"];
	fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
	autosize: ChartingLibraryWidgetOptions["autosize"];
	studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
	container: ChartingLibraryWidgetOptions["container"];
}

const getLanguageFromURL = (): LanguageCode | null => {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null
		? null
		: (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

const tabs = [
	{ id: 1, title: "MA", name: "Moving Average" },
	{ id: 2, title: "EMA", name: "Moving Average Exponential" },
	{ id: 3, title: "BOLL", name: "Bollinger Bands" },
	{ id: 4, title: "SAR", name: "Parabolic SAR" },
	{ id: 5, title: "VOL", name: "Volume" },
	{ id: 6, title: "MACD", name: "MACD" },
	{ id: 7, title: "KDJ", name: "KDJ" },
	{ id: 8, title: "RSI", name: "Moving Average Exponential" },
	{ id: 9, title: "WR", name: "Moving Average Exponential" },
	{ id: 10, title: "StochRSI", name: "Moving Average Exponential" },
];

let tvWidget: IChartingLibraryWidget;

const TVChartContainer: React.FC<componentsProps> = ({
	symbol,
	interval,
	mqtt,
	isFull,
	width,
	height,
	tooglePortalVisible,
}) => {
	const chartContainerRef =
		useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const isApp = getInfoFromUserAgent("UserId");
	const defaultProps: Omit<ChartContainerProps, "container"> = {
		symbol: "BTCUSDT",
		interval: "30" as ResolutionString,
		datafeedUrl: window.location.origin,
		libraryPath: "/charting_library/",
		chartsStorageUrl: "https://saveload.tradingview.com",
		chartsStorageApiVersion: "1.1",
		clientId: "tradingview.com",
		userId: "public_user_id",
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};
	Object.assign(defaultProps, {
		symbol,
		interval,
	});
	const [offsetAxisWidth, setOffsetAxisWidth] = useState(80);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		try {
			const widgetOptions: ChartingLibraryWidgetOptions = {
				symbol: defaultProps.symbol as string,
				// BEWARE: no trailing slash is expected in feed URL
				// tslint:disable-next-line:no-any
				datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
					defaultProps.datafeedUrl,
					mqtt
				),
				interval:
					defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
				container: chartContainerRef.current,
				library_path: defaultProps.libraryPath as string,
				locale: getLanguageFromURL() || "en",
				disabled_features: [
					"use_localstorage_for_settings",
					"widget_logo",
				],
				enabled_features: ["study_templates"],
				charts_storage_url: defaultProps.chartsStorageUrl,
				charts_storage_api_version:
					defaultProps.chartsStorageApiVersion,
				client_id: defaultProps.clientId,
				user_id: defaultProps.userId,
				fullscreen: defaultProps.fullscreen,
				autosize: defaultProps.autosize,
				studies_overrides: defaultProps.studiesOverrides,
				preset: "mobile", //手机模式
				theme:
					localStorage.getItem("theme") === "dark" ? "Dark" : "Light",
				// overrides: {
				// 	"paneProperties.background": "#222222",
				// 	"paneProperties.vertGridProperties.color": "#454545",
				// 	"paneProperties.horzGridProperties.color": "#454545",
				// 	"scalesProperties.textColor" : "#AAA"
				// }
			};

			tvWidget = new widget(widgetOptions);
			tvWidget.onChartReady(() => {
				tvWidget
					.activeChart()
					.getAllStudies()
					.forEach((study) => {
						tvWidget.activeChart().removeEntity(study.id);
					});
				axisStateHandle();
			});
		} catch (error) {
			console.log("====================================");
			console.log(error);
			console.log("====================================");
		}
		console.log(tvWidget, "===tvWidget");
		return () => {
			tvWidget.remove();
		};
	}, []);

	useEffect(() => {
		if (tvWidget) {
			// onChartReady 事件来确保图表准备就绪后再执行相关操作
			tvWidget.onChartReady(() => {
				const chart = tvWidget.chart();
				chart.setResolution(interval, () => {
					console.log(`重新设置时间（例如设置为:${interval}）`);
				});
			});
		}
	}, [interval]);

	const axisStateHandle = () => {
		try {
			setTimeout(() => {
				const iframeBody = document.querySelector("iframe");
				const axisScrollWidth =
					iframeBody.contentWindow.document.body.querySelector(
						".price-axis"
					).scrollWidth || 0;
				if (axisScrollWidth) {
					setOffsetAxisWidth(axisScrollWidth + 6);
				}
				setVisible(true);
			}, 1000);
		} catch (error) {
			console.log("----axisStateHandle----", error);
		}
	};

	const [selectedItems, setSelectedItems] = useState<
		{ id: number; title: string }[]
	>([]);

	//点击指标切换方法
	const handleItemClick = (item: any) => {
		const isItemInSelectedItems = isSelect(item);
		// 切换选中状态
		const updatedItems = isItemInSelectedItems
			? selectedItems.filter((selectedItem) => selectedItem !== item)
			: [...selectedItems, item];

		setSelectedItems(updatedItems);
		toggleStudyVisibility(item.name);
	};

	const isSelect = (item: any) => {
		return selectedItems.some(
			(selectedItem) => selectedItem.id === item.id
		);
	};

	const toggleStudyVisibility = (studyName) => {
		if (tvWidget) {
			const study: EntityInfo = tvWidget
				.activeChart()
				.getAllStudies()
				.find((s) => s.name === studyName);
			console.log("===", tvWidget.activeChart().getAllStudies());
			if (study) {
				tvWidget.activeChart().removeEntity(study.id);
			} else {
				switch (studyName) {
					case "Moving Average":
					case "Moving Average Exponential":
						tvWidget
							.activeChart()
							.createStudy(studyName, false, false, {
								length: 9,
								offset: 0,
								smoothingLength: 9,
								smoothingLine: "SMA",
								source: "close",
								symbol: "",
							});
						break;
					case "Bollinger Bands":
						tvWidget
							.activeChart()
							.createStudy(studyName, false, false, [20, 2]);
						break;
					case "Parabolic SAR":
						tvWidget
							.activeChart()
							.createStudy(
								studyName,
								false,
								false,
								[0.02, 0.2, 0.2]
							);
						break;
					case "Volume":
						tvWidget
							.activeChart()
							.createStudy(studyName, false, false);
						break;
					case "MACD":
						tvWidget
							.activeChart()
							.createStudy(studyName, false, false, [
								14,
								26,
								"close",
								9,
							]);
						break;
					case "KDJ":
						// 添加第一个 Stochastic 指标（%K 线）
						tvWidget
							.activeChart()
							.createStudy(
								"Stochastic",
								false,
								false,
								[14, 3, 3],
								{
									"plot.color.0": "blue",
								}
							);

						// 添加第二个 Stochastic 指标（%D 线）
						tvWidget
							.activeChart()
							.createStudy(
								"Stochastic",
								false,
								false,
								[14, 3, 3],
								{
									"plot.color.0": "red",
								}
							);
						// tvWidget.activeChart().createStudy(
						// 	'Stochastic',  false, false, [14, 3, 3], {
						// 		'plot.color.0': 'blue',
						// 		'plot.color.1': 'red',
						// 		'plot.color.2': 'green',
						// 	  })
						break;
				}
			}
		}
	};

	return (
		<div
			style={{
				position: "relative",
				display: "flex",
				flexDirection: "column",
				transform: isFull && !isApp ? "rotate(90deg)" : "",
			}}
		>
			<div ref={chartContainerRef} style={{ width, height }} />
			<div
				className="horizontal-scroll-list hide-scrollbar"
				style={{ width }}
			>
				{tabs.map((item, index) => (
					<div
						key={index}
						className={`list-item ${
							isSelect(item) ? "list-item-select" : ""
						}`}
						onClick={() => handleItemClick(item)}
					>
						{item.title}
					</div>
				))}
			</div>
			{visible && (
				<div
					onClick={() => tooglePortalVisible()}
					style={{
						position: "absolute",
						bottom: "74px",
						right: offsetAxisWidth + "px",
						border: "1px solid #333",
					}}
				>
					{isFull ? (
						<ShrinkOutline fontSize={24} />
					) : (
						<ArrowsAltOutline fontSize={24} />
					)}
				</div>
			)}
		</div>
	);
};

export default TVChartContainer;
