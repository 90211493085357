import { Swiper, SwiperRef, Tabs } from "antd-mobile";
import React, { forwardRef, useRef, useState } from "react";
import MyDApp from "./comp/myDApp";
import RecDApp from "./comp/recDApp";
import CustomDApp from "./comp/customDApp";
import "./style/index.scss";
import useCustomTranslation from "@/hooks/useTranslation";
import ParentApp from "@/components/parentApp";

const MyDAppComponent = forwardRef(MyDApp);
const RecDAppComponent = forwardRef(RecDApp);
const CustomDAppComponent = forwardRef(CustomDApp);
const Application: React.FC = () => {
	const { t } = useCustomTranslation();
	const myDAppRef = useRef<any>(null);
	const recDAppRef = useRef<any>(null);
	const customDAppRef = useRef<any>(null);
	// type 1/我的DAPP 2/推荐 3/自定义
	const tabItems = [
		{
			key: "1",
			title: t("mine"),
			comp: (
				<MyDAppComponent
					key={"1"}
					ref={myDAppRef}
					dappStateChanged={(id, added) => {
						recDAppRef.current?.dappStateChanged(id, added);
						customDAppRef.current?.dappStateChanged(id, added);
					}}
				/>
			),
		},
		{
			key: "2",
			title: t("Recommend"),
			comp: (
				<RecDAppComponent
					ref={recDAppRef}
					key={"2"}
					dappStateChanged={(id, added) => {
						myDAppRef.current?.refresh();
					}}
				/>
			),
		},
		{
			key: "3",
			title: t("customize"),
			comp: (
				<CustomDAppComponent
					ref={customDAppRef}
					key={"3"}
					dappStateChanged={() => {
						myDAppRef.current?.refresh();
					}}
				/>
			),
		},
	];
	const swiperRef = useRef<SwiperRef>(null);
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<ParentApp>
			<section
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Tabs
					activeKey={tabItems[activeIndex].key}
					stretch={true}
					onChange={(key) => {
						const index = tabItems.findIndex(
							(item) => item.key === key
						);
						setActiveIndex(index);
						swiperRef.current?.swipeTo(index);
					}}
					activeLineMode="fixed"
					className="custom_tabs"
				>
					{tabItems.map((item) => (
						<Tabs.Tab
							title={item.title}
							key={item.key}
							className="secondary-tab"
						/>
					))}
				</Tabs>
				<Swiper
					direction="horizontal"
					loop={false}
					indicator={() => null}
					ref={swiperRef}
					defaultIndex={activeIndex}
					onIndexChange={(index) => {
						setActiveIndex(index);
						if (index === 0) {
							myDAppRef.current?.componentAppear();
						}
					}}
					style={{ flex: 1, overflow: "hidden" }}
				>
					{tabItems.map((item) => (
						<Swiper.Item key={item.key}>{item.comp}</Swiper.Item>
					))}
				</Swiper>
			</section>
		</ParentApp>
	);
};

export default Application;
