import { getLogo } from "@/utils/Index";
import { Image } from "antd-mobile";
import { isEmpty } from "lodash";
import React from "react";

const CustomIconLogo: React.FC<{
	unit: string;
	w: any;
	h: any;
}> = ({ unit, w = 24, h = 24 }) => {
	const logo = getLogo(unit.replace("_USDT", ""));
	if (isEmpty(logo)) {
		return (
			<h1
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: w,
					height: h,
					borderRadius: "100%",
					backgroundColor: "#F2F3F5",
					border: "1px solid #767E8B",
				}}
			>
				<span>{unit.substring(0, 1)}</span>
			</h1>
		);
	}
	return (
		// <Avatar
		// 	src={require(`@/images/icon/${getLogo(
		// 		item.unit
		// 	)}.png`)}
		// 	style={{
		// 		"--size":
		// 			"30px",
		// 		"--border-radius":
		// 			"100%",
		// 	}}
		// />
		<Image
			src={require(`@/images/icon/${logo}.png`)}
			width={w}
			height={h}
			fit="fill"
			style={{ borderRadius: "100%" }}
		/>
	);
};

export default CustomIconLogo;
