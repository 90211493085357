import CustomNavBar from "@/components/navBar";
import { Avatar, Card, Grid, SafeArea, Toast } from "antd-mobile";
import "./style/index.scss";
import { useEffect, useState } from "react";
import TVChartContainer from "@/components/TVChartContainer";
import { ResolutionString } from "@/charting_library/charting_library";
import MQTTStore from "@/hooks/useMqtt";
import useRouter from "@/hooks/useRouter";
import {
	addThousandsSeparator,
	isIos,
	roundToDecimalPlaces,
} from "@/utils/Index";
import { assign, isEmpty, throttle } from "lodash";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import {
	fetchAppFavorite,
	onAppUpdateTickers,
	setTradeData,
} from "@/redux/reducers/hot";
import API from "@/api";
import createBridge from "@/utils/WebViewJavascriptBridge";
import useCustomTranslation from "@/hooks/useTranslation";
import ParentApp from "@/components/parentApp";

const StyleSheet = {
	content: {
		flex: 1,
		paddingTop: "16px",
		paddingBottom: "20px",
		backgroundColor: "var(--background)",
		borderTopLeftRadius: "20px",
		borderTopRightRadius: "20px",
	},
};
const timeData = [
	{
		name: "1H",
		value: "60",
	},
	{
		name: "4H",
		value: "240",
	},
	{
		name: "24H",
		value: "D",
	},
	{
		name: "1W",
		value: "W",
	},
	{
		name: "1M",
		value: "M",
	},
];
const PortalComponent = ({ children, container }) => {
	const [target] = useState(() => document.createElement("div"));

	useEffect(() => {
		container.appendChild(target);
		return () => {
			container.removeChild(target);
		};
	}, [container, target]);

	return ReactDOM.createPortal(children, target);
};

const Trade: React.FC = () => {
	const { t } = useCustomTranslation();
	const dispatch: AppDispatch = useDispatch();
	const { params, goBack } = useRouter();
	const symbol = params.symbol || "BTCUSDT";
	const mqttStoreInstance = MQTTStore.getInstance();
	const [interval, setInterval] = useState<any>("60");
	const [tradeList, setTradeList] = useState<any>({});
	const [isPortalVisible, setIsPortalVisible] = useState(false);
	const tradeData = useSelector((state: RootState) => state.hot.tradeData);
	const userId = useSelector((state: RootState) => state.hot.userId);

	const appTickersHandle = async () => {
		try {
			const params = {
				page: 0,
				pageSize: 30,
				fromId: userId,
				symbol,
			};
			const result = await API.cms.getAppTickers(params);
			if (result.code === 0) {
				if (result.content.hasOwnProperty("targetData")) {
					setTradeList(result.content.targetData);
				}
			}
		} catch (error) {
			console.log("---getAppTickers--:", error);
		}
	};

	useEffect(() => {
		let tickersTopics = [`tickers.${symbol}`];
		console.log("-----订阅-----", tickersTopics);
		const updateTradeList = throttle((message) => {
			setTradeList((prevTradeList) => ({
				...prevTradeList,
				...message.data,
			}));
		}, 300);
		appTickersHandle();
		mqttStoreInstance
			.connect(symbol)
			.then((isConnected) => {
				if (isConnected) {
					mqttStoreInstance.subscribe(tickersTopics);
					const handleReceivedData = (
						topic: string,
						message: any
					) => {
						// console.log(
						// 	`Received data on topic ${topic}:`,
						// 	message
						// );
						if (topic.includes(symbol)) {
							updateTradeList(message);
						}
					};
					mqttStoreInstance.addObserver(handleReceivedData);
				} else {
					console.error("MQTT连接错误");
				}
			})
			.catch((error) => {
				console.error("错误的MQTT信息:", error);
			});
		// return () => {
		// 	mqttStoreInstance && mqttStoreInstance.disconnect();
		// };
	}, []);

	const getmarkPrice = () => {
		// 最新市場成交價 | 指數價格 | 標記價格
		let price =
			tradeList.lastPrice ||
			tradeList.indexPrice ||
			tradeList.markPrice ||
			"-";
		return price;
	};

	const [windowDimensions, setWindowDimensions] = useState({
		width: window.screen.width,
		height: window.screen.height,
	});
	const handleResize = () => {
		setWindowDimensions({
			width: window.screen.width,
			height: window.screen.height,
		});
	};
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const onAppFavorite = async () => {
		if (isEmpty(tradeData) || tradeData.symbol !== symbol) {
			return;
		}
		const item = tradeData;
		const params: any = { refId: item.unit }; // fromId: userId
		if (item.favorite === 1) {
			params["remove"] = 1;
		}
		if (userId.includes("-1")) {
			return;
		}
		const resultAction = await dispatch(fetchAppFavorite(params));
		const resultData = resultAction.payload as { data: any };
		const result = resultData.data;
		if (result.code === 0) {
			const newItem = assign({}, item, {
				favorite: item.favorite ? 0 : 1,
			});
			dispatch(onAppUpdateTickers(newItem));
			dispatch(setTradeData(newItem));
			localStorage.setItem("newItem", JSON.stringify(newItem));

			Toast.show({
				content: item.favorite
					? t("CancelFavorites")
					: t("CollectionSuccessful"),
			});
		} else {
			Toast.show({
				content: t("CollectionFailed"),
			});
		}
	};
	return (
		<ParentApp>
			<section className="section-main">
				<CustomNavBar
					title={symbol}
					rightContent={
						<Avatar
							onClick={onAppFavorite}
							src={require(`@/images/${
								tradeData.favorite ? "favorite" : "collect"
							}.png`)}
							style={{
								"--size": "20px",
								display: "inline-block",
							}}
						/>
					}
					onLeftClick={() => {
						goBack();
					}}
				/>
				<div style={{ ...StyleSheet.content, overflowY: "auto" }}>
					<h1 className="money">${getmarkPrice()}</h1>
					<h4
						className={`rate ${
							tradeList.price24hPcnt >= 0 ? "long" : "short"
						}`}
					>
						{tradeList.price24hPcnt
							? roundToDecimalPlaces(
									+tradeList.price24hPcnt * 100,
									2
							  )
							: "0.00"}
						%
					</h4>
					<div className="time-area">
						<ul className="time">
							{timeData.map((item: any, index) => (
								<li
									className={`${
										item.value === interval && "active"
									}`}
									key={index}
									onClick={() => {
										setInterval(item.value);
									}}
								>
									{item?.name}
								</li>
							))}
						</ul>
					</div>
					<div className="chart">
						{!isPortalVisible && (
							<TVChartContainer
								mqtt={mqttStoreInstance}
								symbol={symbol}
								interval={interval as ResolutionString}
								width={windowDimensions.width}
								height={280}
								tooglePortalVisible={() => {
									createBridge((bridge) => {
										bridge.callHandler("setOrientation", 1);
									});
									setIsPortalVisible(!isPortalVisible);
								}}
							/>
						)}
					</div>
					<div className="caption">Price 7d</div>
					<div style={{ padding: "0 18px" }}>
						<Card
							style={{
								backgroundColor: "var(--backgroundSecondary)",
								borderRadius: "8px",
							}}
						>
							<Grid columns={4} gap={8}>
								<Grid.Item>
									<div className="c-normal">Open</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-heavier">
										{tradeList.prevPrice24h
											? addThousandsSeparator(
													tradeList.prevPrice24h
											  )
											: "-"}
									</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-normal">Close</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-heavier">
										{getmarkPrice()}
									</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-normal">High</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-heavier">
										{tradeList.highPrice24h
											? addThousandsSeparator(
													tradeList.highPrice24h
											  )
											: "-"}
									</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-normal">Low</div>
								</Grid.Item>
								<Grid.Item>
									<div className="c-heavier">
										{tradeList.lowPrice24h
											? addThousandsSeparator(
													tradeList.lowPrice24h
											  )
											: "-"}
									</div>
								</Grid.Item>
							</Grid>
						</Card>
					</div>
					<div className="caption">{t("MarketStatistic")}</div>
					<div style={{ padding: "0 18px" }}>
						<Card
							style={{
								backgroundColor: "var(--backgroundSecondary)",
								borderRadius: "8px",
							}}
						>
							<Grid columns={2} gap={10}>
								<Grid.Item>
									<div
										className="c-normal"
										style={{ textAlign: "left" }}
									>
										{t("MarketCapitalization")}
									</div>
								</Grid.Item>
								<Grid.Item>
									<div
										className="c-heavier"
										style={{ textAlign: "right" }}
									>
										{tradeList.markPrice
											? addThousandsSeparator(
													tradeList.markPrice
											  )
											: "-"}
									</div>
								</Grid.Item>
								<Grid.Item>
									<div
										className="c-normal"
										style={{ textAlign: "left" }}
									>
										{t("CirculatingSuply")}
									</div>
								</Grid.Item>
								<Grid.Item>
									<div
										className="c-heavier"
										style={{ textAlign: "right" }}
									>
										{tradeList.turnover24h
											? "$" +
											  addThousandsSeparator(
													tradeList.turnover24h
											  )
											: "-"}
									</div>
								</Grid.Item>
							</Grid>
						</Card>
					</div>
				</div>
			</section>
			<div style={{ background: "#E8F0F7" }}>
				<SafeArea position="bottom" />
			</div>

			{isPortalVisible && (
				<PortalComponent container={document.body}>
					<div
						style={{
							position: "fixed",
							top: "0",
							left: "0",
							bottom: "0",
							right: "0",
							background: "white",
							boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
						}}
					>
						<TVChartContainer
							mqtt={mqttStoreInstance}
							symbol={symbol}
							interval={interval as ResolutionString}
							width={
								isIos()
									? windowDimensions.height
									: windowDimensions.width - 40
							}
							height={
								isIos()
									? windowDimensions.width - 36
									: windowDimensions.height
							}
							isFull={isPortalVisible}
							tooglePortalVisible={() => {
								createBridge((bridge) => {
									bridge.callHandler("setOrientation", 0);
								});
								setIsPortalVisible(!isPortalVisible);
							}}
						/>
					</div>
				</PortalComponent>
			)}
		</ParentApp>
	);
};

export default Trade;
