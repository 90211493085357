// CustomNavBar.tsx
import React, { ReactNode } from "react";
import { Avatar, NavBar } from "antd-mobile";
import useRouter from "@/hooks/useRouter";

interface CustomNavBarProps {
	title: ReactNode | string; // 修改 title 的类型为 ReactNode，以支持传入动态内容
	onLeftClick?: () => void;
	rightContent?: ReactNode;
}

const CustomNavBar: React.FC<CustomNavBarProps> = ({
	title,
	onLeftClick,
	rightContent,
}) => {
	const { goBack } = useRouter();
	const defaultLeftClick = () => {
		goBack();
		console.log("Default left click");
	};

	return (
		<NavBar
			// backArrow={
			// 	<Avatar
			// 		src={require(`@/images/back.png`)}
			// 		style={{ "--size": "24px" }}
			// 	/>
			// }
			onBack={onLeftClick || defaultLeftClick}
			right={rightContent}
			style={{
				color: "var(--black)",
				fontWeight: "600",
				fontSize: "18px",
			}}
		>
			{title}
		</NavBar>
	);
};

export default CustomNavBar;

/**
 * <div>
      <CustomNavBar
        title="My Page" // 静态标题
        onLeftClick={handleLeftClick}
        rightContent={<i className="iconfont icon-more" />} // 右侧自定义内容
      />

      <CustomNavBar
        title={<span>{'Dynamic Title'}</span>} // 动态标题
        onLeftClick={handleLeftClick}
        rightContent={<i className="iconfont icon-more" />} // 右侧自定义内容
      />
    </div>
 * 
*/
