import ReactDOM from "react-dom/client";
import "lib-flexible";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import "./locales/index";
import { MetaMaskProvider } from "@onyx-p/metamask-react";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
		 <MetaMaskProvider>
			<App />
		 </MetaMaskProvider>
		</PersistGate>
	</Provider>
);

reportWebVitals();
