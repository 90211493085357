import React, { useEffect, useState } from "react";
import "../style/swiperBlock.scss";
import { Skeleton, Swiper, Image } from "antd-mobile";
import { getCoverUri, roundToDecimalPlaces } from "@/utils/Index";
import { setTradeData } from "@/redux/reducers/hot";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/redux/store";
import useRouter from "@/hooks/useRouter";
import API from "@/api";
const SwiperBlock: React.FC = () => {
	// const dispatch: AppDispatch = useDispatch();
	const { customNavigate, toExternalLink } = useRouter();
	const [data, setData] = useState<any>({});
	const getAppBannerHandle = async () => {
		try {
			const result = await API.wallets.getAppBanner({ type: 1 });
			if (result.code === 0) {
				const contentData = result.content.data;
				setData(contentData);
			}
		} catch (error) {
			console.log("---getAppBanner--:", error);
		}
	};
	useEffect(() => {
		getAppBannerHandle();
	}, []);

	// 	@Schema(title = "1/外部链接 2/跳原生 3/跳详情页")
	// public String jumpType;
	const jumpTypeOpen = (itemData: any) => {
		const type = itemData.jumpType + "";
		const url = itemData.url;
		const hidden = itemData.header === 0 ? true : false;
		switch (type) {
			case "1":
			case "2":
				if (url.startsWith("http")) {
					toExternalLink(url, hidden);
				} else {
					customNavigate(url);
				}
				break;
			case "3":
				customNavigate(`/new/${itemData.id}`);
				break;
		}
	};

	return (
		<div className="swiperBlock-wrap">
			<Swiper
				slideSize={82}
				trackOffset={9}
				stuckAtBoundary={false}
				defaultIndex={2}
				loop
				indicator={(total, current) => (
					<ul className="indicator-wrap">
						{Array.from({ length: total }).map((_, index) => {
							return (
								<li
									className={`${
										current === index && "active"
									}`}
								></li>
							);
						})}
					</ul>
				)}
				style={{ "--height": "132px", paddingBottom: "12px" }}
			>
				{data.length ? (
					data.map((item, index) => (
						<Swiper.Item key={index}>
							<div
								onClick={() => jumpTypeOpen(item)}
								style={{
									height: "120px",
									padding: "0 5px",
								}}
							>
								<Image
									lazy
									fit="fill"
									src={getCoverUri(item.cover)}
									style={{
										height: "100%",
										borderRadius: "10px",
										overflow: "hidden",
									}}
								/>
							</div>
						</Swiper.Item>
					))
				) : (
					<Skeleton
						animated
						style={{ height: "120px", borderRadius: "10px" }}
					/>
				)}
			</Swiper>
		</div>
	);
};

export default SwiperBlock;
