import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Popup,
	Skeleton,
	Space,
	Toast,
	Image,
	Avatar,
} from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import "../style/index.scss";
import QRCode from "react-qr-code";
import API from "@/api";
import { isEmpty } from "lodash";
import { getCoverUri, setDataToClipboard } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";

const BridgeIn: React.FC<{
	data: any[];
	targetData: Record<string, any>;
	isWalletConnect: boolean;
	fromAddress?: string;
	connectWallet: () => void;
}> = ({ data, targetData, connectWallet, isWalletConnect, fromAddress }) => {
	const { t } = useCustomTranslation();
	const [currencyData, setCurrencyData] = useState<any>({});
	const [addressData, setAddressData] = useState<any>({});
	const [qrCodeValue, setQrCodeValue] = useState("");
	const [visible, setVisible] = useState(false);
	const togglesVisible = () => {
		setVisible(!visible);
	};
	const bridgeConversionInHandle = async (params: {
		code: any;
		userAddress: any;
	}) => {
		const toastObject = Toast.show({
			icon: "loading",
			content: t("refreshingText"),
			duration: 0,
		});
		try {
			// userAddress/用户的唯一标识 code/渠道编码 下发收款相关
			const result = await API.wallets.postBridgeConversionIn(params);
			if (result.code === 0) {
				const contentData = result.content.data;
				setQrCodeValue(contentData);
			}
		} catch (error) {
			console.log("---postBridgeConversionIn--:", error);
		} finally {
			toastObject.close();
		}
	};

	useEffect(() => {
		if (data.length) setCurrencyData(data[0]);
	}, [data]);
	useEffect(() => {
		if (!isEmpty(targetData)) setAddressData(targetData);
	}, [targetData]);

	// useEffect(() => {}, [currencyData, addressData, isWalletConnect]);
	const selectWalletAddress = (itemData) => {
		if (itemData.code !== currencyData.code) {
			setCurrencyData(itemData);
		}
		togglesVisible();
		if (isWalletConnect && !isEmpty(qrCodeValue) && !isEmpty(addressData)) {
			bridgeConversionInHandle({
				code: itemData.code,
				userAddress: fromAddress,
			});
		}
	};

	const codeGenerateQR = () => {
		if (!isWalletConnect) {
			// return Toast.show({
			// 	content: t("SignTypedDataFailed"),
			// 	position: "top",
			// });
			return connectWallet();
		}
		if (!isEmpty(currencyData) && !isEmpty(addressData)) {
			bridgeConversionInHandle({
				code: currencyData.code,
				userAddress: fromAddress,
			});
		}
	};

	const splitStrIntoTwoLine = (text: string) => {
		const halfLength = Math.ceil(text.length / 2);
		const firstHalf = text.substring(0, halfLength);
		const secondHalf = text.substring(halfLength);
		return firstHalf + "\n" + secondHalf;
	};

	return (
		<div className="content">
			<Card className="care-wrap">
				<Card
					style={{
						backgroundColor: "#F9FBFD",
						marginBottom: "10px",
						borderRadius: "10px",
						border: "1px solid var(--border)",
					}}
				>
					<div className="stored-area">
						<div className="title">{t("Token")}</div>
						<div className="currency">
							{addressData.unit ? (
								<div
									style={{
										border: "1px solid rgba(0, 0, 0, .15)",
										borderRadius: "100%",
										overflow: "hidden",
									}}
								>
									<Avatar
										src={getCoverUri(targetData.logo)}
										style={{ "--size": "28px" }}
									/>
								</div>
							) : (
								<Skeleton
									animated
									style={{
										width: "28px",
										height: "28px",
										"--border-radius": "100%",
									}}
								/>
							)}
							<strong>{addressData.unit}</strong>
						</div>
					</div>
				</Card>
				<Card
					style={{
						backgroundColor: "#F9FBFD",
						marginBottom: "10px",
						borderRadius: "10px",
						border: "1px solid var(--border)",
					}}
					onClick={togglesVisible}
				>
					<div className="stored-area">
						<div className="title">{t("Network")}</div>
						<div className="currency">
							{currencyData.unit ? (
								<div
									style={{
										border: "1px solid rgba(0, 0, 0, .15)",
										borderRadius: "100%",
										overflow: "hidden",
									}}
								>
									<Avatar
										src={getCoverUri(currencyData.logo)}
										style={{ "--size": "28px" }}
									/>
								</div>
							) : (
								<Skeleton
									animated
									style={{
										width: "28px",
										height: "28px",
										"--border-radius": "100%",
									}}
								/>
							)}
							<strong>{currencyData.name}</strong>
							<RightOutline fontSize={14} color="#A8B4D2" />
						</div>
					</div>
				</Card>

				<Space
					justify="between"
					style={{
						fontSize: "14px",
						width: "100%",
						padding: "0 3px",
					}}
				>
					<span style={{ color: "#7D858A" }}>
						{t("EstimatedRate")}：
					</span>
					{currencyData.rate ? (
						<span
							style={{ color: "#2585BB" }}
						>{`1 ${currencyData.unit} ≈ ${currencyData.rate} ${addressData.unit}`}</span>
					) : (
						<Skeleton
							animated
							style={{
								width: "150px",
								height: "18px",
							}}
						/>
					)}
				</Space>

				{!isEmpty(qrCodeValue) && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							height: "auto",
							margin: "15px auto",
							width: "100%",
						}}
					>
						<div
							style={{
								maxWidth: 160,
							}}
						>
							<QRCode
								size={160}
								style={{
									height: "auto",
									maxWidth: "100%",
									width: "100%",
								}}
								value={qrCodeValue}
								viewBox={`0 0 256 256`}
							/>
						</div>
						<div
							style={{
								maxWidth: "202px",
								fontSize: "14px",
								fontWeight: 500,
								lineHeight: "20px",
								textAlign: "center",
								marginTop: "10px",
								color: "#4B6DE3",
								whiteSpace: "pre-line",
								position: "relative",
							}}
						>
							{splitStrIntoTwoLine(qrCodeValue)}
							<Image
								src={require("@/images/copy.png")}
								width="35px"
								height="35px"
								style={{
									position: "absolute",
									right: "-47px",
									top: "50%",
									marginTop: "-17px",
								}}
								onClick={() => {
									Toast.show({
										content: t("copiedSucc"),
									});
									setDataToClipboard(qrCodeValue);
								}}
							/>
						</div>
					</div>
				)}

				<div className="tag-addr">
					{t("TRCC20Addreess", {
						description: currencyData.description,
						symbol: currencyData.symbol,
					})}
				</div>
				{isEmpty(qrCodeValue) && (
					<Button
						block
						shape="rounded"
						onClick={codeGenerateQR}
						size="large"
						style={{
							"--background-color": "#4B6DE3",
							"--text-color": "white",
						}}
					>
						{t("GetQrCode")}
					</Button>
				)}

				{/* <p
					onClick={() => {
						Toast.show({
							content: t("copiedSucc"),
						});
						setDataToClipboard(qrCodeValue);
					}}
					style={{
						fontSize: "16px",
						lineHeight: "24px",
						textAlign: "center",
						color: "blue",
					}}
				>
					{qrCodeValue}
				</p> */}
			</Card>
			<Card>
				<dl className="tips">
					<dt>{t("Tips")}</dt>
					{[0, 1, 2, 3, 4].map((item, index) => (
						<dd key={item}>
							{index + 1}.{t("warnLoss" + index)}
						</dd>
					))}
				</dl>
			</Card>

			<Popup
				visible={visible}
				onMaskClick={() => {
					setVisible(false);
				}}
				bodyClassName="popup-list"
			>
				<div
					style={{
						height: "40vh",
						overflowY: "scroll",
						padding: "20px 15px",
					}}
				>
					<ul className="coins">
						{data.map((item, index) => (
							<li
								onClick={() => {
									selectWalletAddress(item);
								}}
								key={index}
							>
								<Avatar
									src={getCoverUri(item.logo)}
									style={{ "--size": "28px" }}
								/>
								<strong>{item.unit}</strong>
							</li>
						))}
					</ul>
				</div>
			</Popup>
		</div>
	);
};

export default BridgeIn;
