import routes from "./router"; // 导入路由配置文件 HashRouter | BrowserRouter
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./style/transition.scss";
import { AliveScope, KeepAlive } from "react-activation";
import { getInfoFromUserAgent } from "./utils/Index";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, clearLocalstorage } from "./redux/store";
import { useEffect } from "react";
import { setUserId } from "./redux/reducers/hot";
import { getStorage, setStorage } from "./utils/Storage";
import { random } from "lodash";

function App() {
	const dispatch: AppDispatch = useDispatch();

	useEffect(() => {
		const newUserId = getInfoFromUserAgent("UserId");

		if (newUserId?.length) {
			dispatch(setUserId(newUserId));
		} else {
			_getDefaultUserId().then((defaultUserId) => {
				dispatch(setUserId(defaultUserId));
			});
		}
	}, []);

	const _InitPage = async () => {
		const currentVersion = "1.0.2";
		const storedVersion = await getStorage("appVersion");
		if (!storedVersion || storedVersion !== currentVersion) {
			clearLocalstorage();
			setStorage("appVersion", currentVersion);
		}
	};

	const _getDefaultUserId = async () => {
		let userId: string = await getStorage("defaultUserId");
		if (!userId?.length) {
			userId = random(-10000, -2) + "";
			await setStorage("defaultUserId", userId);
		}
		return userId;
	};

	useEffect(() => {
		_InitPage();
	}, []);

	return (
		<Router>
			<AliveScope>
				<TransitionGroup>
					<Routes>
						{routes.map((route, index) => (
							<Route
								key={index}
								path={route.path}
								element={
									<CSSTransition
										in={true}
										timeout={300}
										classNames="my-route"
										unmountOnExit
									>
										<KeepAlive
											key={index}
											when={route.keepAlive}
											id={route.path}
										>
											<route.component />
										</KeepAlive>
									</CSSTransition>
								}
							/>
						))}
					</Routes>
				</TransitionGroup>
			</AliveScope>
		</Router>
	);
}

export default App;
