import useRouter from "@/hooks/useRouter";
import React, { useEffect, useState } from "react";
import "./style/index.scss";
import API from "@/api";
import TextComp from "./comp/text";
import ImagesComp from "./comp/images";
import { isEmpty } from "lodash";
import { Skeleton } from "antd-mobile";
import ParentApp from "@/components/parentApp";

const Market: React.FC = () => {
	const { params, goBack } = useRouter();
	const id = params.id || "1";
	const [data, setData] = useState<any>({});
	const getAppNewsDetailHandle = async () => {
		try {
			// getAppNewsDetail
			const result = await API.wallets.getAppBannerContent({
				id: id,
			});
			if (result.code === 0) {
				const contentData = result.content.data;
				setData(contentData);
			}
		} catch (error) {
			console.log("---getAppNewsDetail--:", error);
		}
	};
	useEffect(() => {
		getAppNewsDetailHandle();
	}, []);
	if (isEmpty(data)) {
		return (
			<ParentApp>
				<Skeleton.Title animated />
				<Skeleton.Paragraph lineCount={5} animated />
			</ParentApp>
		);
	} else if (data.cover) {
		return <ImagesComp data={data} />;
	} else {
		return (
			<ParentApp>
				<TextComp data={data} />
			</ParentApp>
		);
	}
};

export default Market;
