import React, { useState, useEffect } from "react";
import { Avatar, InfiniteScroll, PullToRefresh } from "antd-mobile";
import useRouter from "@/hooks/useRouter";
import { tabItemTpee } from "@/api/type";
import {
	formatVolume,
	roundToDecimalPlaces,
	getTickClassName,
} from "@/utils/Index";
import "../style/eliteList.scss";
import CustomEmpty from "@/components/empty";
import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/redux/store";
import { setTradeData } from "@/redux/reducers/hot";
import useCustomTranslation from "@/hooks/useTranslation";

const EliteList: React.FC<{
	jsonData: tabItemTpee;
	onAppFavorite: (item: any) => void;
}> = (props) => {
	const { t } = useCustomTranslation();
	const dispatch: AppDispatch = useDispatch();
	const { data, hasMore, page, onInterface } = props.jsonData;
	const [localPage, setLocalPage] = useState(page);
	const [localData, setLocalData] = useState(data);
	const [localHasMore, setLocalHasMore] = useState(hasMore);
	const onAppFavorite = props.onAppFavorite;
	const { customNavigate } = useRouter();
	const [refreshing, setRefreshing] = useState(false);
	const fetchData = async (pageIndex: number) => {
		if (onInterface) {
			await onInterface(pageIndex);
		}
	};

	useEffect(() => {
		setLocalPage(page);
	}, [page]);

	useEffect(() => {
		setLocalData(data);
	}, [data]);

	useEffect(() => {
		setLocalHasMore(hasMore);
	}, [hasMore]);

	useEffect(() => {
		fetchData(0);
	}, []);

	const handleRefresh = async () => {
		setRefreshing(true);
		await fetchData(0);
		setRefreshing(false);
	};

	const handleFetchMore = async () => {
		await fetchData(localPage);
	};

	return (
		<div className="list-body">
			<PullToRefresh
				onRefresh={handleRefresh}
				canReleaseText={t("canReleaseText")}
				completeText={t("completeText")}
				pullingText={t("pullingText")}
				refreshingText={t("refreshingText")}
			>
				{localData.length === 0 ? (
					<CustomEmpty />
				) : (
					<div>
						{localData.map((item, index) => {
							const price24hPcnt = roundToDecimalPlaces(
								+item.price24hPcnt * 100,
								2
							);
							const dynamicClassName = `rise-fall ${
								price24hPcnt > 0 ? "rise" : "fall"
							}`;
							return (
								<div
									onClick={() => {
										dispatch(setTradeData(item));
										setTimeout(() => {
											customNavigate(
												`/trade/${item.symbol}`
											);
										}, 10);
									}}
									className={`listItem-wrap ${getTickClassName(
										item.tickDirection
									)}`}
									key={item.unit}
								>
									<div className="coin">
										<Avatar
											src={item.logo}
											className="logo"
										/>
										<strong>{item.unit}</strong>
									</div>
									<div className="volume">
										<strong>
											{formatVolume(+item.volume24h)}
										</strong>
									</div>
									<div className="price">
										<strong>{item.lastPrice}</strong>
									</div>
									<div>
										<div className={dynamicClassName}>
											<span>{price24hPcnt}%</span>
										</div>
										<div className="collect">
											<div
												onClick={(e) => {
													e.stopPropagation();
													e.nativeEvent.stopImmediatePropagation();
													onAppFavorite(item);
												}}
												className={`${
													item.favorite
														? "collectOn"
														: "collectOff"
												}`}
											></div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
				{localData.length > 0 && (
					<InfiniteScroll
						loadMore={handleFetchMore}
						hasMore={localHasMore}
					>
						<InfiniteScrollContent hasMore={localHasMore} />
					</InfiniteScroll>
				)}
			</PullToRefresh>
		</div>
	);
};

export default EliteList;
