import CustomNavBar from "@/components/navBar";
import useRouter from "@/hooks/useRouter";
import React, { useEffect, useState } from "react";
import "./style/index.scss";
import API from "@/api";
import CustomEmpty from "@/components/empty";
import { InfiniteScroll, PullToRefresh, Toast } from "antd-mobile";
import InfiniteScrollContent from "@/components/InfiniteScrollContent";
import { setDataToClipboard } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import ParentApp from "@/components/parentApp";

const Market: React.FC = () => {
	const { t } = useCustomTranslation();
	const { params, goBack } = useRouter();
	const id = params.id || "";
	const [data, setData] = useState<any>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const pageSize = 30;
	const contractDeployItems = async (isRefresh = false) => {
		try {
			let pageNum = isRefresh ? 0 : page;
			const result = await API.wallets.postContractDeployItems({
				address: id,
				page: pageNum,
				pageSize: pageSize,
			});
			if (result.code === 0) {
				const contentData = result.content.data;
				const haveMore = result.content.haveMore;
				setHasMore(haveMore);
				if (haveMore) {
					if (isRefresh) {
						setPage(1);
					} else {
						setPage((prevPage) => prevPage + 1);
					}
				}
				if (pageNum === 0) {
					setData(contentData);
				} else {
					setData((prevData) => [...prevData, ...contentData]);
				}
			}
		} catch (error) {
			console.log("---postContractDeployItems--:", error);
		}
	};

	const handleRefresh = async () => {
		// 模拟刷新数据
		setHasMore(true);
		await contractDeployItems(true);
	};

	const handleFetchMore = async () => {
		// 模拟加载更多数据
		await contractDeployItems();
	};

	useEffect(() => {
		contractDeployItems();
	}, []);

	return (
		<ParentApp>
			<section className="section-main">
				<CustomNavBar
					title={t("coinRecords")}
					onLeftClick={() => {
						goBack();
					}}
				/>
				<article className="coinRecord-area">
					<PullToRefresh
						onRefresh={handleRefresh}
						canReleaseText={t("canReleaseText")}
						completeText={t("completeText")}
						pullingText={t("pullingText")}
						refreshingText={t("refreshingText")}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: "10px 15px",
							}}
						></div>
						{data.length === 0 ? (
							<CustomEmpty />
						) : (
							data.map((item, index) => {
								return (
									<ul key={index} className="coin-wrap">
										<li>
											<span className="l">
												{t("TokenName")}:
											</span>
											<span className="r">
												{item.name}
											</span>
										</li>
										<li>
											<span className="l">
												{t("TokenSymbol")}:
											</span>
											<span className="r">
												{item.symbol}
											</span>
										</li>
										<li>
											<span className="l">
												{t("InitialSupply")}:
											</span>
											<span className="r">
												{item.amount}
											</span>
										</li>
										<li>
											<span className="l">
												{t("Decimals")}:
											</span>
											<span className="r">
												{item.decimal}
											</span>
										</li>
										<li>
											<span className="l">
												{t("CreateTime")}:
											</span>
											<span className="r">
												{item.createTime}
											</span>
										</li>
										<li>
											<span className="l">
												{t("Address")}:
											</span>
											<span
												style={{
													color: "#4B6DE3",
													textAlign: "left",
												}}
												className="r"
												onClick={() => {
													Toast.show({
														content:
															t("copiedSucc"),
													});
													setDataToClipboard(
														item.contractAddress
													);
												}}
											>
												{item.contractAddress}
											</span>
											{/* <Button
													size="mini"
													color="primary"
													style={{ height: 26 }}
												>
													copy
												</Button> */}
										</li>
									</ul>
								);
							})
						)}
						{data.length > 0 && (
							<InfiniteScroll
								loadMore={handleFetchMore}
								hasMore={hasMore}
							>
								<InfiniteScrollContent hasMore={hasMore} />
							</InfiniteScroll>
						)}
					</PullToRefresh>
				</article>
			</section>
		</ParentApp>
	);
};

export default Market;
