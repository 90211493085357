interface WebViewJavascriptBridge {
	registerHandler(
		handlerName: string,
		handler: WebViewJavascriptBridgeHandler
	): void;
	callHandler(
		handlerName: string,
		data?: any,
		responseCallback?: WebViewJavascriptBridgeResponseCallback
	): void;
}

interface WebViewJavascriptBridgeHandler {
	(
		data: any,
		responseCallback: WebViewJavascriptBridgeResponseCallback
	): void;
}

interface WebViewJavascriptBridgeResponseCallback {
	(responseData: any): void;
}

function createBridge(
	callback: (bridge: WebViewJavascriptBridge) => void
): void {
	// 如果 WebViewJavascriptBridge 已经存在，直接返回
	if ((window as any).WebViewJavascriptBridge) {
		return callback((window as any).WebViewJavascriptBridge);
	}

	// 兼容 iOS
	if ((window as any).WVJBCallbacks) {
		return (window as any).WVJBCallbacks.push(callback);
	}

	// 如果在安卓 WebView 中
	if ((window as any).AndroidInterface) {
		callback((window as any).AndroidInterface);
	}

	// 注册初始化回调
	(window as any).WVJBCallbacks = [callback];

	// 加载 WebViewJavascriptBridge
	const WVJBIframe = document.createElement("iframe");
	WVJBIframe.style.display = "none";
	WVJBIframe.src = "wvjbscheme://__BRIDGE_LOADED__";
	document.documentElement.appendChild(WVJBIframe);
	setTimeout(() => {
		document.documentElement.removeChild(WVJBIframe);
	}, 0);
}

export default createBridge;
