import { useTranslation } from "react-i18next";

const useCustomTranslation = () => {
	const { t, i18n } = useTranslation();

	const changeLanguage = async (language) => {
		await i18n.changeLanguage(language);
	};

	return { t, i18n, changeLanguage };
};

export default useCustomTranslation;
