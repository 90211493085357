import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api/index";
import { cloneDeep, isEmpty, merge, union } from "lodash";
import { RootState } from "../store";
import { nameEnum, sortDataType, typeEnum, SortOrder } from "../../api/type";
import { sortByData } from "../../utils/Index";
interface marketType {
	name: nameEnum;
	type: typeEnum;
	data: any[];
}

interface hotType {
	userId: string;
	hotData: any[];
	collectList: any[];
	hotList: any[];
	mainList: any[];
	tickersTopics: string[];
	tradeData: any;
}

const initialState: hotType = {
	userId: "discover_-1",
	hotData: [],
	collectList: [],
	hotList: [],
	mainList: [],
	tickersTopics: [],
	tradeData: {},
};

export const fetchAppFavoriteList = createAsyncThunk(
	"hot/fetchAppFavoriteList",
	async (params: any, { getState }) => {
		const state = getState() as RootState;
		const userId = "discover_" + state.hot.userId;
		const parameter = merge({ fromId: userId }, params);
		const response = await API.cms.getAppFavoriteList(parameter);
		return { data: response };
	}
);

export const fetchAppHotTickers = createAsyncThunk(
	"hot/fetchAppHotTickers",
	async (params: any, { getState }) => {
		const state = getState() as RootState;
		const userId = "discover_" + state.hot.userId;
		const parameter = merge({ hit: 1, fromId: userId }, params);
		const response = await API.cms.getAppTickers(parameter);
		return { data: response };
	}
);

export const fetchAppMainTickers = createAsyncThunk(
	"hot/fetchAppMainTickers",
	async (params: any, { getState }) => {
		const state = getState() as RootState;
		const userId = "discover_" + state.hot.userId;
		const parameter = merge({ fromId: userId }, params);
		const response = await API.cms.getAppTickers(parameter);
		return { data: response };
	}
);

export const fetchAppFavorite = createAsyncThunk(
	"hot/fetchAppFavorite",
	async (params: any, { getState }) => {
		const state = getState() as RootState;
		const userId = "discover_" + state.hot.userId;
		const parameter = merge({ fromId: userId }, params);
		const response = await API.cms.getAppFavorite(parameter);
		return { data: response };
	}
);

// export const receiveMqttMessage = createAsyncThunk(
// 	"hot/fetchAppFavoriteList",
// 	async (message: any) => {
// 		return message;
// 	}
// );

function findByIndex(array: any[], key: string, value: string) {
	return array.findIndex((item) => item[key] === value);
}
function updateArrayValue(arr: any[], newValue: any): any[] {
	return arr.map((item) => {
		if (item["symbol"] === newValue.symbol) {
			item = Object.assign({}, item, newValue);
		}
		return item;
	});
}

const appSlice = createSlice({
	name: "hot",
	initialState,
	reducers: {
		setUserId: (state, action: PayloadAction<string>) => {
			state.userId = action.payload;
		},
		setHotData: (state, action: PayloadAction<any>) => {
			console.log(action.payload);
			state.hotData = action.payload;
		},
		setMarketData: (state, action: PayloadAction<marketType>) => {
			const symbols = action.payload.data.map(
				(item) => "tickers." + item.symbol
			);
			state.tickersTopics = union(state.tickersTopics, symbols);
			if (action.payload.type === typeEnum.Add) {
				if (action.payload.name === nameEnum.Collect) {
					if (!isEmpty(action.payload.data)) {
						state.collectList = [
							...state.collectList,
							...action.payload.data,
						];
					} else {
						state.collectList = [];
					}
				}
				if (action.payload.name === nameEnum.Hot) {
					if (!isEmpty(action.payload.data)) {
						state.hotList = [
							...state.hotList,
							...action.payload.data,
						];
					} else {
						state.hotList = [];
					}
				}
				if (action.payload.name === nameEnum.Main) {
					if (!isEmpty(action.payload.data)) {
						state.mainList = [
							...state.mainList,
							...action.payload.data,
						];
					} else {
						state.mainList = [];
					}
				}
			}
			if (action.payload.type === typeEnum.Clear) {
				if (action.payload.name === nameEnum.Collect) {
					state.collectList = [];
				}
				if (action.payload.name === nameEnum.Hot) {
					state.hotList = [];
				}
				if (action.payload.name === nameEnum.Main) {
					state.mainList = [];
				}
			}
		},
		setSortByData: (state, action: PayloadAction<sortDataType>) => {
			if (action.payload.index === nameEnum.Collect) {
				const newData = sortByData(
					cloneDeep(state.collectList),
					action.payload.type,
					action.payload.sortOrder as SortOrder,
					action.payload.tragetArray
				);
				state.collectList = newData;
			}
			if (action.payload.index === nameEnum.Hot) {
				const newData = sortByData(
					cloneDeep(state.hotList),
					action.payload.type,
					action.payload.sortOrder as SortOrder,
					action.payload.tragetArray
				);
				state.hotList = newData;
			}
			if (action.payload.index === nameEnum.Main) {
				const newData = sortByData(
					cloneDeep(state.mainList),
					action.payload.type,
					action.payload.sortOrder as SortOrder,
					action.payload.tragetArray
				);
				state.mainList = newData;
			}
		},
		setEditFavoriteSort: (state, action: PayloadAction<any[]>) => {
			state.collectList = action.payload;
		},
		fetchAppFavorite: (state, action: PayloadAction<any[]>) => {
			state.collectList = action.payload;
		},
		onAppUpdateTickers: (state, action: PayloadAction<any>) => {
			const indexCollect = findByIndex(
				state.collectList,
				"symbol",
				action.payload.symbol
			);
			if (indexCollect === -1) {
				state.collectList.unshift(action.payload);
			} else {
				if (!action.payload.favorite) {
					state.collectList.splice(indexCollect, 1);
				} else {
					state.collectList = updateArrayValue(
						state.collectList,
						action.payload
					);
				}
			}
			const indexhotData = findByIndex(
				state.hotData,
				"symbol",
				action.payload.symbol
			);
			if (indexhotData !== -1) {
				// state.collectList = updateArrayValue(
				// 	state.hotData,
				// 	action.payload
				// );
			}
			state.hotList = updateArrayValue(state.hotList, action.payload);
			state.mainList = updateArrayValue(state.mainList, action.payload);
		},
		receiveMqttMessage: (state, action: PayloadAction<any>) => {
			state.hotData = updateArrayValue(state.hotData, action.payload);
			state.collectList = updateArrayValue(
				state.collectList,
				action.payload
			);
			state.hotList = updateArrayValue(state.hotList, action.payload);
			state.mainList = updateArrayValue(state.mainList, action.payload);
		},
		setTradeData: (state, action: PayloadAction) => {
			state.tradeData = action.payload;
		},
	},
	// extraReducers: (builder) => {
	// 	builder
	// 		.addCase(receiveMqttMessage.fulfilled, (state, action) => {
	// 			// 处理成功的情况，并更新state
	// 			console.log(state, action, "==action");
	// 		})
	// 		.addCase(receiveMqttMessage.rejected, (state, action) => {
	// 			// 处理失败的情况，并更新state
	// 			// 这里可以根据需要处理失败的逻辑
	// 		})
	// 		.addCase(receiveMqttMessage.pending, (state) => {
	// 			// 处理挂起的情况，并更新state
	// 			// 可以根据需要处理挂起的逻辑
	// 		});
	// },
});

export const {
	setUserId,
	setHotData,
	setMarketData,
	setSortByData,
	setEditFavoriteSort,
	onAppUpdateTickers,
	receiveMqttMessage,
	setTradeData,
} = appSlice.actions;
export default appSlice.reducer;
