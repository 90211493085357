import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import MQTTStore from "../../hooks/useMqtt";

// 手动序列化函数
function serializeMQTTStore(mqttStore: MQTTStore) {
	const serializedData = {
		...mqttStore,
		// 存储方法名字
		__methods: Object.getOwnPropertyNames(Object.getPrototypeOf(mqttStore)),
	};
	return JSON.stringify(serializedData);
}

// 手动反序列化函数
function deserializeMQTTStore(serializedData: string) {
	const data = JSON.parse(serializedData);
	const mqttStore = MQTTStore.getInstance();
	console.log(mqttStore, "----mqttStore");

	// 重新构建方法
	const methods = data.__methods || [];
	methods.forEach((methodName: string) => {
		// mqttStore[methodName] = MQTTStore.prototype[methodName];
		console.log(methodName);
	});

	return mqttStore;
}

interface MqttSliceState {
	mqttStoreInstance: MQTTStore | null;
}

const initialState: MqttSliceState = {
	mqttStoreInstance: null,
};

// 用于异步连接 MQTT
export const connectMQTT = createAsyncThunk(
	"mqtt/connect",
	async (clientId: string, thunkAPI) => {
		const mqttStoreInstance = MQTTStore.getInstance();
		await mqttStoreInstance.connect(clientId);
		return mqttStoreInstance;
	}
);

const mqttSlice = createSlice({
	name: "mqtt",
	initialState,
	reducers: {
		// setMQTTStoreInstance: (
		// 	state,
		// 	action: PayloadAction<MQTTStore | null>
		// ) => {
		// 	console.log(action.payload, "--action.payload");
		// 	state.mqttStoreInstance = action.payload;
		// },
	},
	extraReducers: (builder) => {
		builder.addCase(connectMQTT.fulfilled, (state, action) => {
			const serializedData = serializeMQTTStore(action.payload);
			state.mqttStoreInstance = deserializeMQTTStore(serializedData);
			console.log(state.mqttStoreInstance, "----");
		});
	},
});

export const selectMQTTStoreInstance = (state: RootState) =>
	state.mqtt.mqttStoreInstance;
export default mqttSlice.reducer;
