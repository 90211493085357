import useCustomTranslation from "@/hooks/useTranslation";
import { DotLoading } from "antd-mobile";

const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
	const { t } = useCustomTranslation();
	return (
		<div
			style={{
				height: "60px",
				paddingBottom: "30px",
				textAlign: "center",
			}}
		>
			{hasMore ? (
				<>
					<span>{t("loading")}</span>
					<DotLoading />
				</>
			) : (
				<span>--- {t("Ihaveline")} ---</span>
			)}
		</div>
	);
};
export default InfiniteScrollContent;
