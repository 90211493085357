export interface tickersType {
	hit?: number;
	[key: string]: any;
}
export interface tabItemTpee {
	key: string;
	title: string;
	hasMore: boolean;
	page: number;
	data: any[];
	onInterface?: Function;
	onAppFavorite?: Function;
}
export interface sortDataType {
	index?: string;
	type: string;
	sortOrder: string;
	tragetArray?: string[];
}
export interface favoriteDataType {
	index?: string | boolean;
	data: any;
}
export enum nameEnum {
	Collect = "collect",
	Hot = "hot",
	Main = "main",
}
export enum typeEnum {
	Clear = "clear",
	Add = "add",
}
export type SortOrder = "asc" | "desc" | "normal";
