import CustomNavBar from "@/components/navBar";
import useRouter from "@/hooks/useRouter";
import React, { useEffect, useRef, useState } from "react";
import { formatTimeover, getCoverUri } from "@/utils/Index";
import useCustomTranslation from "@/hooks/useTranslation";
import { Avatar, Image, SafeArea, Skeleton } from "antd-mobile";

const Market: React.FC<{ data: any }> = ({ data }) => {
	const { t } = useCustomTranslation();
	const { goBack } = useRouter();

	const [imgError, setImgError] = useState(false);
	const [isSticky, setIsSticky] = useState(false);
	const scrollableRef = useRef(null);
	const scrollingImageRef = useRef(null);
	const handleScroll = () => {
		if (scrollableRef.current) {
			const { scrollTop } = scrollableRef.current;
			const imageHeight = scrollingImageRef.current
				? scrollingImageRef.current.clientHeight
				: 0;
			if (imageHeight && scrollTop >= imageHeight - 45) {
				setIsSticky(true);
			} else {
				setIsSticky(false);
			}
		}
	};

	return (
		<section
			className="section-main"
			style={{ overflowY: "auto", backgroundColor: "white" }}
			ref={scrollableRef}
			onScroll={handleScroll}
		>
			<div
				ref={scrollingImageRef}
				style={{
					width: "100%",
					position: "relative",
					zIndex: 1,
				}}
			>
				{/* <Image src="https://images.unsplash.com/photo-1620476214170-1d8080f65cdb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80" /> */}
				<Image
					src={getCoverUri(data.cover)}
					fit="fill"
					onError={() => {
						setImgError(true);
					}}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "148px",
					}}
				/>
			</div>
			<div
				style={{
					borderTopRightRadius: "20px",
					borderTopLeftRadius: "20px",
					marginTop: "-20px",
					position: "relative",
					zIndex: 2,
					backgroundColor: "rgba(255,255,255,1)",
					paddingBottom: "-20px",
				}}
			>
				<div className={`news-navbar ${isSticky ? "sticky" : ""}`}>
					<SafeArea position="top" />
					<CustomNavBar
						title={t("News")}
						// rightContent={
						// 	<Avatar
						// 		src={require(`@/images/share.png`)}
						// 		style={{
						// 			"--size": "24px",
						// 			display: "inline-block",
						// 		}}
						// 	/>
						// }
						onLeftClick={() => {
							goBack();
						}}
					/>
				</div>
				<article className="isolate-area">
					<h3 className="title">{data.title || "-"}</h3>
					<p className="time">
						{data.createTime
							? formatTimeover(data.createTime)
							: "-"}
					</p>
					<div
						className="content"
						dangerouslySetInnerHTML={{
							__html: data.content,
						}}
					></div>
				</article>
			</div>
		</section>
	);
};

export default Market;
